<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form action>
                <div class="row justify-content-end">
                  <button class="btn btn-default mr-2" @click.prevent="cancel">取消</button>
                  <button class="btn btn-primary" @click.prevent="_verify">儲存</button>
                </div>
                <ul class="nav nav-tabs mb-3" id="custom-content-below-tab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="custom-content-info-tab"
                      data-toggle="pill"
                      href="#custom-content-info"
                      role="tab"
                      aria-controls="custom-content-info"
                      aria-selected="true"
                    >商品簡介</a>
                  </li>
                  <!-- <li class="nav-item">
                    <a
                      class="nav-link"
                      id="custom-content-payAndShip-tab"
                      data-toggle="pill"
                      href="#custom-content-payAndShip"
                      role="tab"
                      aria-controls="custom-content-payAndShip"
                      aria-selected="false"
                    >付款與運費設定</a>
                  </li>-->
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="custom-content-spec-tab"
                      data-toggle="pill"
                      href="#custom-content-spec"
                      role="tab"
                      aria-controls="custom-content-spec"
                      aria-selected="false"
                    >產品規格</a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="custom-content-gift-tab"
                      data-toggle="pill"
                      href="#custom-content-gift"
                      role="tab"
                      aria-controls="custom-content-gift"
                      aria-selected="false"
                    >贈品活動設定</a>
                  </li>
                </ul>
                <div class="tab-content" id="custom-content-below-tabContent">
                  <!-- {{-- 商品簡介 --}} -->
                  <div
                    class="tab-pane fade show active"
                    id="custom-content-info"
                    role="tabpanel"
                    aria-labelledby="custom-content-info-tab"
                  >
                    <div class="row form-group">
                      <div class="col-sm-6">
                        <label>
                          <span class="text-danger">*</span>上下架狀態
                        </label>
                        <div>
                          <Toggle v-model="productForm.enable" falseValue="N" trueValue="Y" />
                        </div>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-sm-6">
                        <label>
                          <span class="text-danger">*</span>產品類別
                        </label>
                        <select class="form-control" v-model="productForm.menu_id">
                          <option
                            v-for="item in menuOptios"
                            :key="item.id"
                            :value="item.id"
                          >{{ item.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-sm-6">
                        <label>
                          <span class="text-danger">*</span>產品名稱
                        </label>
                        <input type="text" class="form-control" v-model="productForm.name" />
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-12">
                        <label>產品特色（建議條列式輸入文字或表情符號）</label>
                        <EditorCustom
                          :data="productForm.brief_description"
                          :target="'brief_description'"
                          @changeData="changeData"
                        />
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-12">
                        <div style="display: flex; flex-direction: column;">
                          <label>
                            <span class="text-danger">*</span>封面照
                          </label>
                          <input
                            type="file"
                            accept="image/jpg, image/png, image/jpeg"
                            class="uploadItemImage"
                            name="filepond"
                            @change="uploadCoverImg($event)"
                          />
                        </div>
                        <div class="imgs-content my-2" v-show="coverImgURL">
                          <img class="img-content" :src="coverImgURL" />
                        </div>
                        <div class="text-danger">建議上傳尺寸：800x800 像素 / 檔案限制 2MB 內</div>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-12">
                        <div style="display: flex; flex-direction: column;">
                          <label>
                            <span class="text-danger">*</span>產品照（一次可選擇多張照片）
                          </label>
                          <input
                            type="file"
                            accept="image/jpg, image/png, image/jpeg"
                            class="uploadItemImage"
                            name="filepond"
                            multiple
                            @change="uploadProducsImgs($event)"
                          />
                        </div>
                        <div
                          v-for="(img, index) in productImgs"
                          :key="img"
                          class="imgs-content my-2"
                        >
                          <img class="img-content" :src="producsImgsURL(img)" />
                          <a @click.prevent="deleteProductsImg(index)">- 刪除</a>
                        </div>
                        <div
                          v-for="(img, index) in producsImgsEdit"
                          :key="img.img_id"
                          class="imgs-content my-2"
                          v-show="breadCrumbs.childPage === '編輯產品' && !productImgs.length"
                        >
                          <img class="img-content" :src="img.commodity_img_url" />
                          <a
                            v-if="img.img_id"
                            @click.prevent="delEditProductsImg(index, img.img_id)"
                          >- 刪除</a>
                        </div>
                        <div class="text-danger">建議上傳尺寸：800x800 像素 / 檔案限制 2MB 內</div>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-12">
                        <label>產品介紹</label>
                        <Editor
                          :data="productForm.description"
                          :target="'description'"
                          @changeData="changeData"
                        />
                      </div>
                    </div>
                    <!-- <div class="row form-group">
                      <div class="col-12">
                        <label>產品成分說明</label>
                        <Editor
                          :data="productForm.specification_description"
                          :target="'specification_description'"
                          @changeData="changeData"
                        />
                      </div>
                    </div>-->
                    <div class="row form-group">
                      <div class="col-12 qaContainer mb-2">
                        <label class="mb-2">Ｑ＆Ａ</label>
                        <div class="qaItem" v-for="(qa, index) in qaArray" :key="index">
                          <hr class="mt-4" v-show="qaArray.length !== 1 && index !== 0  " />
                          <label>題目</label>
                          <input
                            type="text"
                            class="form-control mb-3"
                            id="qaTitle1"
                            v-model="qa.question"
                          />
                          <label>內容</label>
                          <Editor :data="qa.answer" :target="index" @changeData="changeQA" />
                          <div>
                            <a
                              class="text-danger mt-2 mb-2 delete-qaItem"
                              @click.prevent="deleteQA(index)"
                              v-show="qaArray.length !== 1"
                            >- 刪除</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center">
                        <a class="add-qaItem" @click.prevent="addQA">+ 新增題目</a>
                      </div>
                    </div>
                  </div>
                  <!-- {{-- 付款與運費設定 --}} -->
                  <!-- <div
                    class="tab-pane fade"
                    id="custom-content-payAndShip"
                    role="tabpanel"
                    aria-labelledby="custom-content-payAndShip-tab"
                  >
                    <div class="row form-group">
                      <div class="col-12">
                        <label>
                          <span class="text-danger">*</span>付款方式
                        </label>
                        <p class="text-danger">請選取此商品可支援的付款方式,預設為全部支援</p>
                        <DualList
                          :options="payOptions"
                          :selected="payEdit"
                          @changeSelected="paySelected"
                        />
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-12">
                        <label>
                          <span class="text-danger">*</span>出貨方式
                        </label>
                        <p class="text-danger">請選取此商品可支援的出貨方式,預設為全部支援</p>
                        <DualList
                          :options="shipmentOptions"
                          :selected="shipmentEdit"
                          @changeSelected="shiomentSelected"
                        />
                      </div>
                    </div>
                  </div>-->
                  <!-- {{-- 產品規格 --}} -->
                  <div
                    class="tab-pane fade"
                    id="custom-content-spec"
                    role="tabpanel"
                    aria-labelledby="custom-content-spec-tab"
                  >
                    <label>
                      <span class="text-danger">*</span>規格
                    </label>
                    <p class="text-danger">至少輸入一個規格</p>
                    <div class="row styleContainer form-group">
                      <div class="col-12">
                        <div class="row mb-2">
                          <div class="col-sm-6 col-md-custom mb-1 mb-md-0">上下架狀態</div>
                          <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">貨號</div>
                          <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">規格</div>
                          <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">庫存</div>
                          <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">成本</div>
                          <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">原價</div>
                          <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">售價</div>
                        </div>
                        <div
                          class="specContainer"
                          v-for="(item, index) in productForm.specification"
                          v-show="!productForm.specificationEdit"
                          :key="index"
                        >
                          <div class="row mb-2">
                            <div class="col-sm-6 col-md-custom mb-1 mb-md-0">
                              <Toggle v-model="item.enable" falseValue="N" trueValue="Y" />
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="貨號"
                                id="num1"
                                v-model="item.number"
                              />
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="規格"
                                id="spec1"
                                v-model="item.name"
                              />
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="庫存"
                                id="stock1"
                                v-model="item.stock"
                              />
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="成本"
                                id="cost1"
                                v-model="item.cost"
                              />
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="原價"
                                id="price1"
                                v-model="item.original_price"
                              />
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="售價"
                                id="salePrice1"
                                v-model="item.selling_price"
                              />
                            </div>
                          </div>
                          <a
                            class="text-danger remove-spec mb-2"
                            @click.prevent="deleteSpecification(index)"
                            v-show="productForm.specification.length !== 1 && index !== 0 && !item.specification_id"
                          >- 移除規格</a>
                          <hr class="mt-3 mb-4" />
                        </div>
                        <div
                          class="specContainer"
                          v-for="(item, index) in productForm.specificationEdit"
                          :key="index"
                        >
                          <div class="row mb-2">
                            <div class="col-sm-6 col-md-custom mb-1 mb-md-0">
                              <Toggle
                                v-model="item.enable"
                                falseValue="N"
                                trueValue="Y"
                                @change="changeEnable(item.specification_id, item.enable, index)"
                              />
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="貨號"
                                id="num1"
                                v-model="item.number"
                              />
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="規格"
                                id="spec1"
                                v-model="item.name"
                              />
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="庫存"
                                id="stock1"
                                v-model="item.stock"
                              />
                              <span
                                class="text-red"
                                v-if="productForm.specification[index]"
                              >目前庫存：{{ productForm.specification[index].stock }}</span>
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="成本"
                                id="cost1"
                                v-model="item.cost"
                              />
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="原價"
                                id="price1"
                                v-model="item.original_price"
                              />
                            </div>
                            <div class="col-sm-6 col-md-custom-label mb-1 mb-md-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="售價"
                                id="salePrice1"
                                v-model="item.selling_price"
                              />
                            </div>
                          </div>
                          <a
                            class="text-danger remove-spec mb-2"
                            @click.prevent="deleteSpecificationAlert(index, item)"
                            v-show="item.specification_id"
                          >- 刪除規格</a>
                          <a
                            class="text-danger remove-spec mb-2"
                            @click.prevent="deleteSpecification(index)"
                            v-show="!item.specification_id"
                          >- 移除規格</a>
                          <hr class="mt-3 mb-4" />
                        </div>
                        <a class="add-spec" @click.prevent="addSpecification">+ 新增規格</a>
                      </div>
                    </div>
                  </div>
                  <!-- {{-- 贈品活動設定 --}} -->
                  <div
                    class="tab-pane fade"
                    id="custom-content-gift"
                    role="tabpanel"
                    aria-labelledby="custom-content-gift-tab"
                  >
                    <div class="form-group">
                      <label>
                        <span class="text-danger">*可</span>作為贈品使用
                      </label>
                      <p class="text-danger mb-1">開放此商品可作為贈品給予其他商品作為贈品使用</p>
                      <div>
                        <div class="icheck-turquoise d-inline-block mr-2">
                          <input
                            type="radio"
                            id="true"
                            name="as_gift"
                            v-model="productForm.is_gift_use"
                            value="Y"
                          />
                          <label for="true" class="form-check-label">開放</label>
                        </div>
                        <div class="icheck-turquoise d-inline-block mr-2">
                          <input
                            type="radio"
                            id="false"
                            name="as_gift"
                            v-model="productForm.is_gift_use"
                            value="N"
                          />
                          <label for="false" class="form-check-label">鎖定</label>
                        </div>
                      </div>
                    </div>
                    <h5 class="mt-4">活動資訊</h5>
                    <hr class="mt-2" />
                    <div class="form-group">
                      <label class="col-form-label">活動時間</label>
                      <p class="text-danger">開始日期不可低於本日。</p>
                      <div class="input-daterange input-group col-12 col-md-6" id="date-range">
                        <input
                          type="date"
                          class="form-control day_start"
                          name="date_start"
                          placeholder="起日"
                          v-model="productForm.activity_start_at"
                        />
                        <span class="input-group-text rounded-0">～</span>
                        <input
                          type="date"
                          class="form-control day_end"
                          name="date_end"
                          placeholder="迄日"
                          v-model="productForm.activity_end_at"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">活動說明</label>
                      <p class="text-danger">內容將顯示於產品簡述下方。</p>
                      <Editor
                        :data="productForm.activity_answer"
                        :target="'activity_answer'"
                        @changeData="changeData"
                      />
                    </div>
                    <h5 class="mt-4">贈品活動</h5>
                    <hr class="mt-2" />
                    <div class="form-group">
                      <label class="col-form-label d-block">贈送數量</label>
                      <input
                        type="text"
                        class="form-control col-3"
                        placeholder="請輸入數量"
                        v-model="productForm.gift_count"
                      />
                    </div>
                    <label class="mb-0">選擇贈品</label>
                    <p class="text-danger">產品及折扣碼可以選取多個作為贈品，紅利及點數各自輸入數字作為贈品。</p>
                    <div class="mx-2">
                      <div class="form-group">
                        <label class="mb-0">產品</label>
                        <p class="text-danger">若同樣的產品需要送出多個，請選取兩次以上。</p>
                        <div class="giftProduct-container">
                          <div
                            class="giftProduct-item"
                            v-for="(item, index) in giftArray"
                            :key="index"
                          >
                            <select
                              id="giftProduct1"
                              class="form-control"
                              style="width:50%"
                              v-model="giftArray[index]"
                            >
                              <option disabled>請選擇產品</option>
                              <option
                                v-for="gift in giftOptions"
                                :key="gift.specification_id"
                                :value="gift.specification_id"
                              >{{ gift.name }} / {{ gift.specification_name }}</option>
                            </select>
                            <a
                              class="text-danger mt-2 delete-giftProduct"
                              v-show="giftArray.length > 1"
                              @click.prevent="deleteGift(index)"
                            >- 移除產品</a>
                            <hr class="ml-0" style="width: 50%" />
                          </div>
                        </div>
                        <div class="mb-2">
                          <a class="mb-2 add-gift-product" @click.prevent="addGift">+ 贈送產品</a>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="mb-0">折扣碼</label>
                        <p class="text-danger">若同樣的折扣碼需要送出多個，請選取兩次以上。</p>
                        <div class="giftPromoCode-container">
                          <div
                            class="giftPromoCode-item"
                            v-for="(item, index) in promoArray"
                            :key="index"
                          >
                            <select
                              id="giftProduct2"
                              class="form-control"
                              style="width:50%"
                              v-model="promoArray[index]"
                            >
                              <option disabled>請選擇折扣碼</option>
                              <option
                                v-for="promo in promoCodeOptions"
                                :key="promo.id"
                                :value="promo.id"
                              >{{ promo.promo_code }} ({{ promo.name }})</option>
                            </select>
                            <a
                              class="text-danger mt-2 delete-giftProduct"
                              v-show="promoArray.length > 1"
                              @click.prevent="deletePromo(index)"
                            >- 移除折扣碼</a>
                            <hr class="ml-0" style="width: 50%" />
                          </div>
                        </div>
                        <div class="mb-2">
                          <a class="mb-2 add-gift-promoCode" @click.prevent="addPromo">+ 贈送折扣碼</a>
                        </div>
                      </div>
                      <div class="form-group d-flex">
                        <div class="col-lg-3 pl-0">
                          <label>紅利</label>
                          <input
                            type="number  "
                            class="form-control"
                            placeholder="請輸入紅利"
                            v-model="productForm.bonus"
                          />
                        </div>
                        <div class="col-lg-3 pr-0">
                          <label>點數</label>
                          <input
                            type="number  "
                            class="form-control"
                            placeholder="請輸入點數"
                            v-model="productForm.points"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'ProductEdit',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import Editor from '@/views/Product/components/Editor.vue'
import EditorCustom from '@/views/Product/components/EditorCustom.vue'
// import DualList from '@/views/Product/components/DualList.vue'
import { getProductConfig, addProduct, getProductData, updateProduct, deleteProductImg, addProductImg, changeSpecificationEnable, deleteProductSpec } from '@/apis/product'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'
import { useRouter } from 'vue-router'

const router = useRouter()

const breadCrumbs = ref({
  parentPage: '產品',
  currentPage: '產品管理',
  currentPath: '/admin/Product/list',
  childPage: '新增產品',
})

const isLoading = ref(false)

const checkTitle = () => {
  breadCrumbs.value.childPage = location.href.indexOf('add') > 0 ? '新增產品' : '編輯產品'
}
const menuOptios = ref([])
const payOptions = ref([])
const shipmentOptions = ref([])
const giftOptions = ref([])
const promoCodeOptions = ref([])
const getConfig = async () => {
  isLoading.value = true
  try {
    const res = await getProductConfig({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      menuOptios.value = res.data.data.menu
      payOptions.value = res.data.data.pay
      shipmentOptions.value = res.data.data.shipment
      giftOptions.value = res.data.data.giftData
      promoCodeOptions.value = res.data.data.promoCodeData
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      if (location.href.indexOf('add') < 0) {
        getEditData()
      } else { // 新增
        payEdit.value = res.data.data.pay.map(item => item.id.toString())
        shipmentEdit.value = res.data.data.shipment.map(item => item.id.toString())
      }
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const getEditData = async () => {
  isLoading.value = true
  try {
    const res = await getProductData({
      commodities_id: localStorage.getItem('commoditiesId'),
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      setEditData(res.data.data.commodities_data)
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const formatTimestamp = (unix) => {
  if (!unix) return '-'
  const date = new Date(unix * 1000)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const dateString = `${year}-${month}-${day}`
  return dateString
}

const producsImgsEdit = ref([])
const payEdit = ref([])
const shipmentEdit = ref([])
const setEditData = (data) => {
  productForm.value.commodities_id = data.commodities_id
  productForm.value.enable = data.enable
  productForm.value.menu_id = data.menu_id
  productForm.value.name = data.commodities_name
  productForm.value.brief_description = data.brief_description
  productForm.value.description = data.description
  coverImgURL.value = data.commodity_page_img_url
  producsImgsEdit.value = data.commodity_img
  // productForm.value.specification_description = data.specification_description
  qaArray.value = data.QA
  // payEdit.value = data.pay_method
  // shipmentEdit.value = data.shipment_method
  productForm.value.specification = []
  productForm.value.specificationEdit = []
  for (let i in data.specification) {
    productForm.value.specification.push({
      specification_id: data.specification[i].specification_id,
      // number: data.specification[i].commodities_number,
      // name: data.specification[i].specification_name,
      stock: data.specification[i].stock,
      // cost: data.specification[i].cost,
      // original_price: data.specification[i].original_price,
      // selling_price: data.specification[i].selling_price,
    })
    productForm.value.specificationEdit.push({
      enable: data.specification[i].enable,
      specification_id: data.specification[i].specification_id,
      number: data.specification[i].commodities_number,
      name: data.specification[i].specification_name,
      stock: '',
      cost: data.specification[i].cost,
      original_price: data.specification[i].original_price,
      selling_price: data.specification[i].selling_price,
    })
  }
  productForm.value.is_gift_use = data.is_gift_use
  if (data.activity_start_at) {
    productForm.value.activity_start_at = formatTimestamp(data.activity_start_at)
    productForm.value.activity_end_at = formatTimestamp(data.activity_end_at)
    productForm.value.activity_answer = data.activity_answer
  }
  productForm.value.gift_count = data.gift_count
  if (data.gift_commodities_specification_id) {
    giftArray.value = JSON.parse(data.gift_commodities_specification_id)
  }
  if (data.discount_code_id) {
    promoArray.value = JSON.parse(data.discount_code_id)
  }
  if (data.bonus) {
    productForm.value.bonus = data.bonus
  }
  if (data.points) {
    productForm.value.points = data.points
  }
}

onMounted(() => {
  checkTitle()
  getConfig()
})

const productForm = ref({
  enable: 'Y',
  menu_id: '',
  name: '',
  brief_description: '', // 產品特色
  description: '', // 產品介紹
  commodity_page_img: '', // 商品封面照，file
  commodity_img: [], // 產品照，files Array
  // specification_description: '', // 產品成分說明
  // pay_method: [], // 付款方式 id，Array
  // shipment_method: [], // 運送方式 id，Array
  specification: [{
    enable: 'Y', // 上下架狀態
    number: '', // 貨號
    name: '', // 規格
    stock: '', // 庫存
    cost: '', // 成本
    original_price: '', // 原價
    selling_price: '' // 售價
  }], // 產品規格
  is_gift_use: 'N', // 是否做為贈品使用，Y/N
  activity_start_at: '', // 活動開始時間 Unix timestamp 格式
  activity_end_at: '', // 活動結束時間 Unix timestamp 格式
  activity_answer: '', // 活動說明
  gift_count: '', // 贈品數量
  gift_commodities_specification_id: [], // 贈品 id，Array
  discount_code_id: [], // 優惠碼 id，Array
  bonus: '', // 紅利
  points: '', // 點數
})

const changeData = (target, data) => {
  productForm.value[target] = data
}

// 封面照
const coverImg = ref('')
const coverImgURL = ref('')
const uploadCoverImg = (e) => {
  coverImg.value = e.target.files[0]
  coverImgURL.value = URL.createObjectURL(e.target.files[0])
}

// 產品照
const productImgs = ref('')
const uploadProducsImgs = (e) => {
  if (location.href.indexOf('add') > 0) {
    // 新增時照舊
    productImgs.value = e.target.files
  } else {
    // 修改時，使用 API 增加
    uploadProducsImgsWhenEdit(e)
  }
}
const producsImgsURL = (file) => {
  return URL.createObjectURL(file)
}
// 修改時新增產品照
const uploadProducsImgsWhenEdit = async (e) => {
  const formData = new FormData()
  formData.append('commodity_id', productForm.value.commodities_id) // id
  for (let i in setCommodityImg(e.target.files)) {
    formData.append(`img_file@${i}`, setCommodityImg(e.target.files)[i])
  }
  formData.append('token', localStorage.getItem('token')) // file
  isLoading.value = true
  try {
    const res = await addProductImg(formData)
    if (res.data.code === 0) {
      for (let i in res.data.data) {
        producsImgsEdit.value.push({
          commodity_img_url: res.data.data[i].commodity_img_url,
          img_id: res.data.data[i].id,
        })
      }
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}
const deleteProductsImg = (index) => {
  const newFileList = Array.from(productImgs.value)
  newFileList.splice(index, 1)
  productImgs.value = newFileList
}
const delEditProductsImg = async (index, img_id) => {
  isLoading.value = true
  try {
    const res = await deleteProductImg({
      img_id,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      producsImgsEdit.value.splice(index, 1)
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}
const setCommodityImg = (fileList) => {
  return Array.from(fileList)
}

// Q&A
const qaArray = ref([{
  question: '',
  answer: '',
}])
const changeQA = (target, data) => {
  qaArray.value[target].answer = data
}
const deleteQA = (index) => {
  qaArray.value.splice(index, 1)
}
const addQA = () => {
  qaArray.value.push({
    question: '',
    answer: '',
  })
}

// // 付款方式
// const paySelected = (data) => {
//   productForm.value.pay_method = data.map(item => item.id)
// }
// // 運送方式
// const shiomentSelected = (data) => {
//   productForm.value.shipment_method = data.map(item => item.id)
// }

// 產品規格
// 新增規格
const addSpecification = () => {
  if (!productForm.value.specificationEdit) {
    productForm.value.specification.push({
      enable: 'Y', // 上下架狀態
      number: '', // 貨號
      name: '', // 規格
      stock: '', // 庫存
      cost: '', // 成本
      original_price: '', // 原價
      selling_price: '' // 售價
    })
  } else {
    productForm.value.specificationEdit.push({
      enable: 'Y', // 上下架狀態
      number: '', // 貨號
      name: '', // 規格
      stock: '', // 庫存
      cost: '', // 成本
      original_price: '', // 原價
      selling_price: '' // 售價
    })
  }
}
const deleteSpecification = (index) => {
  if (!productForm.value.specificationEdit) {
    productForm.value.specification.splice(index, 1)
  } else {
    productForm.value.specificationEdit.splice(index, 1)
  }
}
const deleteSpecificationAlert = async (index, item) => {
  Swal.fire({
    icon: "error",
    title: `確定刪除「規格：${item.name}」？`,
    text: "刪除後將不可還原",
    confirmButtonText: "確認",
    cancelButtonText: "取消",
    confirmButtonColor: '#fe5b52',
    cancelButtonColor: '#dddddd',
    showCancelButton: true,
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true
      try {
        const res = await deleteProductSpec({
          specification_id: item.specification_id,
          token: localStorage.getItem('token')
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          productForm.value.specificationEdit.splice(index, 1)
          isLoading.value = false
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          isLoading.value = false
          wrongRequest(res.data)
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
    }
  })
  // if (!productForm.value.specificationEdit) {
  //   productForm.value.specification.splice(index, 1)
  // } else {
  //   productForm.value.specificationEdit.splice(index, 1)
  // }
}

// 贈品
const giftArray = ref([''])
const addGift = () => {
  giftArray.value.push('')
}
const deleteGift = (index) => {
  giftArray.value.splice(index, 1)
}

// 折扣碼
const promoArray = ref([''])
const addPromo = () => {
  promoArray.value.push('')
}
const deletePromo = (index) => {
  promoArray.value.splice(index, 1)
}

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-')
  const date = new Date(year, month - 1, day)
  const unixTimestamp = date.getTime()
  const unixTimestampSeconds = Math.floor(unixTimestamp / 1000)
  return unixTimestampSeconds
}

const isDayGreaterThanToday = (day) => {
  const today = new Date()
  const givenDay = new Date(day)
  // 比較日期部分，忽略時間
  today.setHours(0, 0, 0, 0)
  givenDay.setHours(0, 0, 0, 0)
  return givenDay >= today
}
const isEndGreaterThanStart = (end) => {
  const start = new Date(productForm.value.activity_start_at)
  const givenDay = new Date(end)
  // 比較日期部分，忽略時間
  start.setHours(0, 0, 0, 0)
  givenDay.setHours(0, 0, 0, 0)
  return givenDay >= start
}

const changeEnable = async (id, enable, index) => {
  isLoading.value = true
  try {
    const res = await changeSpecificationEnable({
      commodities_specification_id: id,
      enable,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      productForm.value.specificationEdit[index].enable = enable
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const requiredError = (text) => {
  Swal.fire({
    icon: 'error',
    title: text
  })
}

const _verify = () => {
  let verify = true
  if (productForm.value.menu_id === '') {
    verify = false
    requiredError('選單為必選')
    return
  }
  if (productForm.value.name === '') {
    verify = false
    requiredError('商品名稱為必填')
    return
  }
  if (coverImg.value === '' && location.href.indexOf('add') > 0) {
    verify = false
    requiredError('封面照未設定')
    return
  }
  if ((productImgs.value === '' || !productImgs.value.length) && location.href.indexOf('add') > 0) {
    verify = false
    requiredError('產品照未設定')
    return
  }
  // if (!productForm.value.pay_method.length) {
  //   verify = false
  //   requiredError('付款方式未選取')
  //   return
  // }
  // if (!productForm.value.shipment_method.length) {
  //   verify = false
  //   requiredError('出貨方式未選取')
  //   return
  // }
  if (productForm.value.specification[0].name === '' ||
    productForm.value.specification[0].stock === '' ||
    productForm.value.specification[0].cost === '' ||
    productForm.value.specification[0].original_price === '' ||
    productForm.value.specification[0].selling_price === '') {
    verify = false
    requiredError('產品規格為必填')
    return
  }
  if (location.href.indexOf('add') > 0) { // 新增
    const isValidOriginalPrice = productForm.value.specification.every(item => parseFloat(item.original_price) > 0 && !isNaN(parseFloat(item.original_price)))
    if (!isValidOriginalPrice) {
      verify = false
      requiredError('產品原價需大於 0')
      return
    }
    const isValidSellingPrice = productForm.value.specification.every(item => parseFloat(item.selling_price) > 0 && !isNaN(parseFloat(item.selling_price)))
    if (!isValidSellingPrice) {
      verify = false
      requiredError('產品售價需大於 0')
      return
    }
    const isValidSellingPriceMoreThanCost = productForm.value.specification.every(item => {
      const sellingPrice = parseFloat(item.selling_price)
      const cost = parseFloat(item.cost)
      return sellingPrice > 0 && !isNaN(sellingPrice) && sellingPrice > cost
    })
    if (!isValidSellingPriceMoreThanCost) {
      verify = false
      requiredError('產品售價需大於成本')
      return
    }
    const isValidSellingPriceLessThanOriginalPrice = productForm.value.specification.every(item => {
      const sellingPrice = parseFloat(item.selling_price)
      const originalPrice = parseFloat(item.original_price)
      return sellingPrice > 0 && !isNaN(sellingPrice) && sellingPrice < originalPrice
    })
    if (!isValidSellingPriceLessThanOriginalPrice) {
      verify = false
      requiredError('產品售價需小於原價')
      return
    }
  } else { // 編輯
    const isValidOriginalPrice = productForm.value.specificationEdit.every(item => parseFloat(item.original_price) > 0 && !isNaN(parseFloat(item.original_price)))
    if (!isValidOriginalPrice) {
      verify = false
      requiredError('產品原價需大於 0')
      return
    }
    const isValidSellingPrice = productForm.value.specificationEdit.every(item => parseFloat(item.selling_price) > 0 && !isNaN(parseFloat(item.selling_price)))
    if (!isValidSellingPrice) {
      verify = false
      requiredError('產品售價需大於 0')
      return
    }
    const isValidSellingPriceMoreThanCost = productForm.value.specificationEdit.every(item => {
      const sellingPrice = parseFloat(item.selling_price)
      const cost = parseFloat(item.cost)
      return sellingPrice > 0 && !isNaN(sellingPrice) && sellingPrice > cost
    })
    if (!isValidSellingPriceMoreThanCost) {
      verify = false
      requiredError('產品售價需大於成本')
      return
    }
    const isValidSellingPriceLessThanOriginalPrice = productForm.value.specificationEdit.every(item => {
      const sellingPrice = parseFloat(item.selling_price)
      const originalPrice = parseFloat(item.original_price)
      return sellingPrice > 0 && !isNaN(sellingPrice) && sellingPrice < originalPrice
    })
    if (!isValidSellingPriceLessThanOriginalPrice) {
      verify = false
      requiredError('產品售價需小於原價')
      return
    }
  }
  if (productForm.value.activity_start_at && !isDayGreaterThanToday(productForm.value.activity_start_at)) {
    verify = false
    requiredError('活動起始時間不得小於今日')
    return
  }
  if (productForm.value.activity_end_at && !isEndGreaterThanStart(productForm.value.activity_end_at)) {
    verify = false
    requiredError('活動結束時間不得小於起始時間')
    return
  }
  if (verify) {
    send()
  }
}

const send = async () => {
  isLoading.value = true
  const formData = new FormData()
  // 上下架狀態
  formData.append('enable', productForm.value.enable)
  // 產品類別
  formData.append('menu_id', productForm.value.menu_id)
  // 產品名稱
  formData.append('name', productForm.value.name)
  // 產品特色
  formData.append('brief_description', productForm.value.brief_description)
  // 產品介紹
  formData.append('description', productForm.value.description)
  // 封面照
  if (coverImg.value) {
    formData.append('commodity_page_img', coverImg.value)
  }
  for (let i in setCommodityImg(productImgs.value)) {
    formData.append(`commodity_img@${i}`, setCommodityImg(productImgs.value)[i])
  }
  // // 產品成分說明
  // formData.append('specification_description', productForm.value.specification_description)
  // Q&A
  for (let i in qaArray.value) {
    formData.append(`question_answer@${i}@question`, qaArray.value[i].question)
    formData.append(`question_answer@${i}@answer`, qaArray.value[i].answer)
  }
  // // 付款方式
  // formData.append('pay_method', productForm.value.pay_method)
  // // 運送方式
  // formData.append('shipment_method', productForm.value.shipment_method)
  // 產品規格
  if (location.href.indexOf('add') > 0) { // 新增時，庫存直接傳
    for (let i in productForm.value.specification) {
      formData.append(`specification@${i}@enable`, productForm.value.specification[i].enable)
      formData.append(`specification@${i}@number`, productForm.value.specification[i].number)
      formData.append(`specification@${i}@name`, productForm.value.specification[i].name)
      formData.append(`specification@${i}@stock`, productForm.value.specification[i].stock)
      formData.append(`specification@${i}@cost`, productForm.value.specification[i].cost)
      formData.append(`specification@${i}@original_price`, productForm.value.specification[i].original_price)
      formData.append(`specification@${i}@selling_price`, productForm.value.specification[i].selling_price)
    }
  } else { // 修改時，庫存改傳增減
    for (let i in productForm.value.specificationEdit) {
      formData.append(`specification@${i}@number`, productForm.value.specificationEdit[i].number)
      formData.append(`specification@${i}@name`, productForm.value.specificationEdit[i].name)
      formData.append(`specification@${i}@stock`, productForm.value.specificationEdit[i].stock)
      formData.append(`specification@${i}@cost`, productForm.value.specificationEdit[i].cost)
      formData.append(`specification@${i}@original_price`, productForm.value.specificationEdit[i].original_price)
      formData.append(`specification@${i}@selling_price`, productForm.value.specificationEdit[i].selling_price)
      formData.append(`specification@${i}@id`, productForm.value.specificationEdit[i].specification_id)
    }
  }
  // 是否作為贈品
  formData.append('is_gift_use', productForm.value.is_gift_use) // 是否作為贈品
  // 活動開始時間 Unix timestamp 格式
  if (productForm.value.activity_start_at) {
    formData.append('activity_start_at', formatDate(productForm.value.activity_start_at))
  }
  // 活動結束時間 Unix timestamp 格式
  if (productForm.value.activity_end_at) {
    formData.append('activity_end_at', formatDate(productForm.value.activity_end_at))
  }
  // 活動說明
  if (productForm.value.activity_answer) {
    formData.append('activity_answer', productForm.value.activity_answer)
  }
  // 蹭品數量
  if (productForm.value.gift_count) {
    formData.append('gift_count', productForm.value.gift_count)
  }
  // 蹭品
  if (giftArray.value[0] !== '') {
    formData.append('gift_commodities_specification_id', giftArray.value)
  }
  // 折扣碼
  if (promoArray.value[0] !== '') {
    formData.append('discount_code_id', promoArray.value)
  }
  // 紅利
  if (productForm.value.bonus) {
    formData.append('bonus', productForm.value.bonus)
  }
  // 點數
  if (productForm.value.points) {
    formData.append('points', productForm.value.points)
  }
  // 產品 id
  if (location.href.indexOf('add') < 0) {
    formData.append('commodities_id', productForm.value.commodities_id)
  }
  formData.append('token', localStorage.getItem('token'))
  try {
    const res = location.href.indexOf('add') > 0
      ? await addProduct(formData)
      : await updateProduct(formData)
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: `${breadCrumbs.value.childPage}成功`,
      }).then(() => {
        cancel()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const cancel = () => {
  router.push(breadCrumbs.value.currentPath)
}
</script>

<style lang="scss" scoped>
.col-md-custom {
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.col-md-custom-label {
  flex: 0 0 calc((100% - 100px) / 6);
  max-width: calc((100% - 100px) / 6);
}
.upload-div-img:deep,
.upload-div-add-img:deep {
  min-width: 148px;
  width: 100% !important;
  min-height: 148px;
  height: 100% !important;
  border: 1px solid red;
}
.imgs-content {
  background-color: #3c3c3c;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 15px;
  a {
    position: absolute;
    top: 0;
    right: 16px;
  }
  img {
    width: 200px;
  }
}
a {
  cursor: pointer;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>