<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="mb-2">
                  <div class="d-inline-flex align-items-center radio icheck-turquoise">
                    <input
                      type="radio"
                      name="condition"
                      id="send"
                      v-model="searchForm.distribute"
                      value="Y"
                    />
                    <label for="send" class="mx-1 mb-0" value="Y">已發放</label>
                  </div>
                  <div class="d-inline-flex align-items-center radio icheck-turquoise">
                    <input
                      type="radio"
                      name="condition"
                      id="notSend"
                      v-model="searchForm.distribute"
                      value="N"
                    />
                    <label for="notSend" class="mx-1 mb-0" value="N">未發放</label>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="任務名稱"
                      v-model="searchForm.name"
                    />
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-5">
                    <div class="input-daterange input-group" id="date-range">
                      <input
                        type="date"
                        class="form-control day_start"
                        name="date_start"
                        placeholder="起日"
                        v-model="searchForm.start_day"
                      />
                      <span class="input-group-text rounded-0">～</span>
                      <input
                        type="date"
                        class="form-control day_end"
                        name="date_end"
                        placeholder="迄日"
                        v-model="searchForm.end_day"
                      />
                    </div>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-5">
                    <button class="btn btn-primary mr-1" @click.prevent="getList()">搜尋</button>
                    <button class="btn btn-default" @click.prevent="reset">清空</button>
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col-12 mb-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick="location.href = '/admin/Discount/Mission/add'"
                  >新增簽到任務</button>
                </div>
              </div>
              <div class="table-content">
                <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                  <template #distribute="{ scope }">{{ formatDistribute(scope.distribute) }}</template>
                  <template #rewardType="{ scope }">
                    <a
                      class="btn-table"
                      data-toggle="modal"
                      data-target="#giftContent"
                      @click="test(scope)"
                    >
                      <i class="ion-document-text"></i>
                    </a>
                  </template>
                  <template #check_count="{ scope }">
                    <a class="text-blue" @click="setCheckId(scope.id)">{{ scope.check_count }}</a>
                  </template>
                  <template #recode_count="{ scope }">
                    <a class="text-blue" @click="setRecodeId(scope.id)">{{ scope.recode_count }}</a>
                  </template>
                  <template #enable="{ scope }">
                    <Toggle
                      v-model="scope.enable"
                      falseValue="N"
                      trueValue="Y"
                      @change="changeEnable(scope.id, scope.enable)"
                    />
                  </template>
                  <template #edit="{ scope }">
                    <a
                      href="javascript:;"
                      class="btn-table edit ml-auto ml-0"
                      @click="editItem(scope.id)"
                    >
                      <i class="ion-edit"></i>
                    </a>
                  </template>
                </DataTable>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'MissionList',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { getMissionList, changeMissionEnable } from '@/apis/mission'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'

const breadCrumbs = ref({
  parentPage: '優惠',
  currentPage: '簽到任務管理',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '任務名稱',
    label: 'name'
  },
  {
    name: '開始日期',
    label: 'start_day',
  },
  {
    name: '結束日期',
    label: 'end_day',
  },
  {
    name: '獎勵狀態',
    label: 'distribute',
    customLabel: 'distribute',
    custom: true
  },
  {
    name: '獎勵種類',
    label: 'rewardType',
    customLabel: 'rewardType',
    custom: true
  },
  {
    name: '簽到紀錄',
    label: 'check_count',
    customLabel: 'check_count',
    custom: true
  },
  {
    name: '發放紀錄',
    label: 'recode_count',
    customLabel: 'recode_count',
    custom: true
  },
  {
    name: '狀態',
    label: 'enable',
    customLabel: 'enable',
    custom: true
  },
  {
    name: '編輯',
    label: 'edit',
    customLabel: 'edit',
    custom: true
  },
  // {
  //   name: '刪除',
  //   label: 'delete',
  //   customLabel: 'delete',
  //   custom: true
  // }
])

const formatDistribute = (value) => {
  return value === 'Y' ? '已發放' : '未發放'
}

const setCheckId = (id) => {
  localStorage.setItem('missionId', id)
  location.href = `/admin/Discount/Mission/checkRecord`
}

const setRecodeId = (id) => {
  localStorage.setItem('missionId', id)
  location.href = `/admin/Discount/Mission/sentRecord`
}

const editItem = (id) => {
  localStorage.setItem('missionId', id)
  location.href = `/admin/Discount/Mission/edit`
}

const changeEnable = async (id, enable) => {
  isLoading.value = true
  try {
    const res = await changeMissionEnable({
      id,
      enable,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      getList()
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const listData = ref({
  data: []
})

const searchForm = ref({
  distribute: 'Y', // 是否發放 'Y' 'N'
  start_day: '', // 起始日期 ex '2023-04-01'
  end_day: '', // 結束日期 ex '2023-04-01'
  name: '', // 任務名稱
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  const searchData = {}
  searchData.distribute = searchForm.value.distribute
  if (searchForm.value.start_day) {
    searchData.start_day = searchForm.value.start_day
  }
  if (searchForm.value.end_day) {
    searchData.end_day = searchForm.value.end_day
  }
  if (searchForm.value.name) {
    searchData.name = searchForm.value.name
  }
  try {
    const res = await getMissionList({
      ...searchData,
      page,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

onMounted(() => {
  getList()
})

const reset = () => {
  searchForm.value = {
    distribute: 'Y',
    start_day: '',
    end_day: '',
    name: '',
  }
  getList()
}

</script>

<style lang="scss" scoped>
.table-content {
  overflow: auto;
}
.text-blue {
  text-decoration: none;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>