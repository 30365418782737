<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                  <template #count="{ scope }">
                    <span class="badge badge-primary" v-show="scope.model !== '出貨'">增加</span>
                    <span class="badge badge-danger" v-show="scope.model === '出貨'">減少</span>
                    <span class="ml-2 font-bold">{{ scope.count }}</span>
                  </template>
                </DataTable>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script>
export default {
  name: 'ProductStockRecord',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { getStockRecord } from '@/apis/product'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  parentPage: '產品',
  currentPage: '產品管理',
  currentPath: '/admin/Product/list',
  childPage: '庫存紀錄',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '產品名稱',
    label: 'name'
  },
  {
    name: '貨號',
    label: 'commodities_number',
  },
  {
    name: '規格',
    label: 'specification_name',
  },
  {
    name: '變動數量',
    label: 'count',
    customLabel: 'count',
    custom: true
  },
  {
    name: '原因',
    label: 'model',
  },
  {
    name: '註記',
    label: 'note',
  },
])

const listData = ref({
  data: []
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  try {
    const res = await getStockRecord({
      specification: localStorage.getItem('specificationId'),
      page,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

onMounted(() => {
  getList()
})

</script>

<style lang="scss" scoped>
</style>