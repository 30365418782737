<template>
  <Editor :api-key="apiKey" :init="init" v-model="valueHtml" />
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, watch } from 'vue'
import Editor from '@tinymce/tinymce-vue'

const props = defineProps({
  data: {
    required: true
  },
  target: {
    required: true
  },
})

const valueHtml = ref('')

watch(() => props.data, (nV, oV) => {
  if (nV) {
    valueHtml.value = props.data
  }
}, {
  deep: true
})

const apiKey = reactive('dx6fmftljibe6g6r410yfy82dp66z74pq447p9nefmxkyl8e')
const init = reactive({
  language: 'zh_TW',
  height: 300,
  menubar: false,
  plugins: 'code advlist autolink lists link charmap preview insertdatetime help wordcount emoticons',
  editimage_cors_hosts: ['picsum.photos'],
  toolbar: 'code | undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview',
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
})


const emit = defineEmits(['changeData'])

watch(() => valueHtml, (nV, oV) => {
  if (nV) {
    emit('changeData', props.target, valueHtml)
  }
}, {
  deep: true
})

</script>