<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <!-- <div class="col-12 col-md-10">
          <div class="card">
            <div class="card-body">
              <h5 class="pt-2">服務商狀態</h5>
              <hr />
              <div class="table-responsive">
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <th style="width: 200px;">藍新-店到店</th>
                      <td class="center">
                        <div>上線</div>
                        <button type="button" class="btn btn-primary btn-sm mt-2">更新</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>-->
        <div class="col-12 col-md-10">
          <div class="card">
            <div class="card-body">
              <form>
                <h5 class="pt-2">宅配及郵寄設定</h5>
                <hr />
                <section>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pl-2">郵寄-台灣本島</label>
                    </div>
                    <div class="col-md-10 ml-2 ml-md-0">
                      <label>補充說明</label>
                      <textarea
                        id="shipment_comment1"
                        name="shipment_comment1"
                        class="form-control"
                        rows="3"
                        placeholder="請輸入補充說明"
                        v-model="publicity.send_by_post_main_island"
                      ></textarea>
                      <div class="text-danger">相關補充說明會顯示在該名稱下方作為提示客戶須知。</div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pl-2">郵寄-外離島</label>
                    </div>
                    <div class="col-md-10 ml-2 ml-md-0">
                      <label>補充說明</label>
                      <textarea
                        id="shipment_comment2"
                        name="shipment_comment2"
                        class="form-control"
                        rows="3"
                        placeholder="請輸入補充說明"
                        v-model="publicity.send_by_post_outlying_islands"
                      ></textarea>
                      <div class="text-danger">相關補充說明會顯示在該名稱下方作為提示客戶須知。</div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pl-2">宅配-台灣本島</label>
                    </div>
                    <div class="col-md-10 ml-2 ml-md-0">
                      <label>補充說明</label>
                      <textarea
                        id="shipment_comment3"
                        name="shipment_comment3"
                        class="form-control"
                        rows="3"
                        placeholder="請輸入補充說明"
                        v-model="publicity.home_delivery_main_island"
                      ></textarea>
                      <div class="text-danger">相關補充說明會顯示在該名稱下方作為提示客戶須知。</div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pl-2">宅配-外離島</label>
                    </div>
                    <div class="col-md-10 ml-2 ml-md-0">
                      <label>補充說明</label>
                      <textarea
                        id="shipment_comment4"
                        name="shipment_comment4"
                        class="form-control"
                        rows="3"
                        placeholder="請輸入補充說明"
                        v-model="publicity.home_delivery_outlying_islands"
                      ></textarea>
                      <div class="text-danger">相關補充說明會顯示在該名稱下方作為提示客戶須知。</div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pl-2">超商取貨</label>
                    </div>
                    <div class="col-md-10 ml-2 ml-md-0">
                      <label>補充說明</label>
                      <textarea
                        id="shipment_comment5"
                        name="shipment_comment5"
                        class="form-control"
                        rows="3"
                        placeholder="請輸入補充說明"
                        v-model="publicity.supermarket_pickup"
                      ></textarea>
                      <div class="text-danger">相關補充說明會顯示在該名稱下方作為提示客戶須知。</div>
                    </div>
                  </div>
                </section>
                <div class="text-center">
                  <!-- <button type="reset" class="btn btn-default mr-1">取消</button> -->
                  <button class="btn btn-primary" @click.prevent="updateText">儲存</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-10">
          <div class="card">
            <div class="card-body">
              <form>
                <h5 class="pt-2">物流設定</h5>
                <hr />
                <div class="table-responsive">
                  <table class="table text-nowrap">
                    <thead class="thead-light">
                      <th style="width: 20%;">物流方式</th>
                      <th style="width: 15%;">服務商</th>
                      <th style="width: 65%;">設定</th>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in shipmentSetting" :key="index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.service_provider }}</td>
                        <td class="center">
                          <div class="form-group d-flex d-sm-block flex-column flex-sm-row">
                            <span>
                              <span class="mr-2">
                                <label>前台</label>
                                <Toggle
                                  v-model="item.is_front_desk_use"
                                  falseValue="N"
                                  trueValue="Y"
                                />
                              </span>
                              <span class="mr-2">
                                <label>後台</label>
                                <Toggle
                                  v-model="item.is_backstage_use"
                                  falseValue="N"
                                  trueValue="Y"
                                />
                              </span>
                            </span>
                            <span class="mr-2">
                              <label class="text-nowrap">排序</label>
                              <input type="text" class="form-control sort_num" v-model="item.sort" />
                            </span>
                          </div>
                          <div class="row mb-3">
                            <div class="col-12 col-sm-6">
                              <label>運費</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="請輸入運費"
                                v-model="item.shipping_cost"
                              />
                            </div>
                            <div class="col-12 col-sm-6">
                              <label>免運門檻</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="請輸入免運門檻"
                                v-model="item.threshold"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-center">
                  <!-- <button type="reset" class="btn btn-default">取消</button> -->
                  <button class="btn btn-primary" @click.prevent="sendShipmentSetting">儲存</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'OrderShipmentSetting',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { getShipmentConfig, updateShipmentText, upadteShipment } from '@/apis/order'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'

const breadCrumbs = ref({
  parentPage: '訂單',
  currentPage: '運費及免運設定',
})

const isLoading = ref(false)

const publicity = ref({
  send_by_post_main_island: '', // 郵寄-本島
  send_by_post_outlying_islands: '', // 郵寄-離島
  home_delivery_main_island: '', // 宅配-本島
  home_delivery_outlying_islands: '', // 宅配-離島
  supermarket_pickup: '' // 超商取貨
})
const shipmentSetting = ref([])

const getData = async () => {
  isLoading.value = true
  try {
    const res = await getShipmentConfig({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      publicity.value = res.data.data.publicity
      shipmentSetting.value = res.data.data.shipment
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getData()
})

const updateText = async () => {
  isLoading.value = true
  try {
    const res = await updateShipmentText({
      ...publicity.value,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: '更新說明成功'
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const sendShipmentSetting = async () => {
  isLoading.value = true
  try {
    const res = await upadteShipment({
      shipment: shipmentSetting.value,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: '更新運費設定成功'
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

</script>

<style lang="scss" scoped>
.sort_num {
  text-align: center;
  width: 40px;
  display: inline-block;
  padding: 0.25rem;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>