<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <h5 class="mt-2 mb-3">活動設置</h5>
                <div class="form-group text-danger">若折扣碼新增後，已經有獎勵送出時，折扣碼將不可被刪除，只能停用，請謹慎操作。</div>
                <hr />
                <div class="mb-5">
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pt-2 pb-3 mb-0">
                        <span class="text-danger">*</span>活動名稱
                      </label>
                    </div>
                    <div class="col-md-9">
                      <input
                        type="text"
                        class="form-control col-md-6"
                        placeholder="請輸入名稱"
                        required
                        v-model="promoCodeForm.name"
                      />
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pt-2 pb-3 mb-0">
                        <span class="text-danger">*</span>開始日期
                      </label>
                    </div>
                    <div class="col-md-9">
                      <input
                        type="date"
                        class="form-control col-md-6 date"
                        placeholder="請選擇日期"
                        v-model="promoCodeForm.start_at"
                      />
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pt-2 pb-3 mb-0">結束日期</label>
                    </div>
                    <div class="col-md-9">
                      <div class="row align-items-center">
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control mr-3 date"
                            placeholder="請選擇日期"
                            v-model="promoCodeForm.end_at"
                          />
                        </div>
                        <div class="col-md-6">
                          <span class="text-danger">若沒有選擇結束日期則會一直持續贈送</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pt-2 pb-3 mb-0">折扣碼</label>
                    </div>
                    <div class="col-md-10 d-flex align-items-center">
                      <div class="row align-items-center">
                        <div class="input-group col-12 col-md-6">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="輸入折扣碼"
                            required
                            v-model="promoCodeForm.promo_code"
                          />
                          <!-- <div class="input-group-append">
                            <button type="button" class="btn btn-sm btn-primary" @click.prevent="hashPromoCode">隨機產生</button>
                          </div>-->
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="text-danger">僅使用數字或英文，例如：「ILOVE123456」</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pt-2 pb-3 mb-0">領取方式</label>
                    </div>
                    <div class="col-md-10">
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="limitaion"
                          id="limit_member"
                          value="Y"
                          v-model="promoCodeForm.specify"
                        />
                        <label for="limit_member" class="mr-2 mb-0" value="Y">限定會員使用</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="limitaion"
                          id="limit_public"
                          value="N"
                          v-model="promoCodeForm.specify"
                        />
                        <label for="limit_public" class="mr-2 mb-0" value="N">公開領取</label>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 class="mt-2 mb-3">促銷設定</h5>
                <hr />
                <div class="row">
                  <div class="col-md-2">
                    <label class="pt-2 pb-3 mb-0">
                      <span class="text-danger">*</span>促銷種類
                    </label>
                  </div>
                  <div class="col-md-10">
                    <p class="text-danger">請依照需求選擇商品或折抵金額</p>
                    <div class="row form-group">
                      <div class="col-md-2">
                        <div class="d-inline-flex align-items-center radio icheck-turquoise">
                          <input
                            type="radio"
                            name="type"
                            id="discount_product"
                            value="gift"
                            v-model="promoCodeForm.type"
                          />
                          <label for="discount_product" class="mx-1 mb-0">兌換商品</label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <select
                          id="giftProduct1"
                          class="form-control selectbs2like"
                          style="width: 100%"
                          v-model="promoCodeForm.commodities_id"
                        >
                          <option disabled value>--請選擇產品--</option>
                          <option
                            v-for="item in giftOptions"
                            :key="item.id"
                            :value="item.id"
                          >{{ item.name }} / {{ item.specification_description }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-md-2">
                        <div class="d-inline-flex align-items-center radio icheck-turquoise">
                          <input
                            type="radio"
                            name="type"
                            id="discount_price"
                            value="discount"
                            v-model="promoCodeForm.type"
                          />
                          <label for="discount_price" class="mx-1 mb-0">折抵金額</label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="輸入金額"
                          v-model="promoCodeForm.discount"
                          :required="promoCodeForm.type === 'discount'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h5 class="mt-2 mb-3">使用限制</h5>
                <hr />
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 150px;" class="align-middle">
                        <span class="text-danger">*</span>最低訂單金額
                      </th>
                      <td>
                        <input
                          type="text"
                          class="form-control col-md-6"
                          placeholder="請輸入金額"
                          v-model="promoCodeForm.limit_amount"
                        />
                        <div class="text-danger">滿多少金額訂單才能使用此折扣碼，最低為0</div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 150px;" class="align-middle">總使用次數上限</th>
                      <td>
                        <input
                          type="text"
                          class="form-control col-md-6"
                          placeholder="請輸入次數"
                          v-model="promoCodeForm.limit_count"
                        />
                        <div class="text-danger">總共能使用的次數，若沒有次數限制則不填</div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 150px;" class="align-middle">每個帳號可使用次數</th>
                      <td>
                        <input
                          type="text"
                          class="form-control col-md-6"
                          placeholder="請輸入次數"
                          v-model="promoCodeForm.limit_account_count"
                        />
                        <div class="text-danger">若沒有帳號使用次數限制則不填</div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="text-center">
                  <button @click.prevent="cancel" class="btn btn-default mr-1">取消</button>
                  <button @click.prevent="send" class="btn btn-primary">儲存</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'PromoCodeEdit',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { createPromoCode, getPromoCodeConfig, editPromoCode, getGitfcommodity } from '@/apis/promoCode'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

const router = useRouter()

const breadCrumbs = ref({
  parentPage: '優惠',
  currentPage: '折扣碼管理',
  currentPath: '/admin/Discount/PromoCode/list',
  childPage: '新增折扣碼',
})

const isLoading = ref(false)

const promoCodeForm = ref({
  name: '',
  start_at: null,
  end_at: null,
  promo_code: '',
  type: '',
  limit_amount: '',
  limit_count: '',
  limit_account_count: '',
  commodities_id: '',
  discount: '',
  specify: '',
})

const checkTitle = () => {
  breadCrumbs.value.childPage = location.href.indexOf('add') > 0 ? '新增折扣碼' : '編輯折扣碼'
}

onMounted(() => {
  checkTitle()
  getGiftOptions()
})

const giftOptions = ref([])
const getGiftOptions = async () => {
  isLoading.value = true
  try {
    const res = await getGitfcommodity({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      giftOptions.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      if (location.href.indexOf('add') < 0) {
        getPromoCodeData()
      }
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const getPromoCodeData = async () => {
  isLoading.value = true
  try {
    const res = await getPromoCodeConfig({
      promo_code_id: localStorage.getItem('promoCodeId'),
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      setPromoCodeData(res.data.data)
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const setPromoCodeData = (data) => {
  promoCodeForm.value.name = data.name
  promoCodeForm.value.start_at = formatTimestamp(data.start_at)
  promoCodeForm.value.end_at = (data.end_at > 0) ? formatTimestamp(data.end_at) : null
  promoCodeForm.value.promo_code = data.promo_code
  promoCodeForm.value.specify = data.specify
  promoCodeForm.value.type = data.type
  promoCodeForm.value.commodities_id = data.commodities_id
  promoCodeForm.value.discount = data.discount
  promoCodeForm.value.limit_amount = data.limit_amount
  promoCodeForm.value.limit_account_count = data.limit_account_count
  promoCodeForm.value.limit_count = data.limit_count
}

// const hashPromoCode = () => {

// }

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-')
  const date = new Date(year, month - 1, day)
  const unixTimestamp = date.getTime()
  const unixTimestampSeconds = Math.floor(unixTimestamp / 1000)
  return unixTimestampSeconds
}

const formatTimestamp = (unix) => {
  if (!unix) return ''
  const date = new Date(unix * 1000)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const dateString = `${year}-${month}-${day}`
  return dateString
}

const send = async () => {
  isLoading.value = true
  const dataForm = JSON.parse(JSON.stringify(promoCodeForm.value))
  dataForm.start_at = formatDate(promoCodeForm.value.start_at)
  delete dataForm.end_at
  if (promoCodeForm.value.end_at) {
    dataForm.end_at = formatDate(promoCodeForm.value.end_at)
  }
  try {
    const res = (breadCrumbs.value.childPage === '新增折扣碼')
      ? await createPromoCode({
        ...dataForm,
        token: localStorage.getItem('token')
      })
      : await editPromoCode({
        ...dataForm,
        token: localStorage.getItem('token')
      })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: `${breadCrumbs.value.childPage}成功`,
      }).then(() => {
        cancel()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    console.info(error)
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const cancel = () => {
  router.push(breadCrumbs.value.currentPath)
}

</script>

<style lang="scss" scoped>
</style>