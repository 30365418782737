import request from '@/utils/request';

// 系統相關-主機設定
export function getImageCount(param) {
  return request({
    url: `/system/getimagecount`,
    method: 'post',
    data: param,
  });
}

// 取得網站資料
export function getWebsiteData(param) {
  return request({
    url: `/system/getwebsitedata`,
    method: 'post',
    data: param,
  });
}

// 更新網站資料
export function updateWebsiteData(param) {
  return request({
    url: `/system/updatewebsitedata`,
    method: 'post',
    data: param,
  });
}

// 取得SEO設定資料
export function getSeoSitemap(param) {
  return request({
    url: `/system/getseositemap`,
    method: 'post',
    data: param,
  });
}

// 更新SEO設定資料
export function updateSeoSitemap(param) {
  return request({
    url: `/system/updateseositemap`,
    method: 'post',
    data: param,
  });
}