<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="活動名稱"
                      v-model="searchForm.name"
                    />
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-5">
                    <div class="input-daterange input-group" id="date-range">
                      <input
                        type="date"
                        class="form-control day_start"
                        name="date_start"
                        placeholder="起日"
                        v-model="searchForm.start_day"
                      />
                      <span class="input-group-text rounded-0">～</span>
                      <input
                        type="date"
                        class="form-control day_end"
                        name="date_end"
                        placeholder="迄日"
                        v-model="searchForm.end_day"
                      />
                    </div>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-5">
                    <button class="btn btn-primary mr-1" @click.prevent="getList()">搜尋</button>
                    <button class="btn btn-default" @click.prevent="reset">清空</button>
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col-12 mb-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick="location.href = '/admin/Discount/Price/add'"
                  >新增滿額活動</button>
                </div>
              </div>
              <div class="table-responsive">
                <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                  <template #full_amount="{ scope }">{{ checkMoney(scope.full_amount) }} 元</template>
                  <template #discount_gift="{ scope }">
                    <div class="form-group" v-for="item in scope.discount_gift" :key="item.id">
                      <div
                        v-if="item.commodity_specification"
                      >產品：{{ item.commodity_specification.commodities.name}}</div>
                      <!-- <hr /> -->
                    </div>
                    <div class="form-group" v-show="scope.bonus">紅利 {{scope.bonus}}</div>
                    <div class="form-group" v-show="scope.point">點數 {{scope.point}}</div>
                  </template>
                  <template #enable="{ scope }">
                    <Toggle
                      v-model="scope.enable"
                      falseValue="N"
                      trueValue="Y"
                      @change="changeEnable(scope.id, scope.enable)"
                    />
                  </template>
                  <template #edit="{ scope }">
                    <a class="btn-table edit" @click.prevent="editItem(scope.id)">
                      <i class="ion-edit"></i>
                    </a>
                  </template>
                  <template #delete="{ scope }">
                    <a class="btn-table delete" @click.prevent="deleteItem(scope.id, scope.name)">
                      <i class="ion-ios-trash"></i>
                    </a>
                  </template>
                </DataTable>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'PriceList',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { getdActivitesList, changedActivitesEnable, deleteActivites } from '@/apis/discount'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import { checkMoney } from '@/utils/checkMoney'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'

const breadCrumbs = ref({
  parentPage: '優惠',
  currentPage: '滿額活動管理',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '活動名稱',
    label: 'name',
  },
  {
    name: '滿額條件',
    label: 'full_amount',
    customLabel: 'full_amount',
    custom: true
  },
  {
    name: '贈送種類',
    label: 'discount_gift',
    customLabel: 'discount_gift',
    custom: true
  },
  {
    name: '開始日期',
    label: 'start_day',
  },
  {
    name: '結束日期',
    label: 'end_day',
  },
  {
    name: '狀態',
    label: 'enable',
    customLabel: 'enable',
    custom: true
  },
  {
    name: '編輯',
    label: 'edit',
    customLabel: 'edit',
    custom: true
  },
  {
    name: '刪除',
    label: 'delete',
    customLabel: 'delete',
    custom: true
  },
])

const listData = ref({
  data: []
})

const searchForm = ref({
  name: null,
  start_day: null,
  end_day: null,
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  const searchData = {}
  if (searchForm.value.name) {
    searchData.name = searchForm.value.name
  }
  if (searchForm.value.start_at) {
    searchData.start_at = searchForm.value.start_at
  }
  if (searchForm.value.end_at) {
    searchData.end_at = searchForm.value.end_at
  }
  try {
    const res = await getdActivitesList({
      ...searchData,
      page,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

onMounted(() => {
  getList()
})

const changeEnable = async (id, enable) => {
  isLoading.value = true
  try {
    const res = await changedActivitesEnable({
      id,
      enable,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      getList(pageNum.value)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const reset = () => {
  searchForm.value = {
    name: null,
    start_day: null,
    end_day: null,
  }
  getList()
}

const editItem = (id) => {
  localStorage.setItem('discountActivitesId', id)
  location.href = `/admin/Discount/Price/edit`
}

const deleteItem = async (id, name) => {
  Swal.fire({
    icon: "error",
    title: `確定刪除「${name}」？`,
    text: "刪除後將不可還原",
    confirmButtonText: "確認",
    cancelButtonText: "取消",
    confirmButtonColor: '#fe5b52',
    cancelButtonColor: '#dddddd',
    showCancelButton: true,
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true
      try {
        const res = await deleteActivites({
          id,
          token: localStorage.getItem('token')
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          getList()
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          isLoading.value = false
          wrongRequest(res.data)
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
    }
  })
}

</script>

<style lang="scss" scoped>
</style>
<style src="@vueform/toggle/themes/default.css"></style>