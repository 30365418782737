import request from '@/utils/request';

//------------- 底部文章管理 -------------

// 網站設定 - 底部文章管理  / 網頁文宣，公司簡介，隱私權政策那些有的沒的
export function getWebcontent(param) {
  return request({
    url: `/webcontent/copywriting`,
    method: 'post',
    data: param,
  });
}

// 網站設定 - 底部文章管理  / 網站文宣，隱私權政策那些的CK編輯器圖片上傳用
export function uploadImage(param) {
  return request({
    url: `/webcontent/copywritingckupload`,
    method: 'post',
    data: param,
  });
}

// 網站設定 - 底部文章管理  / 設定文案內容，隱私權政策那些
export function updateWebcontent(param) {
  return request({
    url: `/webcontent/setcopywriting`,
    method: 'post',
    data: param,
  });
}
//------------- 文章管理 -------------

// 網站設定 - 取得文章列表
export function getArticleList(param) {
  return request({
    url: `/webcontent/getarticle`,
    method: 'post',
    data: param,
  });
}

// 網站設定 - 修改文章狀態
export function changeArticleEnable(param) {
  return request({
    url: `/webcontent/articleenable`,
    method: 'post',
    data: param,
  });
}

// 網站設定 - 新增、編輯文章，取得是文章分類的選單
export function getMenuOptions(param) {
  return request({
    url: `/webcontent/getarticlemenu`,
    method: 'post',
    data: param,
  });
}

// 網站設定 - 新增、編輯文章，取得是產品分類的選單
export function getProductOptions(param) {
  return request({
    url: `/commodity/commoditymenu`,
    method: 'post',
    data: param,
  });
}

// 網站設定 - 刪除文章
export function delArticle(param) {
  return request({
    url: `/webcontent/delarticle`,
    method: 'post',
    data: param,
  });
}

// 網站設定 - 新增文章
export function addArticle(param) {
  return request({
    url: `/webcontent/createarticle`,
    method: 'post',
    data: param,
  });
}

// 網站設定 - 文章管理  / HTML編輯器上傳文章圖片用
export function uploadImageAll(param) {
  return request({
    url: `/webcontent/articleckupload`,
    method: 'post',
    data: param,
  });
}

// 網站設定 - 修改時取得文章資料
export function getArticleData(param) {
  return request({
    url: `/webcontent/getarticledate`,
    method: 'post',
    data: param,
  });
}

// 網站設定 - 修改文章
export function updateArticle(param) {
  return request({
    url: `/webcontent/editarticle`,
    method: 'post',
    data: param,
  });
}