<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage"></DataTable>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'LoginRecord',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
// import { baseURL } from '@/utils/baseWebURL'
import { getLoginList } from '@/apis/log'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  currentPage: '登入紀錄',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '姓名',
    label: 'name',
  },
  {
    name: '帳號',
    label: 'account',
  },
  // {
  //   name: '登入事項	',
  //   label: 'method',
  //   customLabel: 'method',
  //   custom: true
  // },
  // {
  //   name: '來源IP',
  //   label: 'ip',
  //   customLabel: 'ip',
  //   custom: true
  // },
  {
    name: '登入時間',
    label: 'updated_at',
  }
])

const listData = ref({
  data: []
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  try {
    const res = await getLoginList({
      page,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      isLoading.value = false
      localStorage.setItem('token', res.data.new_token)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

onMounted(() => {
  getList()
})

</script>

<style lang="scss" scoped>
</style>