<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row form-group">
                <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4">
                  <div class="input-daterange input-group" id="date-range">
                    <input
                      type="date"
                      class="form-control day_start"
                      name="date_start"
                      placeholder="起日"
                      v-model="searchForm.start_time"
                    />
                    <span class="input-group-text rounded-0">～</span>
                    <input
                      type="date"
                      class="form-control day_end"
                      name="date_end"
                      placeholder="迄日"
                      v-model="searchForm.end_time"
                    />
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4">
                  <v-select
                    :options="memberOptions"
                    label="label"
                    :reduce="(option) => option.id"
                    v-model="searchForm.member_id"
                  ></v-select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="訂單號碼"
                    v-model="searchForm.order_num"
                  />
                </div>
              </div>
              <div class="my-2">
                <div class="icheck-turquoise d-inline-block mr-2 group_type">
                  <input
                    type="radio"
                    id="bonus_increase"
                    name="bonus_send_type"
                    value="addition"
                    v-model="searchForm.type"
                  />
                  <label for="bonus_increase" class="form-check-label">贈送</label>
                </div>
                <div class="icheck-turquoise d-inline-block mr-2 group_type">
                  <input
                    type="radio"
                    id="bonus_decrease"
                    name="bonus_send_type"
                    value="deduct"
                    v-model="searchForm.type"
                  />
                  <label for="bonus_decrease" class="form-check-label">扣除</label>
                </div>
                <div class="icheck-turquoise d-inline-block mr-2 group_type">
                  <input
                    type="radio"
                    id="bonus_fail"
                    name="bonus_send_type"
                    value="invalid"
                    v-model="searchForm.type"
                  />
                  <label for="bonus_fail" class="form-check-label">失效</label>
                </div>
              </div>
              <div class="mb-2">
                <button class="btn btn-primary mr-2" @click.prevent="getList()">搜尋</button>
                <button class="btn btn-default" @click.prevent="reset">清空</button>
              </div>
              <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                <template #order_num="{ scope }">{{ formatNull(scope.order_num) }}</template>
                <template #member="{ scope }">{{ scope.member.name }} / {{ scope.member.account }}</template>
                <template #amount="{ scope }">
                  <span class="text-pink" v-show="scope.amount < 0">{{ scope.amount }}</span>
                  <span class="text-success" v-show="scope.amount >= 0">{{ scope.amount }}</span>
                </template>
                <template #balance="{ scope }">
                  <span class="text-primary">{{ scope.balance }}</span>
                </template>
                <template #remark="{ scope }">
                  <i
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="scope.remark"
                    class="far fa-sticky-note"
                  ></i>
                </template>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'BonusRecord',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { getBonusList } from '@/apis/bonusandpoint'
import { getAllMemberList } from '@/apis/order'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  parentPage: '點數',
  currentPage: '紅利紀錄',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '日期',
    label: 'created_at',
  },
  {
    name: '訂單號碼',
    label: 'order_num',
    customLabel: 'order_num',
    custom: true
  },
  {
    name: '會員',
    label: 'member',
    customLabel: 'member',
    custom: true
  },
  {
    name: '原因',
    label: 'cause',
  },
  {
    name: '紅利',
    label: 'amount',
    customLabel: 'amount',
    custom: true
  },
  {
    name: '結餘',
    label: 'balance',
    customLabel: 'balance',
    custom: true
  },
  {
    name: '備註',
    label: 'remark',
    customLabel: 'remark',
    custom: true
  }
])

const searchForm = ref({
  member_id: '', // 會員
  start_time: '', // 2023-04-01
  end_time: '', // end_time
  order_num: '', // 訂單號碼
  type: 'addition', // addition贈送  deduct扣除 invalid失效
})

const listData = ref({
  data: []
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  const searchData = {}
  Object.entries(searchForm.value).forEach(([key, value]) => {
    if (value !== '' && value !== null) {
      searchData[key] = value
    }
  })
  try {
    const res = await getBonusList({
      ...searchData,
      page,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

const memberOptions = ref([])
const getMemberOptions = async () => {
  isLoading.value = true
  try {
    const res = await getAllMemberList({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      memberOptions.value = res.data.data.map(obj => ({
        ...obj,
        label: `${obj.name}/${obj.cell_phone}`
      }))
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      getList()
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getMemberOptions()
})

const formatNull = (item) => {
  return (!item) ? '-' : item
}

const reset = () => {
  searchForm.value = {
    member_id: '',
    start_time: '',
    end_time: '',
    order_num: '',
    type: 'addition',
  }
  getList()
}
</script>

<style lang="scss" scoped>
</style>