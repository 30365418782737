import request from '@/utils/request';

// 取得後臺帳號列表
export function getAdminList(param) {
  return request({
    url: `/admin/getlist`,
    method: 'post',
    data: param,
  });
}

// 取得後臺帳號列表
export function updateAdminEnable(param) {
  return request({
    url: `/admin/updateenable`,
    method: 'post',
    data: param,
  });
}

// 新增後台帳號
export function addAdmin(param) {
  return request({
    url: `/admin/createaccount`,
    method: 'post',
    data: param,
  });
}

// 取得後台帳號編輯資料
export function getAdminData(param) {
  return request({
    url: `/admin/getdetail`,
    method: 'post',
    data: param,
  });
}

// 編輯後台帳號
export function updateAdmin(param) {
  return request({
    url: `/admin/editaccount`,
    method: 'post',
    data: param,
  });
}

// // 更改密碼
// export function updatePassword(param) {
//   return request({
//     url: `/admin/editpassword`,
//     method: 'post',
//     data: param,
//   });
// }

// 取得後台選單
export function getMenuPermission(param) {
  return request({
    url: `/adminmenu/getlist`,
    method: 'post',
    data: param,
  });
}

// 更新後台選單權限
export function updateMenuPermission(param) {
  return request({
    url: `/adminmenu/update`,
    method: 'post',
    data: param,
  });
}