<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "~@/assets/css/admin/admin.css";
@import "~@/assets/css/rwd.css";
#app {
  font-size: 14px;
  .content-wrapper {
    transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out !important;
  }
}
.btn-default {
  background-color: #f8f9fa !important;
  border-color: #ddd !important;
  color: #444 !important;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
