<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12 col-md-3">
                    <select name="'view_mode'" class="form-control" v-model="searchForm.cycle">
                      <option value="day">以日計算</option>
                      <option value="month">以月計算</option>
                    </select>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-12 col-md-5">
                    <div class="input-daterange input-group" id="date-range">
                      <input
                        type="date"
                        class="form-control day_start"
                        name="'date_start'"
                        placeholder="起日"
                        v-model="searchForm.start_time"
                      />
                      <span class="input-group-text rounded-0">～</span>
                      <input
                        type="date"
                        class="form-control day_end"
                        name="'date_end'"
                        placeholder="迄日"
                        v-model="searchForm.end_time"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4 viewBtn">
                    <button
                      type="button"
                      class="btn btn-default"
                      @click.prevent="calcDaysRange(7)"
                    >過去7天</button>
                    <button
                      type="button"
                      class="btn btn-default"
                      @click.prevent="calcDaysRange(30)"
                    >過去30天</button>
                    <button
                      type="button"
                      class="btn btn-default"
                      @click.prevent="calcMonthRange(0)"
                    >本月</button>
                    <button
                      type="button"
                      class="btn btn-default"
                      @click.prevent="calcMonthRange(1)"
                    >上月</button>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-12 col-md-3">
                    <label class="col-form-label">商品</label>
                    <v-select
                      :options="productOptions"
                      label="label"
                      :reduce="(option) => option.commodities_specification_id"
                      multiple
                      v-model="searchForm.specification_id"
                    ></v-select>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-4">
                    <button class="btn btn-primary mr-2" @click.prevent="getList()">搜尋</button>
                    <button class="btn btn-default" @click.prevent="reset">清空</button>
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col-sm-12 col-md-4">
                  <button class="btn btn-sm btn-primary mb-2" @click.prevent="getDownloadKey">
                    <i class="fas fa-file-excel mr-1"></i>匯出
                  </button>
                </div>
              </div>
              <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage"></DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="'fade'">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'ReportProduct',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { baseURL } from '@/utils/baseWebURL'
import { getProductReport, getExcel } from '@/apis/report'
import { getProductList } from '@/apis/order'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import axios from 'axios'

const breadCrumbs = ref({
  parentPage: '報表',
  currentPage: '產品報表',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '排名',
    label: 'no',
  },
  {
    name: '銷售數',
    label: 'sales_count',
  },
  {
    name: '退貨數',
    label: 'returns_count',
  },
  {
    name: '訂單筆數',
    label: 'order_count',
  },
  {
    name: '產品名稱',
    label: 'name',
  },
  {
    name: '貨號',
    label: 'commodities_number',
  },
])

const searchForm = ref({
  cycle: 'day', // day 以日計算  / month 以月計算
  start_time: '', // 2023-04-01
  end_time: '', // 2023-04-01
  specification_id: [],
})

const listData = ref({
  data: []
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  const searchData = {}
  searchData.cycle = searchForm.value.cycle
  if (searchForm.value.specification_id.length > 0) {
    searchData.specification_id = searchForm.value.specification_id.join(',')
  }
  if (searchForm.value.start_time) {
    searchData.start_time = searchForm.value.start_time
  }
  if (searchForm.value.end_time) {
    searchData.end_time = searchForm.value.end_time
  }
  try {
    const res = await getProductReport({
      ...searchData,
      page,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

const productOptions = ref([])
const gerProductOptions = async () => {
  isLoading.value = true
  try {
    const res = await getProductList({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      productOptions.value = res.data.data.map(obj => ({
        ...obj,
        label: `${obj.commodities_name}/${obj.specification_name}`
      }))
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  gerProductOptions()
  // 預設帶入 30 天前
  calcDaysRange(30)
})

const calcDaysRange = (days) => {
  const date = new Date()
  searchForm.value.start_time = dayjs(date).subtract(days, 'day').format('YYYY-MM-DD')
  searchForm.value.end_time = dayjs(date).format('YYYY-MM-DD')
  getList()
}

const calcMonthRange = (lastMonth) => {
  // 上個月第一天
  searchForm.value.start_time = dayjs().subtract(lastMonth, 'month').startOf('month').format('YYYY-MM-DD')
  // 上個月最後一天
  searchForm.value.end_time = dayjs().subtract(lastMonth, 'month').endOf('month').format('YYYY-MM-DD')
  getList()
}

const reset = () => {
  searchForm.value = {
    cycle: 'day',
    start_time: '',
    end_time: '',
    specification_id: [],
  }
  getList()
}

const getDownloadKey = async () => {
  isLoading.value = true
  const searchData = {}
  searchData.cycle = searchForm.value.cycle
  if (searchForm.value.specification_id.length > 0) {
    searchData.specification_id = searchForm.value.specification_id.join(',')
  }
  if (searchForm.value.start_time) {
    searchData.start_time = searchForm.value.start_time
  }
  if (searchForm.value.end_time) {
    searchData.end_time = searchForm.value.end_time
  }
  try {
    const res = await getProductReport({
      ...searchData,
      page: 1,
      limit: 9999,
      get_excls_url: 'Y',
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      exportExcel(res.data.data)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const exportExcel = async (key) => {
  const res = await getExcel(key)
  if (res) {
    window.open(`${baseURL}exclsPost?key=${key}`)
  }
}

</script>

<style lang="scss" scoped>
</style>