<template>
  <Preloader v-if="pageLoading" />

  <div
    class="hold-transition sidebar-mini layout-fixed"
    :class="{ 'sidebar-collapse': isSidebarCollapse }"
  >
    <div class="wrapper">
      <Navbar />
      <Sidebar />

      <div class="content-wrapper">
        <router-view />
      </div>

      <Footer />
      <ControlSidebar />
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
import "../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
import "../node_modules/admin-lte/plugins/select2/css/select2.min.css"
import Preloader from "@/components/Preloader.vue"
import Navbar from "@/dashboard/Navbar.vue"
import Sidebar from "@/dashboard/Sidebar.vue"
import Footer from "@/dashboard/Footer.vue"
import ControlSidebar from "@/dashboard/ControlSidebar.vue"
import { useAdminStore } from '@/store/admin'

export default {
  name: "Layout",
  components: {
    Preloader,
    Navbar,
    Sidebar,
    Footer,
    ControlSidebar
  },
  data: () => ({
    pageLoading: true,
  }),
  computed: {
    isSidebarCollapse() {
      return useAdminStore().isSidebarCollapse;
    }
  },
  methods: {

    initializeSelect2() {
      //Initialize Select2 Elements
      $(".select2").select2()

      //Initialize Select2 Elements
      $(".select2bs4").select2({
        theme: "bootstrap4",
      })
    },
    showPreloader() {
      setTimeout(() => {
        this.pageLoading = false
      }, 1500)
    },
  },
  mounted() {
    this.initializeSelect2()
    this.showPreloader()
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");

a{
  text-decoration: unset !important;
}
</style>
