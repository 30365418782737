import request from '@/utils/request';

// 取得操作紀錄
export function getLogList(param) {
  return request({
    url: `/log`,
    method: 'post',
    data: param,
  });
}

// 取得登入紀錄
export function getLoginList(param) {
  return request({
    url: `/admin/loginlog`,
    method: 'post',
    data: param,
  });
}
