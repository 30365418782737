import request from '@/utils/request';

// 取得紅利點數設定
export function getBonusConfig(param) {
  return request({
    url: `/bonusandpoint/getbonusconfig`,
    method: 'post',
    data: param,
  });
}

// 更新紅利點數設定
export function updateBonusConfig(param) {
  return request({
    url: `/bonusandpoint/setbonusconfig`,
    method: 'post',
    data: param,
  });
}

// 紅利和點數增減
export function increaseOrDecreaseAmount(param) {
  return request({
    url: `/bonusandpoint/amount`,
    method: 'post',
    data: param,
  });
}

// 取得紅利紀錄
export function getBonusList(param) {
  return request({
    url: `/bonusandpoint/getbonuslist`,
    method: 'post',
    data: param,
  });
}

// 取得點數紀錄
export function getPointList(param) {
  return request({
    url: `/bonusandpoint/getpointlist`,
    method: 'post',
    data: param,
  });
}