<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div class="card">
            <div class="card-body">
              <form>
                <h5 class="mt-2 mb-4">紅利發放設定</h5>
                <hr />
                <div class="row">
                  <div class="col-md-2">
                    <label class="mb-0 mb-2 mb-md-0">發放時機</label>
                  </div>
                  <div class="col-md-10 ml-3 ml-md-0">
                    <div class="form-group">
                      <label>發送天數</label>
                      <input
                        type="text"
                        class="form-control"
                        value
                        placeholder="輸入天數"
                        v-model="configForm.sent_day"
                      />
                      <div class="text-danger">訂單「已完成」後，第幾天發送點數</div>
                    </div>
                    <div class="form-group">
                      <label>到期天數</label>
                      <input
                        type="text"
                        class="form-control"
                        value
                        placeholder="輸入天數"
                        v-model="configForm.bonus_expired_day"
                      />
                      <div class="text-danger">獲得後X天到期，不輸入就不限制，輸入1~365天，每天23:59到期</div>
                    </div>
                  </div>
                </div>
                <h5 class="mt-2 mb-4">紅利贈送設定</h5>
                <hr />
                <div class="row">
                  <div class="col-md-2">
                    <label class="mb-0 mb-2 mb-md-0">訂單滿額活動</label>
                  </div>
                  <div class="col-md-10 ml-3 ml-md-0">
                    <div class="row form-group">
                      <div class="col-12 col-md-6 mb-2 mb-md-0">
                        <input
                          type="text"
                          class="form-control"
                          value
                          placeholder="輸入滿額比例"
                          v-model="configForm.full_money_ratio"
                        />
                      </div>
                      <div class="col-12 col-md-6 mb-2 mb-md-0">
                        <input
                          type="text"
                          class="form-control"
                          value
                          placeholder="輸入贈送紅利"
                          v-model="configForm.gift_bonus"
                        />
                      </div>
                      <div class="col-12">
                        <div class="text-danger">每多少購買金額才獲得多少紅利，例如滿千送百、滿百送十元</div>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-12 col-md-6">
                        <input
                          type="text"
                          class="form-control"
                          value
                          placeholder="輸入最高贈送紅利"
                          v-model="configForm.gift_limit"
                        />
                      </div>
                      <div class="col-12">
                        <div class="text-danger">送到多少紅利之後就不在向上追加贈送</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <label class="mb-0 mb-2 mb-md-0">生日禮</label>
                  </div>
                  <div class="col-md-10 ml-3 ml-md-0">
                    <div class="form-group">
                      <span>每月1號早上9點發送</span>
                      <span class="ml-3 text-danger">系統使用排程每日作業發送</span>
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control col-md-6"
                        value
                        placeholder="輸入贈送紅利"
                        v-model="configForm.birthday_bonus"
                      />
                      <span class="text-danger">贈送後將寫入紀錄備註</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <label class="mb-0 mb-2 mb-md-0">註冊禮</label>
                  </div>
                  <div class="col-md-10 ml-3 ml-md-0">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control col-md-6"
                        value
                        placeholder="輸入贈送紅利"
                        v-model="configForm.register_bonus"
                      />
                      <span class="text-danger">贈送後將寫入紀錄備註</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <label class="mb-0 mb-2 mb-md-0">首購禮</label>
                  </div>
                  <div class="col-md-10 ml-3 ml-md-0">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control col-md-6"
                        value
                        placeholder="輸入贈送紅利"
                        v-model="configForm.first_order_bonus"
                      />
                      <span class="text-danger">贈送後將寫入紀錄備註</span>
                    </div>
                  </div>
                </div>
                <h5 class="mt-2 mb-4">訂單折抵設定</h5>
                <hr />
                <div class="row">
                  <div class="col-md-2">
                    <label class="mb-0 mb-2 mb-md-0">訂單折抵比例</label>
                  </div>
                  <div class="col-md-10 ml-3 ml-md-0">
                    <div class="row form-group">
                      <div class="col-12 col-md-6 mb-2 mb-md-0">
                        <input
                          type="text"
                          class="form-control"
                          value
                          placeholder="輸入折抵紅利"
                          v-model="configForm.bonus_discount"
                        />
                      </div>
                      <div class="col-12 col-md-6 mb-2 mb-md-0">
                        <input
                          type="text"
                          class="form-control"
                          value
                          placeholder="輸入折抵金額"
                          v-model="configForm.money_discount"
                        />
                      </div>
                      <div class="col-12">
                        <div class="text-danger">每多少紅利，可折抵多少金額</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="d-block mr-3 mb-2">訂單折抵限制</label>
                      <div class="row">
                        <div class="col-12 form-group">
                          <div class="row">
                            <div class="col-md-2">
                              <div class="d-inline-flex align-items-center radio icheck-turquoise">
                                <input
                                  type="radio"
                                  name="method"
                                  id="method_1"
                                  value="limit_fixed"
                                  v-model="limitType"
                                />
                                <label for="method_1" class="mx-1 mb-0">固定</label>
                              </div>
                            </div>
                            <div class="col-md-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="折抵金額"
                                v-model="configForm.money_discount_limit_fixed"
                              />
                              <div class="text-danger">每多少紅利，可折抵多少金額</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 form-group">
                          <div class="row">
                            <div class="col-md-2">
                              <div
                                class="d-inline-flex align-items-center mr-2 radio icheck-turquoise"
                              >
                                <input
                                  type="radio"
                                  name="method"
                                  id="method_2"
                                  value="limit_proportion"
                                  v-model="limitType"
                                />
                                <label for="method_2" class="mx-1 mb-0">比例</label>
                              </div>
                            </div>
                            <div class="col-md-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="百分比"
                                v-model="configForm.money_discount_limit_proportion"
                              />
                              <div class="text-danger">每張訂單最多可折抵多少%金額</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 form-group">
                          <div class="row">
                            <div class="col-md-2">
                              <div
                                class="d-inline-flex align-items-center mr-2 radio icheck-turquoise"
                              >
                                <input
                                  type="radio"
                                  name="method"
                                  id="method_3"
                                  value="no_limit"
                                  v-model="limitType"
                                />
                                <label for="method_3" class="mx-1 mb-0">不限制</label>
                              </div>
                            </div>
                            <div class="col-md-9"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button @click.prevent="reset" class="btn btn-default mr-1">取消</button>
                  <button @click.prevent="send" class="btn btn-primary">儲存</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'PointSetting',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { getBonusConfig, updateBonusConfig } from '@/apis/bonusandpoint'
import Swal from 'sweetalert2'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'

const breadCrumbs = ref({
  parentPage: '點數',
  currentPage: '紅利設定',
})

const isLoading = ref(false)

const configForm = ref({
  sent_day: null, // 發送天數
  bonus_expired_day: null, // 過期天數
  full_money_ratio: null, // 訂單滿額活動，滿額比例(%)
  gift_bonus: null, // 訂單滿額活動，贈送紅利
  gift_limit: null, // 訂單滿額活動，贈送最高階紅利
  birthday_bonus: null, // 生日禮 紅利
  register_bonus: null, // 註冊禮 紅利
  first_order_bonus: null, // 首購禮 紅利
  bonus_discount: null, // 折抵紅利
  money_discount: null, // 折抵現金
  money_discount_limit_fixed: null, // 折抵金額
  money_discount_limit_proportion: 5, // 折抵百分比(%)
  limit_fixed: "Y", // 固定
  limit_proportion: "N", // 比例
  no_limit: "N", // 不限制
})
const cloneConfigForm = ref({})

const getConfig = async () => {
  isLoading.value = true
  try {
    const res = await getBonusConfig({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      configForm.value = res.data.data
      cloneConfigForm.value = JSON.parse(JSON.stringify(res.data.data))
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      checktLimitType()
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getConfig()
})

const limitType = ref('limit_fixed')
const checktLimitType = () => {
  if (configForm.value.limit_fixed === 'Y') {
    limitType.value = 'limit_fixed'
  } else if (configForm.value.limit_proportion === 'Y') {
    limitType.value = 'limit_proportion'
  } else if (configForm.value.no_limit === 'Y') {
    limitType.value = 'no_limit'
  }
}

const send = async () => {
  isLoading.value = true
  if (limitType.value === 'limit_fixed') {
    configForm.value.limit_fixed = 'Y'
    configForm.value.limit_proportion = 'N'
    configForm.value.no_limit = 'N'
  }
  if (limitType.value === 'limit_proportion') {
    configForm.value.limit_fixed = 'N'
    configForm.value.limit_proportion = 'Y'
    configForm.value.no_limit = 'N'
  }
  if (limitType.value === 'no_limit') {
    configForm.value.limit_fixed = 'N'
    configForm.value.limit_proportion = 'N'
    configForm.value.no_limit = 'Y'
  }
  try {
    const res = await updateBonusConfig({
      ...configForm.value,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      Swal.fire({
        icon: 'success',
        title: res.data.data,
      })
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      getConfig()
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const reset = () => {
  configForm.value = cloneConfigForm.value
}

</script>

<style lang="scss" scoped>
</style>