<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-md-6 col-xl-4">
                      <div class="input-daterange input-group" id="date-range">
                        <input
                          type="date"
                          class="form-control day_start"
                          name="date_start"
                          placeholder="起日"
                          v-model="searchForm.start_day"
                        />
                        <span class="input-group-text rounded-0">～</span>
                        <input
                          type="date"
                          class="form-control day_end"
                          name="date_end"
                          placeholder="迄日"
                          v-model="searchForm.end_day"
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div class="row form-group">
                  <div class="col-md-2 mb-2 mb-md-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="英文字母"
                      v-model="searchForm.invoice_str"
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control day_start text-center"
                        name="number_start"
                        placeholder="開始號碼"
                        v-model="searchForm.invoice_num_start"
                      />
                      <span class="input-group-text rounded-0 bg-light">～</span>
                      <input
                        type="text"
                        class="form-control day_end text-center"
                        name="number_end"
                        placeholder="結束號碼"
                        v-model="searchForm.invoice_num_end"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-3 col-xl-2 mb-2 mb-md-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="訂單號碼"
                      v-model="searchForm.order_num"
                    />
                  </div>
                  <div class="col-md-3 col-xl-2 mb-2 mb-md-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="會員姓名"
                      v-model="searchForm.name"
                    />
                  </div>
                  <div class="col-md-3 col-xl-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="統一編號"
                      v-model="searchForm.uniform_numbers"
                    />
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-sm-12 col-md-6">
                    <div class="d-inline-flex align-items-center radio icheck-turquoise">
                      <input
                        type="radio"
                        name="status"
                        id="status_1"
                        value="Y"
                        v-model="searchForm.establish"
                      />
                      <label for="status_1" class="mx-1 mb-0">開立</label>
                    </div>
                    <div class="d-inline-flex align-items-center mr-2 radio icheck-turquoise">
                      <input
                        type="radio"
                        name="status"
                        id="status_2"
                        value="N"
                        v-model="searchForm.establish"
                      />
                      <label for="status_2" class="mx-1 mb-0">作廢</label>
                    </div>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-4">
                    <button class="btn btn-primary mr-1" @click.prevent="getList()">搜尋</button>
                    <button class="btn btn-default" @click.prevent="reset">清空</button>
                  </div>
                </div>
              </form>
              <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                <template #establish="{ scope }">
                  <span class="badge badge-success" v-show="scope.establish === 'Y'">開立</span>
                  <span class="badge badge-danger" v-show="scope.establish === 'N'">作廢</span>
                </template>
                <template #upload="{ scope }">
                  <span class="badge badge-warning text-white" v-show="scope.upload === ''">待開立</span>
                  <span class="badge badge-info" v-show="scope.upload === 'Y'">完成</span>
                  <span class="badge badge-danger" v-show="scope.upload === 'N'">錯誤</span>
                </template>
                <template #edit="{ scope }">
                  <button
                    class="btn btn-sm btn-danger abandon"
                    @click.prevent="test(scope.invoice_num)"
                    v-show="scope.establish === 'Y'"
                  >作廢</button>
                </template>
              </DataTable>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script>
export default {
  name: 'InvoiceList',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { getInvoiceTrackList, scrapInvoiceTrack } from '@/apis/invoice'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  parentPage: '發票',
  currentPage: '發票紀錄',
})

const isLoading = ref(false)


const columns = ref([
  {
    name: '訂單號碼',
    label: 'order_num'
  },
  {
    name: '發票號碼',
    label: 'invoice_num',
  },
  {
    name: '日期',
    label: 'invoice_date',
  },
  {
    name: '類型',
    label: 'buy_type',
  },
  {
    name: '買方資訊',
    label: 'buy_name',
  },
  {
    name: '總金額(未稅)',
    label: 'total_amount_untaxed',
  },
  {
    name: '總金額(含稅)',
    label: 'total_amount_tax_included',
  },
  {
    name: '開立狀態',
    label: 'establish',
    customLabel: 'establish',
    custom: true
  },
  {
    name: '上傳結果',
    label: 'upload',
    customLabel: 'upload',
    custom: true
  },
  {
    name: '回應時間',
    label: 'return_time',
  },
  {
    name: '操作',
    label: 'edit',
    customLabel: 'edit',
    custom: true
  }
])

const listData = ref({
  data: []
})

const searchForm = ref({
  start_day: null,
  end_day: null,
  invoice_str: null, // 發票號碼英文
  invoice_num_start: null, // 發票號碼字軌起始
  invoice_num_end: null, // 發票號碼字軌結束
  order_num: null, // 訂單號碼
  name: null, // 姓名
  uniform_numbers: null, // 統編
  establish: 'Y', // 發票開立(Y)/作廢(N)
})

const reset = () => {
  searchForm.value = {
    start_day: null,
    end_day: null,
    invoice_str: null, // 發票號碼英文
    invoice_num_start: null, // 發票號碼字軌起始
    invoice_num_end: null, // 發票號碼字軌結束
    order_num: null, // 訂單號碼
    name: null, // 姓名
    uniform_numbers: null, // 統編
    establish: 'Y', // 發票開立(Y)/作廢(N)
  }
  getList()
}

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  try {
    const searchData = {}
    Object.keys(searchForm.value).forEach((key) => {
      if (searchForm.value[key]) {
        searchData[key] = searchForm.value[key];
      }
    });
    const res = await getInvoiceTrackList({
      ...searchData,
      page,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

onMounted(() => {
  getList()
})

const test = (invoice) => {
  Swal.fire({
    title: '確定作廢？',
    input: 'text',
    inputLabel: '作廢原因（必填）',
    inputValidator: (value) => {
      if (!value) {
        return '原因為必填！'
      }
    },
    confirmButtonText: "確認",
    cancelButtonText: "取消",
    confirmButtonColor: '#fe5b52',
    cancelButtonColor: '#dddddd',
    showCancelButton: true,
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true
      try {
        const res = await scrapInvoiceTrack({
          invoice,
          invalid_reason: result.value,
          token: localStorage.getItem('token'),
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          isLoading.value = false
          Swal.fire({
            icon: 'success',
            title: res.data.data,
          }).then(() => {
            getList()
          })
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          localStorage.setItem('token', res.data.new_token)
          isLoading.value = false
          Swal.fire({
            icon: 'error',
            title: res.data.message,
          })
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
    }
  })
}

</script>

<style lang="scss" scoped>
.table-content {
  overflow: auto;
}
</style>
