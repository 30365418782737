import request from '@/utils/request';

// 前台選單相關功能
// 取得前台選單列表
export function getMenuList(param) {
  return request({
    url: `/menu/getlist`,
    method: 'post',
    data: param,
  });
}

// // 取得主選單資料，修改資料用
// export function getMenuData(param) {
//   return request({
//     url: `/menu/getdata`,
//     method: 'post',
//     data: param,
//   });
// }

// // 取得右上角選單按鈕相關設定
// export function getMenuConfig(param) {
//   return request({
//     url: `/menu/menuconfig`,
//     method: 'post',
//     data: param,
//   });
// }

// // 更新右上角選單按鈕
// export function updateMemberConfig(param) {
//   return request({
//     url: `/menu/updatemenuconfig`,
//     method: 'post',
//     data: param,
//   });
// }

// 新增前台選單
export function addMenu(param) {
  return request({
    url: `/menu/addmenu`,
    method: 'post',
    data: param,
  });
}

// 更新前台選單
export function updateMenu(param) {
  return request({
    url: `/menu/updatemenu`,
    method: 'post',
    data: param,
  });
}

// 更新選單啟用狀態
export function updateMenuEnable(param) {
  return request({
    url: `/menu/updatemenu`,
    method: 'post',
    data: param,
  });
}

// 刪除選單
export function delMenu(param) {
  return request({
    url: `/menu/delmenu`,
    method: 'post',
    data: param,
  });
}

// ------------- 底部選單管理 -------------
// ***********************************
// 見 webcontent.js


// ------------- 首頁管理 -------------
// ***********************************

// 取得首頁設定資料
export function getWebConfig(param) {
  return request({
    url: `/webcontent/indexconfig`,
    method: 'post',
    data: param,
  });
}

// 更新首頁管理
export function updateWebConfig(param) {
  return request({
    url: `/webcontent/setindexconfig`,
    method: 'post',
    data: param,
  });
}

// 取得 Banner(首頁輪播) 資料
export function getBannerList(param) {
  return request({
    url: `/webcontent/bannerlist`,
    method: 'post',
    data: param,
  });
}

// 新增 Banner(首頁輪播) 圖片
export function addBannerImg(param) {
  return request({
    url: `/webcontent/createbanner`,
    method: 'post',
    data: param,
  });
}

// 刪除 Banner(首頁輪播) 圖片
export function delBannerImg(param) {
  return request({
    url: `/webcontent/delbanner`,
    method: 'post',
    data: param,
  });
}

// 修改 Banner(首頁輪播) 排序
export function updateBannerSort(param) {
  return request({
    url: `/webcontent/updatebannerorder`,
    method: 'post',
    data: param,
  });
}

// 更新 Banner(首頁輪播) 圖片啟用狀態
export function updateBannerEnable(param) {
  return request({
    url: `/webcontent/bannerenable`,
    method: 'post',
    data: param,
  });
}

// 更新啟用狀態
export function updateWebConfigEnable(param) {
  return request({
    url: `/webcontent/indexconfigenable`,
    method: 'post',
    data: param,
  });
}

// ------------- 文章管理 -------------
// ***********************************
// 見 webcontent.js


// ------------- 聯絡我們 -------------
// ***********************************
// 取得列表資料
export function getContactUsList(param) {
  return request({
    url: `webcontent/getcontactuslist`,
    method: 'post',
    data: param,
  });
}

// 取得問題類型
export function getQuestionType(param) {
  return request({
    url: `/webcontent/getquestiontype`,
    method: 'post',
    data: param,
  });
}
// 取得問回覆紀錄
export function getReplyData(param) {
  return request({
    url: `/webcontent/getcontactusdetaillist`,
    method: 'post',
    data: param,
  });
}

// 回覆聯絡 (寄信)
export function sendReply(param) {
  return request({
    url: `/webcontent/createcontactusdetail`,
    method: 'post',
    data: param,
  });
}