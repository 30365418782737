import request from '@/utils/request';

// 後臺登入
export function adminLogin(param) {
  return request({
    url: `/login`,
    method: 'post',
    data: param,
  });
}
