<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12 col-md-5">
                    <div class="input-daterange input-group" id="date-range">
                      <input
                        type="date"
                        class="form-control day_start"
                        name="date_start"
                        placeholder="起日"
                        v-model="searchForm.start_day"
                      />
                      <span class="input-group-text rounded-0">～</span>
                      <input
                        type="date"
                        class="form-control day_end"
                        name="date_end"
                        placeholder="迄日"
                        v-model="searchForm.end_day"
                      />
                    </div>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-12 col-md-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email"
                      v-model="searchForm.mail"
                    />
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-4">
                    <button class="btn btn-primary" @click.prevent="getList()">搜尋</button>
                    <button class="btn btn-default" @click.prevent="reset">清空</button>
                  </div>
                </div>
              </form>
              <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                <template #content="{ scope }">
                  <a
                    class="btn-table"
                    data-toggle="modal"
                    data-target="#email-content"
                    @click="showContent(scope.content)"
                  >
                    <i class="ion-document-text"></i>
                  </a>
                </template>
                <template #enable="{ scope }">
                  <span class="badge badge-success" v-show="scope.enable === 'Y'">上傳成功</span>
                  <span class="badge badge-danger" v-show="scope.enable === 'N'">上傳錯誤</span>
                </template>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="modal fade" id="email-content" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">LINE發送內容</h5>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td class>
                    <div class="notify-template-content">{{ modelContent }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script>
export default {
  name: 'LineRecord',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { getNotifyList } from '@/apis/notify'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  parentPage: '通知',
  currentPage: 'LINE紀錄',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '發送時間',
    label: 'created_at',
  },
  {
    name: 'Email',
    label: 'mail',
  },
  {
    name: '內容',
    label: 'content',
    customLabel: 'content',
    custom: true
  },
  {
    name: '狀態',
    label: 'enable',
    customLabel: 'enable',
    custom: true,
    customHeader: `<a data-toggle="tooltip" data-placement="right" title="可能存在服務商無法接收之狀態" class="ml-1">
      <i class="ion-information-circled"></i>
    </a>`
  },
])

const listData = ref({
  data: []
})

onMounted(() => {
  getList()
})

const searchForm = ref({
  mail: null,
  start_day: null,
  end_day: null,
})

const reset = () => {
  searchForm.value = {
    mail: null,
    start_day: null,
    end_day: null,
  }
  getList()
}

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  const searchData = {}
  if (searchForm.value.mail) {
    searchData.mail = searchForm.value.mail
  }
  if (searchForm.value.start_day) {
    searchData.start_day = searchForm.value.start_day
  }
  if (searchForm.value.end_day) {
    searchData.end_day = searchForm.value.end_day
  }
  try {
    const res = await getNotifyList({
      ...searchData,
      type: 'L', // LINE
      page,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

const modelContent = ref('')
const showContent = (content) => {
  modelContent.value = content
}

</script>

<style lang="scss" scoped>
.notify-template-content {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #e9ecef;
  padding: 30px;
  font-family: sans-serif;
  font-size: 15px;
  line-height: 2;
  color: #555555;
}
</style>