<template>
  <div class="modal modal-bg">
    <div class="close-bg" @click="closeEdit"></div>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title" v-if="props.editData.id">編輯會員</h5>
          <h5 class="modal-title" v-else>新增會員</h5>
          <button
            type="button"
            class="close text-white"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeEdit"
          >
            <small aria-hidden="true">&times;</small>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-danger">
            新增會員後系統不會發送任何Email通知給予會員，
            <br />請管理員自行提供密碼供會員登入。
          </p>
          <form>
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>帳號
              </label>
              <div class="row">
                <div class="col-sm-6">
                  <input
                    type="email"
                    class="form-control"
                    v-model="memberForm.account"
                    placeholder="輸入Email"
                    :readonly="props.editData.id"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>姓名
              </label>
              <div class="row">
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="memberForm.name"
                    placeholder="輸入姓名"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>性別
              </label>
              <div>
                <div class="d-inline-flex align-items-center radio icheck-turquoise">
                  <input
                    type="radio"
                    name="edit_gender"
                    id="edit_male"
                    value="M"
                    v-model="memberForm.sex"
                  />
                  <label for="edit_male" class="mx-1 mb-0">先生</label>
                </div>
                <div class="d-inline-flex align-items-center radio icheck-turquoise">
                  <input
                    type="radio"
                    name="edit_gender"
                    id="edit_female"
                    value="F"
                    v-model="memberForm.sex"
                  />
                  <label for="edit_female" class="mx-1 mb-0">小姐</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>密碼
              </label>
              <div class="row">
                <div class="col-sm-6">
                  <input
                    type="password"
                    class="form-control"
                    v-model="memberForm.password"
                    placeholder="輸入密碼"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>確認密碼
              </label>
              <div class="row">
                <div class="col-sm-6">
                  <input
                    type="password"
                    class="form-control"
                    v-model="checkPassword"
                    placeholder="確認密碼"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>手機</label>
              <div class="row">
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="memberForm.cell_phone"
                    placeholder="輸入手機號碼"
                  />
                </div>
              </div>
            </div>
            <fieldset class="form-group">
              <label>生日</label>
              <div class="row">
                <div class="col-sm-4">
                  <select class="form-control" v-model="birthdayYear">
                    <option disabled="disabled">年</option>
                    <option v-for="(year, index) in yearOptionsReverse" :key="index">{{ year }}</option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select class="form-control" v-model="birthdayMonth">
                    <option disabled="disabled">月</option>
                    <option v-for="(month, index) in monthOptions" :key="index">{{ month }}</option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select class="form-control" v-model="birthdayDay">
                    <option disabled="disabled">日</option>
                    <option v-for="(day, index) in dayOptions" :key="index">{{ day }}</option>
                  </select>
                </div>
              </div>
            </fieldset>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-default mr-2"
                data-dismiss="modal"
                @click.prevent="closeEdit"
              >取消</button>
              <button type="submit" class="btn btn-primary" @click.prevent="send">儲存</button>
            </div>
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  name: 'MemberEdit',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted, computed, defineEmits, defineProps } from 'vue'
import { addMemberData, updateMemberData } from '@/apis/member'
import { wrongRequest, wrongToken } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const props = defineProps({
  editData: {
    type: Object
  }
})

const memberForm = ref({
  account: '',
  sex: 'M',
  password: '',
  cell_phone: '',
  name: '',
  birthday: ''
})

const checkPassword = ref('')

const birthdayYear = ref('年')
const birthdayMonth = ref('月')
const birthdayDay = ref('日')
const yearOptions = ref([])
const monthOptions = ref([])
const dayOptions = ref([])

const setBirthdayOptions = () => {
  const today = new Date()
  let nowYear = today.getFullYear()
  for (let i = 1920; i <= nowYear; i++) {
    yearOptions.value.push(i)
  }
  for (let i = 1; i <= 12; i++) {
    if (i < 10) {
      monthOptions.value.push(`0${i}`)
    } else {
      monthOptions.value.push(i)
    }
  }
  for (let i = 1; i <= 31; i++) {
    if (i < 10) {
      dayOptions.value.push(`0${i}`)
    } else {
      dayOptions.value.push(i)
    }
  }
}

const setEditForm = () => {
  memberForm.value = {
    account: props.editData.account,
    sex: props.editData.sex,
    password: '',
    cell_phone: props.editData.cell_phone,
    name: props.editData.name,
  }
  if (props.editData.birthday) {
    const birthAry = props.editData.birthday.split('-')
    birthdayYear.value = birthAry[0]
    birthdayMonth.value = birthAry[1]
    birthdayDay.value = birthAry[2]
  }
}

onMounted(() => {
  setBirthdayOptions()
  if (props.editData.id) {
    setEditForm()
  }
})

const yearOptionsReverse = computed(() => yearOptions.value.slice().reverse())

const emit = defineEmits(['closeEdit'])
const closeEdit = () => {
  memberForm.value = {
    account: '',
    password: '',
    cell_phone: '',
    name: '',
    birthday: ''
  }
  birthdayYear.value = ''
  birthdayMonth.value = ''
  birthdayDay.value = ''
  emit('closeEdit', false)
}

const send = async () => {
  if (props.editData.id && memberForm.value.account !== props.editData.account) {
    Swal.fire({
      icon: 'error',
      title: '帳號不可更動，你這個小壞壞',
    })
    return
  }
  if (props.editData.id && memberForm.value.password === '') { // 若編輯時沒有異動密碼，則送出時不送 password 欄位
    delete memberForm.value.password
  }
  if (memberForm.value.password && memberForm.value.password !== checkPassword.value) {
    Swal.fire({
      icon: 'error',
      title: '兩次密碼輸入不同',
    })
    return
  }
  if (birthdayYear.value !== '年' && birthdayMonth.value !== '月' && birthdayDay.value !== '日') {
    memberForm.value.birthday = `${birthdayYear.value}-${birthdayMonth.value}-${birthdayDay.value}`
  } else {
    delete memberForm.value.birthday
  }
  try {
    const res = (props.editData.id)
      ? await updateMemberData({
        ...memberForm.value,
        id: props.editData.id,
        token: localStorage.getItem('token')
      })
      : await addMemberData({
        ...memberForm.value,
        token: localStorage.getItem('token')
      })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      Swal.fire({
        icon: 'success',
        title: res.data.data,
      }).then(() => {
        emit('closeEdit', false)
      })
    } else if (!res.data.new_token) {
      wrongToken(res.data.message)
    } else {
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

</script>

<style lang="scss" scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: block;
}
.close-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: block;
  background-color: #0000007d;
}
</style>