import request from '@/utils/request';

// 取得商品列表
export function getProductList(param) {
  return request({
    url: `/commodity`,
    method: 'post',
    data: param,
  });
}

// 修改商品狀態
export function changeProductEnable(param) {
  return request({
    url: `/commodity/editStatus`,
    method: 'post',
    data: param,
  });
}

// 取得新增商品頁面所需資料
export function getProductConfig(param) {
  return request({
    url: `/commodity/createview`,
    method: 'post',
    data: param,
  }); 
}
// 取得新增商品頁面所需資料
export function addProduct(param) {
  return request({
    url: `/commodity/create`,
    method: 'post',
    data: param,
  }); 
}

// 取得修改商品資料
export function getProductData(param) {
  return request({
    url: `/commodity/getEditData`,
    method: 'post',
    data: param,
  });
}

// 更新商品資料
export function updateProduct(param) {
  return request({
    url: `/commodity/edit`,
    method: 'post',
    data: param,
  });
}

// 取得商品庫存資料
export function getStockRecord(param) {
  return request({
    url: `/stockRecord/getStockList`,
    method: 'post',
    data: param,
  });
}

// 複製新增商品
export function copyProduct(param) {
  return request({
    url: `/commodity/commoditycopy`,
    method: 'post',
    data: param,
  });
}

// 刪除商品圖片(修改頁面用)
export function deleteProductImg(param) {
  return request({
    url: `/commodity/delcommodityimg`,
    method: 'post',
    data: param,
  });
}

// 上傳商品圖片(修改頁面用)
export function addProductImg(param) {
  return request({
    url: `/commodity/uploadcommodityimg`,
    method: 'post',
    data: param,
  });
}

// 修改商品規格狀態
export function changeSpecificationEnable(param) {
  return request({
    url: `/commodity/uploadspenable`,
    method: 'post',
    data: param,
  });
}

// 刪除產品
export function deleteProduct(param) {
  return request({
    url: `/commodity/delcommodity`,
    method: 'post',
    data: param,
  });
}

// 刪除產品規格
export function deleteProductSpec(param) {
  return request({
    url: `/commodity/delcommodityspec`,
    method: 'post',
    data: param,
  });
}