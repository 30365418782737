import request from '@/utils/request';

// 取得銷售總表
export function getSaleReport(param) {
  return request({
    url: `/report/getSale`,
    method: 'post',
    data: param,
  });
}

// 取得金流報表
export function getPaymentFlowReport(param) {
  return request({
    url: `/report/paymentFlow`,
    method: 'post',
    data: param,
  });
}

// 取得商品報表
export function getProductReport(param) {
  return request({
    url: `/report/product`,
    method: 'post',
    data: param,
  });
}

// 匯出Excel
export function getExcel(key) {
  return request({
    url: `/exclsPost?key=${key}`,
    method: 'get',
  });
}