<template>
  <div class="modal modal-bg">
    <div class="close-bg" @click="closeImgSlide"></div>
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">編輯圖片輪播</h5>
          <button
            type="button"
            class="close text-white"
            data-dismiss="modal"
            @click="closeImgSlide"
          >
            <small aria-hidden="true">&times;</small>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>名稱</label>
              <div>圖片輪播</div>
            </div>
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>單一圖片
              </label>
              <div class="text-danger">建議上傳尺寸：1920x750 像素 / 檔案限制 2MB 內</div>
            </div>
            <div class="item-container">
              <div class="modal-item" v-for="(img, index) in imgList" :key="img.id">
                <div class="form-group" style="margin-bottom: 0;">
                  <input
                    type="file"
                    accept="image/jpg, image/png, image/jpeg"
                    class="filepond uploadItemImage"
                    :id="`item-${img.id}`"
                    v-show="!img.banner_img_url"
                    @change="uploadImg($event)"
                  />
                  <img class="img-content mt-2" :src="img.banner_img_url" />
                  <div class="form-group mt-2" style="display: flex;">
                    <label class="mt-2 mr-2" style="width:31px;">連結</label>
                    <input class="form-control mr-2" v-model="img.url" />
                  </div>
                  <div
                    class="form-group mt-2"
                    style="display: flex; justify-content: space-between; align-items: center;"
                  >
                    <div style="display: flex;">
                      <label class="mt-2 mr-2">排序</label>
                      <input
                        class="form-control sort_num mr-2"
                        style="width: 120px;"
                        v-model="img.order"
                      />
                      <button
                        class="btn btn-info"
                        @click.prevent="updateBanner(img.id, img.url, img.order)"
                        v-show="img.id"
                      >更新</button>
                    </div>
                    <div style="display: flex; align-items: center;">
                      <label class="mt-2 mr-2">啟用</label>
                      <Toggle
                        v-model="img.enable"
                        falseValue="N"
                        trueValue="Y"
                        @change="changeEnable(img.id, img.enable)"
                      />
                    </div>
                  </div>
                </div>
                <a
                  class="btn-table delete"
                  @click.prevent="delItem(img, index)"
                  style="cursor: pointer;"
                >
                  <i class="ion-ios-trash"></i>
                </a>
              </div>
              <div class="form-group text-center">
                <button
                  @click.prevent="closeImgSlide"
                  class="btn btn-danger mr-1"
                  data-dismiss="modal"
                >關閉</button>
                <button @click.prevent="addItem" class="btn btn-primary add-item">新增</button>
              </div>
            </div>
            <!-- <div class="text-center">
              <button
                @click.prevent="closeImgSlide"
                class="btn btn-default mr-1"
                data-dismiss="modal"
              >取消</button>
              <button @click.prevent="send" class="btn btn-primary">儲存</button>
            </div>-->
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script>
export default {
  name: 'ImgSlider',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted, defineEmits } from 'vue'
import Loading from '@/components/Loading.vue'
import { getBannerList, addBannerImg, delBannerImg, updateBannerSort, updateBannerEnable } from '@/apis/content'
import { wrongRequest, wrongToken } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'

const isLoading = ref(false)

const imgList = ref([])
const getBannerData = async () => {
  isLoading.value = true
  try {
    const res = await getBannerList({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      imgList.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getBannerData()
})

const addItem = async () => {
  imgList.value.push({
    banner_img_url: '',
    banner_img_id: '',
    order: '0',
    enable: 'Y',
  })
}

const uploadImg = async (e) => {
  isLoading.value = true
  const formData = new FormData()
  formData.append('files', e.target.files[0])
  formData.append('enable', 'Y')
  formData.append('order', '0')
  formData.append('token', localStorage.getItem('token'))
  try {
    const res = await addBannerImg(formData)
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      getBannerData()
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const delItem = async (data, index) => {
  if (data.id) {
    Swal.fire({
      icon: "error",
      title: "確定刪除此張圖片？",
      text: "刪除後將不可還原",
      confirmButtonText: "確認",
      cancelButtonText: "取消",
      confirmButtonColor: '#fe5b52',
      cancelButtonColor: '#dddddd',
      showCancelButton: true,
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        isLoading.value = true
        try {
          const res = await delBannerImg({
            id: data.id,
            token: localStorage.getItem('token'),
          })
          if (res.data.code === 0) {
            localStorage.setItem('token', res.data.new_token)
            getBannerData()
          } else if (!res.data.new_token) {
            isLoading.value = false
            wrongToken(res.data.message)
          } else {
            isLoading.value = false
            wrongRequest(res.data)
          }
        }
        catch (error) {
          console.info(error)
          isLoading.value = false
          Swal.fire({
            icon: 'error',
            title: error,
          })
        }
      }
    })
  } else {
    imgList.value.splice(index, 1)
  }
}

const updateBanner = async (id, url, order) => {
  isLoading.value = true
  try {
    const res = await updateBannerSort({
      id,
      url,
      order,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: '資料更新成功',
      }).then(() => {
        getBannerData()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const changeEnable = async (id, enable) => {
  isLoading.value = true
  try {
    const res = await updateBannerEnable({
      id,
      enable,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      getBannerData()
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const emit = defineEmits(['closeImgSlide'])
const closeImgSlide = () => {
  emit('closeImgSlide', false)
}

</script>

<style lang="scss" scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  outline: 0;
  display: block;
}
.close-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: block;
  background-color: #0000007d;
}
input {
  width: 100%;
}
.img-content {
  max-width: 500px;
  max-height: 250px;
  // width: 500px;
  // height: 250px;
  background-color: #f1f0ef;
}
.modal-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>