<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form class="mb-3">
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <label>訂單成立時間</label>
                      <div class="input-daterange input-group" id="date-range">
                        <input
                          type="date"
                          class="form-control"
                          placeholder="起日"
                          v-model="searchForm.start_day"
                        />
                        <span class="input-group-text rounded-0">～</span>
                        <input
                          type="date"
                          class="form-control"
                          placeholder="迄日"
                          v-model="searchForm.end_day"
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-3 mb-2 mb-md-0">
                      <label class="d-block">退貨單號</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="退貨單號"
                        v-model="searchForm.return_num"
                      />
                    </div>
                    <div class="form-group col-12 col-sm-6 col-md-3 mb-2 mb-md-0">
                      <label class="d-block">類型</label>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="type"
                          id="type-nll"
                          v-model="searchForm.type"
                          value="null"
                        />
                        <label for="type-nll" class="mx-1 mb-0" value="null">全選</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="type"
                          id="type-C"
                          v-model="searchForm.type"
                          value="C"
                        />
                        <label for="type-C" class="mx-1 mb-0" value="C">換貨</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="type"
                          id="type-B"
                          v-model="searchForm.type"
                          value="B"
                        />
                        <label for="type-B" class="mx-1 mb-0" value="B">退貨</label>
                      </div>
                    </div>
                    <div class="form-group col-12 col-sm-6 col-md-3 mb-2 mb-md-0">
                      <label class="d-block">審核狀態</label>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="enable"
                          id="enable-null"
                          v-model="searchForm.enable"
                          value="null"
                        />
                        <label for="enable-null" class="mx-1 mb-0" value="null">全選</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="enable"
                          id="enable-Y"
                          v-model="searchForm.enable"
                          value="Y"
                        />
                        <label for="enable-Y" class="mx-1 mb-0" value="Y">完成</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="enable"
                          id="enable-C"
                          v-model="searchForm.enable"
                          value="C"
                        />
                        <label for="enable-C" class="mx-1 mb-0" value="C">取消</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="enable"
                          id="enable-P"
                          v-model="searchForm.enable"
                          value="P"
                        />
                        <label for="enable-P" class="mx-1 mb-0" value="P">待審核</label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div class="col-sm-5 p-0">
                  <button class="btn btn-primary mr-1" @click.prevent="getList()">搜尋</button>
                  <button class="btn btn-default" @click.prevent="reset">清空</button>
                </div>
              </form>

              <button
                class="btn btn-primary mr-1"
                @click="goHref('/admin/Order/returnList/add')"
              >新增退換貨</button>
            </div>

            <div class="p-3">
              <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                <template #return_order_num="{ scope }">
                  <span class="badge badge-primary" v-show="scope.type === 'C'">換貨</span>
                  <span class="badge badge-warning" v-show="scope.type === 'B'">退貨</span>
                  {{ scope.return_order_num }}
                </template>
                <template #product="{ scope }">
                  <div v-for="item in scope.order_return_detail" :key="item.id">
                    {{ formatProductName(item.order_return_product_details[0].commodity_data)}} -
                    <span
                      class="text-blue"
                    >{{ formatSpecificationName(item.order_return_product_details[0].commodity_spec_data) }}</span>
                  </div>
                </template>
                <template #return_amount="{ scope }">NT${{ checkMoney(scope.return_amount) }}</template>
                <template #return_point="{ scope }">{{ checkMoney(scope.return_point) }}</template>
                <template #return_bonus="{ scope }">{{ checkMoney(scope.return_bonus) }}</template>
                <template #enable="{ scope }">
                  <span class="badge badge-success cursor-disabled" v-show="scope.enable === 'Y'">完成</span>
                  <span class="badge badge-danger cursor-disabled" v-show="scope.enable === 'C'">取消</span>
                  <span
                    class="badge badge-primary cursor-pointer"
                    v-show="scope.enable === 'P'"
                    @click="review(scope.id)"
                  >待審核</span>
                </template>
              </DataTable>
            </div>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script>
export default {
  name: 'OrderReturnList',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { getReturnOrderList, returnReview } from '@/apis/order'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import { checkMoney } from '@/utils/checkMoney'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  parentPage: '訂單',
  currentPage: '退換貨管理',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '申請時間',
    label: 'created_at',
  },
  {
    name: '退/換貨單號',
    label: 'return_order_num',
    customLabel: 'return_order_num',
    custom: true
  },
  {
    name: '產品',
    label: 'product',
    customLabel: 'product',
    custom: true
  },
  {
    name: '退回金額',
    label: 'return_amount',
    customLabel: 'return_amount',
    custom: true
  },
  {
    name: '點數返還',
    label: 'return_point',
    customLabel: 'return_point',
    custom: true
  },
  {
    name: '紅利返還',
    label: 'return_bonus',
    customLabel: 'return_bonus',
    custom: true
  },
  {
    name: '審核狀態',
    label: 'enable',
    customLabel: 'enable',
    custom: true
  },
  // {
  //   name: '審核',
  //   label: 'edit',
  //   customLabel: 'edit',
  //   custom: true
  // },
])

const goHref = (href) => {
  document.location.href = href
}

const searchForm = ref({
  start_day: '', // 訂單成立開始時間，格式 ex: '2023-01-01'
  end_day: '', // 訂單成立結束時間，格式 ex: '2023-01-20'
  return_num: null, // 會員名稱 | 電話 | 信箱
  type: null, // B退貨 / C換貨
  enable: null, // 審核狀態，Y完成 / C取消 / P 待審核
})

const reset = () => {
  searchForm.value = {
    start_day: '',
    end_day: '',
    return_num: null,
    type: null,
    enable: null
  }
  getList()
}

const listData = ref({
  data: []
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  const searchData = {}
  Object.entries(searchForm.value).forEach(([key, value]) => {
    if (value !== '' && value !== null) {
      searchData[key] = value
    }
  })
  try {
    const res = await getReturnOrderList({
      ...searchData,
      page,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

onMounted(() => {
  getList()
})

const formatProductName = (item) => {
  return item ? item.name : '資料錯誤'
}

const formatSpecificationName = (item) => {
  return item ? item.specification_name : '資料錯誤'
}

const review = (return_id) => {
  console.log(return_id)
  Swal.fire({
    title: '變更審核狀態',
    confirmButtonText: '送出',
    cancelButtonText: '取消',
    input: 'select',
    inputOptions: {
      'Y': '成功',
      'C': '取消'
    },
    inputPlaceholder: '選擇狀態',
    showCancelButton: true,
    inputValidator: async (value) => {
      try {
        const res = await returnReview({
          return_id,
          enable: value,
          token: localStorage.getItem('token'),
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          isLoading.value = false
          getList()
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          isLoading.value = false
          wrongRequest(res.data)
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
      console.log(value)
    }
  })
}

</script>

<style lang="scss" scoped>
table {
  text-align: center;
}
a,
.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
</style>