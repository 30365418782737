<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0" v-if="breadCrumbs.childPage">{{ breadCrumbs.childPage }}</h1>
          <h1 class="m-0" v-else>{{ breadCrumbs.currentPage }}</h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item">
              <a href="/admin/Dashboard">首頁</a>
            </li>
            <li class="breadcrumb-item" v-if="breadCrumbs.parentPage">{{ breadCrumbs.parentPage }}</li>
            <li class="breadcrumb-item active" v-if="breadCrumbs.currentPath">
              <a :href="breadCrumbs.currentPath">{{ breadCrumbs.currentPage }}</a>
            </li>
            <li
              class="breadcrumb-item active"
              v-if="breadCrumbs.currentPage && !breadCrumbs.currentPath"
            >{{ breadCrumbs.currentPage }}</li>
            <li
              class="breadcrumb-item active"
              v-if="breadCrumbs.childPage"
            >{{ breadCrumbs.childPage }}</li>
          </ol>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    breadCrumbs: {
      default: '',
    }
  },
}
</script>

<style lang="scss" scoped>
</style>