<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="活動名稱"
                      v-model="searchForm.name"
                    />
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-5">
                    <div class="input-daterange input-group" id="date-range">
                      <input
                        type="date"
                        v-model="searchForm.start_at"
                        class="form-control date col-md-5"
                        placeholder="起日"
                      />
                      <!-- <form-control picker
                        v-model="searchForm.start_at"
                        class="date day_start"
                        placeholder="起日"
                        :lang="'zh-Hant-TW'"
                      />-->
                      <span class="input-group-text rounded-0">～</span>
                      <input
                        type="date"
                        v-model="searchForm.end_at"
                        class="form-control date col-md-5"
                        placeholder="迄日"
                      />
                      <!-- <Datepicker
                        v-model="searchForm.end_at"
                        class="date day_end"
                        placeholder="迄日"
                        :lang="'zh-Hant-TW'"
                      />-->
                    </div>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-12 col-md-5">
                    <button class="btn btn-primary" @click.prevent="getList()">搜尋</button>
                    <button class="btn btn-default" @click.prevent="reset">清空</button>
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col-12 mb-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick="location.href='/admin/Discount/PromoCode/add'"
                  >新增折扣碼</button>
                </div>
              </div>
              <div class="table-responsive">
                <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                  <template #specify="{ scope }">{{ formatReceiveType(scope.specify) }}</template>
                  <template #type="{ scope }">{{ formatType(scope) }}</template>
                  <template #limit_amount="{ scope }">${{ Number(scope.limit_amount) }}</template>
                  <template #start_at="{ scope }">{{ formatTimestamp(scope.start_at) }}</template>
                  <template #end_at="{ scope }">{{ formatTimestamp(scope.end_at) }}</template>
                  <template #used_count="{ scope }">{{ scope.used_count}}/ {{ scope.limit_count }}</template>
                  <template #order_count="{ scope }">{{ scope.order_count}}</template>
                  <template #status="{ scope }">
                    <Toggle
                      v-model="scope.status"
                      falseValue="N"
                      trueValue="Y"
                      @change="changeEnable(scope.id, scope.status)"
                    />
                  </template>
                  <template #edit="{ scope }">
                    <a
                      href="javascript:;"
                      class="btn-table edit ml-auto ml-0"
                      @click="editItem(scope.id)"
                    >
                      <i class="ion-edit"></i>
                    </a>
                  </template>
                  <template #delete="{ scope }">
                    <a
                      class="btn-table delete"
                      @click.prevent="deleteItem(scope.id, scope.promo_code)"
                    >
                      <i class="ion-ios-trash"></i>
                    </a>
                  </template>
                </DataTable>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'PromoCodeList',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { getPromoCodeList, changePromoCodeEnable, deletePromoCode } from '@/apis/promoCode'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'

const breadCrumbs = ref({
  parentPage: '優惠',
  currentPage: '折扣碼管理',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '活動名稱',
    label: 'name'
  },
  {
    name: '領取方式',
    label: 'specify',
    customLabel: 'specify',
    custom: true
  },
  {
    name: '折扣碼',
    label: 'promo_code',
  },
  {
    name: '促銷種類',
    label: 'type',
    customLabel: 'type',
    custom: true
  },
  {
    name: '最低訂單金額',
    label: 'limit_amount',
    customLabel: 'limit_amount',
    custom: true
  },
  {
    name: '開始日期',
    label: 'start_at',
    customLabel: 'start_at',
    custom: true
  },
  {
    name: '結束日期',
    label: 'end_at',
    customLabel: 'end_at',
    custom: true
  },
  {
    name: '發放紀錄',
    label: 'used_count',
    customLabel: 'used_count',
    custom: true
  },
  {
    name: '關聯訂單數',
    label: 'order_count',
    customLabel: 'order_count',
    custom: true
  },
  {
    name: '狀態',
    label: 'status',
    customLabel: 'status',
    custom: true
  },
  {
    name: '編輯',
    label: 'edit',
    customLabel: 'edit',
    custom: true
  },
  {
    name: '刪除',
    label: 'delete',
    customLabel: 'delete',
    custom: true
  }
])

const formatReceiveType = (receiveType) => {
  return receiveType === 'Y' ? '限定會員使用' : '公開領取'
}

const formatType = (scope) => {
  return scope.type === 'discount' ? `$${Number(scope.discount)}` : scope.commodities_id
}

const formatTimestamp = (unix) => {
  if (!unix) return '無'
  const date = new Date(unix * 1000)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const dateString = `${year}-${month}-${day}`
  return dateString
}

const searchForm = ref({
  name: null,
  start_at: null,
  end_at: null,
})

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-')
  const date = new Date(year, month - 1, day)
  const unixTimestamp = date.getTime()
  const unixTimestampSeconds = Math.floor(unixTimestamp / 1000)
  return unixTimestampSeconds
}

const reset = () => {
  searchForm.value = {
    name: null,
    start_at: null,
    end_at: null,
  }
}

const listData = ref({
  data: []
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  const searchData = {}
  if (searchForm.value.start_at) {
    searchData.start_at = formatDate(searchForm.value.start_at)
  }
  if (searchForm.value.end_at) {
    searchData.end_at = formatDate(searchForm.value.end_at)
  }
  if (searchForm.value.name) {
    searchData.name = searchForm.value.name
  }
  isLoading.value = true
  try {
    const res = await getPromoCodeList({
      ...searchData,
      page,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

onMounted(() => {
  getList()
})

const changeEnable = async (id, status) => {
  isLoading.value = true
  console.log(id, status)
  try {
    const res = await changePromoCodeEnable({
      promo_code_id: id,
      status,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      getList(pageNum.value)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const editItem = (id) => {
  localStorage.setItem('promoCodeId', id)
  location.href = `/admin/Discount/PromoCode/edit`
}

const deleteItem = async (id, promo_code) => {
  Swal.fire({
    icon: "error",
    title: `確定刪除「${promo_code}」？`,
    text: "刪除後將不可還原",
    confirmButtonText: "確認",
    cancelButtonText: "取消",
    confirmButtonColor: '#fe5b52',
    cancelButtonColor: '#dddddd',
    showCancelButton: true,
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true
      try {
        const res = await deletePromoCode({
          id,
          token: localStorage.getItem('token')
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          getList()
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          isLoading.value = false
          wrongRequest(res.data)
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
    }
  })
}

</script>

<style lang="scss" scoped>
.date:deep {
  height: 38px !important;
  width: 100%;
  input {
    width: 100%;
    height: 38px !important;
    padding-left: 20px;
  }
  svg {
    display: none;
  }
}
.day_start:deep {
  width: calc(50% - 20px);
  input {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.day_end:deep {
  width: calc(50% - 20px);
  input {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>