import {
  createRouter,
  createWebHistory,
} from 'vue-router'
import { useAdminStore } from '@/store/admin'

const routes = [
  {
    path: '/admin/Login',
    name: 'Login',
    component: () => import('@/views/Login/login.vue'),
    meta: {
      title: '後台登入',
      hidden: true,
      hiddenTab: true
    }
  },
  {
    path: '/admin/Order/shipping', // 訂單進行出貨
    name: 'OrderShipping',
    component: () => import('@/views/Order/shipping.vue'),
    meta: {
      title: '出貨',
      hidden: true,
      hiddenTab: true
    }
  },
  // {
  //   path: '/admin/Order/returning', // 訂單進行退貨
  //   name: 'OrderReturning',
  //   component: () => import('@/views/Order/returning.vue'),
  //   meta: {
  //     title: '退貨',
  //     hidden: true,
  //     hiddenTab: true
  //   }
  // },
  // {
  //   path: '/admin/Order/changing', // 訂單進行換貨
  //   name: 'OrderChanging',
  //   component: () => import('@/views/Order/changing.vue'),
  //   meta: {
  //     title: '換貨',
  //     hidden: true,
  //     hiddenTab: true
  //   }
  // },
  {
    path: '/admin/Order/print', // 訂單詳情列印
    name: 'OrderPrint',
    component: () => import('@/views/Order/print.vue'),
    meta: {
      title: '列印',
      hidden: true,
      hiddenTab: true
    }
  },
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/Layout.vue'),
    children: [
      {
        path: 'admin/Dashboard', // 首頁
        name: 'Home',
        component: () => import('@/views/Dashboard/index.vue'),
        meta: {
          title: '後台',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/User/loginRecord', // 登入紀錄
        name: 'LoginRecord',
        component: () => import('@/views/User/loginRecord.vue'),
        meta: {
          title: '登入紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/User/operationRecord', // 操作紀錄
        name: 'OperationRecord',
        component: () => import('@/views/User/operationRecord.vue'),
        meta: {
          title: '操作紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/User/list', // 後台帳號列表
        name: 'UserList',
        component: () => import('@/views/User/list.vue'),
        meta: {
          title: '後台帳號列表',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/User/add', // 新增、編輯後台帳號
        alias: ['admin/User/edit'],
        name: 'UserEdit',
        component: () => import('@/views/User/edit.vue'),
        meta: {
          title: '後台帳號',
          hidden: true,
          hiddenTab: true
        },
      },
      //
      // -------- 網站設定 -------- 
      //
      {
        path: 'admin/Content/type/bottom', // 底部選單管理
        name: 'TypeBottom',
        component: () => import('@/views/Content/type/typeBottom.vue'),
        meta: {
          title: '底部選單管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Content/index/list', // 首頁管理
        name: 'IndexList',
        component: () => import('@/views/Content/index/list.vue'),
        meta: {
          title: '首頁管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Content/article/list', // 文章管理
        name: 'ArticleList',
        component: () => import('@/views/Content/article/list.vue'),
        meta: {
          title: '文章管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Content/article/add', // 新增、編輯文章
        alias: ['admin/Content/article/edit'],
        name: 'ArticleEdit',
        component: () => import('@/views/Content/article/edit.vue'),
        meta: {
          title: '文章管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Content/contact/list', // 聯絡我們
        name: 'ContactList',
        component: () => import('@/views/Content/contact/list.vue'),
        meta: {
          title: '聯絡我們',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Content/contact//detail', // 詳細回覆紀錄
        name: 'ContactDetail',
        component: () => import('@/views/Content/contact/detail.vue'),
        meta: {
          title: '回覆紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      //
      // -------- 會員 --------
      //
      {
        path: 'admin/Member/setting', // 會員登入設定
        name: 'MemberSetting',
        component: () => import('@/views/Member/setting.vue'),
        meta: {
          title: '會員登入設定',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Member/list', // 會員管理
        name: 'MemberList',
        component: () => import('@/views/Member/list.vue'),
        meta: {
          title: '會員管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Member/detail', // 會員詳細
        name: 'MemberDetail',
        component: () => import('@/views/Member/detail.vue'),
        meta: {
          title: '會員詳細資料',
          hidden: true,
          hiddenTab: true
        },
      },
      //
      // -------- 產品 --------
      //
      {
        path: 'admin/Product/list', // 產品管理
        name: 'ProductList',
        component: () => import('@/views/Product/list.vue'),
        meta: {
          title: '產品管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Product/add', // 新增、編輯產品
        alias: ['admin/Product/edit'],
        name: 'ProductEdit',
        component: () => import('@/views/Product/edit.vue'),
        meta: {
          title: '產品管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Product/stockRecord', // 產品庫存紀錄
        name: 'ProductStockRecord',
        component: () => import('@/views/Product/stockRecord.vue'),
        meta: {
          title: '產品庫存紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      //
      // -------- 訂單 --------
      //
      {
        path: 'admin/Order/paymentSetting', // 付款相關設定
        name: 'OrderP aymentSetting',
        component: () => import('@/views/Order/paymentSetting.vue'),
        meta: {
          title: '付款相關設定',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Order/shipmentSetting', // 運費及免運設定
        name: 'OrderShipmentSetting',
        component: () => import('@/views/Order/shipmentSetting.vue'),
        meta: {
          title: '運費及免運設定',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Order/list', // 訂單管理
        name: 'OrderList',
        component: () => import('@/views/Order/list.vue'),
        meta: {
          title: '訂單管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Order/detail', // 訂單詳情
        name: 'OrderDetail',
        component: () => import('@/views/Order/detail.vue'),
        meta: {
          title: '訂單詳情',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Order/add', // 新增訂單
        name: 'OrderAdd',
        component: () => import('@/views/Order/add.vue'),
        meta: {
          title: '訂單管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Order/returnList', // 訂單退貨/換貨
        name: 'OrderReturnList',
        component: () => import('@/views/Order/Return/list.vue'),
        meta: {
          title: '退換貨管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Order/returnList/add', // 新增訂單退貨/換貨
        name: 'OrderReturnAdd',
        component: () => import('@/views/Order/Return/add.vue'),
        meta: {
          title: '退換貨管理',
          hidden: true,
          hiddenTab: true
        },
      },
      //
      // -------- 發票 --------
      //
      {
        path: 'admin/Invoice/setting', // 發票設定
        name: 'InvoiceSetting',
        component: () => import('@/views/Invoice/setting.vue'),
        meta: {
          title: '發票設定',
          hidden: true,
          hiddenTab: true
        },
      },  
      {
        path: 'admin/Invoice/setting/add', // 新增發票字軌
        name: 'InvoiceSettingAdd',
        component: () => import('@/views/Invoice/add.vue'),
        meta: {
          title: '新增發票字軌',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Invoice/list', // 發票紀錄
        name: 'InvoiceList',
        component: () => import('@/views/Invoice/list.vue'),
        meta: {
          title: '發票紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      //
      // -------- 優惠 --------
      //
      {
        path: 'admin/Discount/Mission/list', // 簽到任務管理
        name: 'MissionList',
        component: () => import('@/views/Discount/Mission/list.vue'),
        meta: {
          title: '簽到任務管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Discount/Mission/add', // 新增、編輯簽到任務
        alias: ['admin/Discount/Mission/edit'],
        name: 'MissionEdit',
        component: () => import('@/views/Discount/Mission/edit.vue'),
        meta: {
          title: '簽到任務管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Discount/Mission/checkRecord', // 簽到紀錄
        name: 'MissionCheckRecord',
        component: () => import('@/views/Discount/Mission/checkRecord.vue'),
        meta: {
          title: '簽到紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Discount/Mission/sentRecord', // 發放紀錄
        name: 'MissionSentRecord',
        component: () => import('@/views/Discount/Mission/sentRecord.vue'),
        meta: {
          title: '發放紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Discount/Price/setting', // 滿額折扣設定
        name: 'PriceSetting',
        component: () => import('@/views/Discount/Price/setting.vue'),
        meta: {
          title: '滿額折扣設定',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Discount/Price/list', // 滿額活動管理
        name: 'PriceList',
        component: () => import('@/views/Discount/Price/list.vue'),
        meta: {
          title: '滿額活動管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Discount/Price/add', // 新增、編輯滿額活動管理
        alias: ['admin/Discount/Price/edit'],
        name: 'PriceEdit',
        component: () => import('@/views/Discount/Price/edit.vue'),
        meta: {
          title: '滿額活動管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Discount/PromoCode/setting', // 系統優惠設定
        name: 'PromoCodeSetting',
        component: () => import('@/views/Discount/PromoCode/setting.vue'),
        meta: {
          title: '系統優惠設定',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Discount/PromoCode/list', // 折扣碼管理
        name: 'PromoCodeList',
        component: () => import('@/views/Discount/PromoCode/list.vue'),
        meta: {
          title: '折扣碼管理',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Discount/PromoCode/add', // 新增、編輯折扣碼
        alias: ['admin/Discount/PromoCode/edit'],
        name: 'PromoCodeEdit',
        component: () => import('@/views/Discount/PromoCode/edit.vue'),
        meta: {
          title: '折扣碼管理',
          hidden: true,
          hiddenTab: true
        },
      },
      //
      // -------- 點數 --------
      //
      {
        path: 'admin/Point/setting', // 紅利設定
        name: 'PointSetting',
        component: () => import('@/views/Point/setting.vue'),
        meta: {
          title: '紅利設定',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Point/bonusSend', // 紅利發放
        name: 'BonusSend',
        component: () => import('@/views/Point/Bonus/send.vue'),
        meta: {
          title: '紅利發放',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Point/pointSend', // 點數發放
        name: 'PointSend',
        component: () => import('@/views/Point/Point/send.vue'),
        meta: {
          title: '點數發放',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Point/bonusRecord', // 紅利紀錄
        name: 'BonusRecord',
        component: () => import('@/views/Point/Bonus/record.vue'),
        meta: {
          title: '紅利紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Point/pointRecord', // 點數紀錄
        name: 'PointRecord',
        component: () => import('@/views/Point/Point/record.vue'),
        meta: {
          title: '點數紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      //
      // -------- 報表 --------
      //
      {
        path: 'admin/Report/sale', // 銷售總表
        name: 'ReportSale',
        component: () => import('@/views/Report/sale.vue'),
        meta: {
          title: '銷售總表',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Report/paymentFlow', // 金流報表
        name: 'ReportPaymentFlow',
        component: () => import('@/views/Report/paymentFlow.vue'),
        meta: {
          title: '金流報表',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Report/product', // 產品報表
        name: 'ReportProduct',
        component: () => import('@/views/Report/product.vue'),
        meta: {
          title: '產品報表',
          hidden: true,
          hiddenTab: true
        },
      },
      //
      // -------- 通知 --------
      //
      {
        path: 'admin/Notify/setting', // 啟用通知
        name: 'NotifySetting',
        component: () => import('@/views/Notify/setting.vue'),
        meta: {
          title: '啟用通知',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Notify/emailRecord', // Email紀錄
        name: 'NotifyEmailRecord',
        component: () => import('@/views/Notify/emailRecord.vue'),
        meta: {
          title: 'Email紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Notify/smsRecord', // 簡訊紀錄
        name: 'NotifySmsRecord',
        component: () => import('@/views/Notify/smsRecord.vue'),
        meta: {
          title: '簡訊紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/Notify/lineRecord', // LINE紀錄
        name: 'NotifyLineRecord',
        component: () => import('@/views/Notify/lineRecord.vue'),
        meta: {
          title: 'LINE紀錄',
          hidden: true,
          hiddenTab: true
        },
      },
      //
      // -------- 系統 --------
      //
      {
        path: 'admin/System/website', // 網站資料
        name: 'SystemWebsite',
        component: () => import('@/views/System/website.vue'),
        meta: {
          title: '網站資料',
          hidden: true,
          hiddenTab: true
        },
      },
      {
        path: 'admin/System/sitemap', // SEO SiteMap
        name: 'SystemSitemap',
        component: () => import('@/views/System/sitemap.vue'),
        meta: {
          title: 'SEO SiteMap',
          hidden: true,
          hiddenTab: true
        },
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.info('%c route: ', 'background: #222; color: #bada55', to, from, savedPosition);
    return { top: 0, behavior: 'smooth' }
  }
})

// 登入後進到這些頁面要跳轉回首頁
const notLoginPages = ['/admin/Login']

const allPages = [
  '/admin/Login',
  '/admin/Order/shipping',
  // '/admin/Order/returning',
  // '/admin/Order/changing',
  '/admin/Order/print',
  '/admin/Dashboard',
  '/admin/User/loginRecord',
  '/admin/User/operationRecord',
  '/admin/User/list',
  '/admin/User/add',
  '/admin/User/edit',
  '/admin/Content/type/bottom',
  '/admin/Content/index/list',
  '/admin/Content/article/list',
  '/admin/Content/article/add',
  '/admin/Content/article/edit',
  '/admin/Content/contact/list',
  '/admin/Content/contact/detail',
  '/admin/Member/setting',
  '/admin/Member/list',
  '/admin/Member/detail',
  '/admin/Product/list',
  '/admin/Product/add',
  '/admin/Product/edit',
  '/admin/Product/stockRecord',
  '/admin/Order/paymentSetting',
  '/admin/Order/shipmentSetting',
  '/admin/Order/list',
  '/admin/Order/detail',
  '/admin/Order/add',
  '/admin/Order/returnList',
  '/admin/Order/returnList/add',
  '/admin/Invoice/setting',
  '/admin/Invoice/setting/add',
  '/admin/Invoice/list',
  '/admin/Discount/Mission/list',
  '/admin/Discount/Mission/add',
  '/admin/Discount/Mission/edit',
  '/admin/Discount/Mission/checkRecord',
  '/admin/Discount/Mission/sentRecord',
  '/admin/Discount/Price/setting',
  '/admin/Discount/Price/list',
  '/admin/Discount/Price/add',
  '/admin/Discount/Price/edit',
  '/admin/Discount/PromoCode/setting',
  '/admin/Discount/PromoCode/list',
  '/admin/Discount/PromoCode/add',
  '/admin/Discount/PromoCode/edit',
  '/admin/Point/setting',
  '/admin/Point/bonusSend',
  '/admin/Point/pointSend',
  '/admin/Point/bonusRecord',
  '/admin/Point/pointRecord',
  '/admin/Report/sale',
  '/admin/Report/paymentFlow',
  '/admin/Report/product',
  '/admin/Notify/setting',
  '/admin/Notify/emailRecord',
  '/admin/Notify/smsRecord',
  '/admin/Notify/lineRecord',
  '/admin/System/website',
  '/admin/System/sitemap',
]

router.beforeEach( (to, from, next) => {
  const admin = useAdminStore()
  const webTitle = localStorage.getItem('name')
  const hasLoginToken = localStorage.getItem('token') ?? admin.isLogin
  
  if (to.meta.title) {
    document.title = `${webTitle} - ${to.meta.title}`
  }

  // 登入成功後自動重整一次
  if(hasLoginToken && from.fullPath === '/admin/Login'){
    if (localStorage.getItem('reloaded')) {
      localStorage.removeItem('reloaded')
    } else {
      localStorage.setItem('reloaded', '1')
      location.reload();
    }
  }

  // 登入後不可訪問頁面
  if (hasLoginToken && notLoginPages.includes(to.path) ) {
    next('/admin/Dashboard')
    return
  }

  // 登入後造訪非設定的路由
  if (hasLoginToken && !allPages.includes(to.path) ) {
    next('/admin/Dashboard')
    return
  }

  if (!hasLoginToken && !notLoginPages.includes(to.path)) {
    next('/admin/Login')
    return
  }
  
  next()
})

export default router
