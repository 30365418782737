<template>
  <div>
    <table width="1000" cellspacing="0" class="container table">
      <thead>
        <tr>
          <td colspan="2" style="border-bottom: 2px solid #ccc;">
            <h2 style="text-align: center; margin: 0;">維濟生技</h2>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="oddSection">
            <table cellspacing="0" width="100%">
              <thead>
                <th colspan="2">
                  <h3>訂單資訊</h3>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td width="120px">訂單成立時間：</td>
                  <td>{{ orderDetail.make_at }}</td>
                </tr>
                <tr>
                  <td>訂單號碼：</td>
                  <td>{{ orderDetail.order_pk }}</td>
                </tr>
                <tr v-show="orderDetail.o_order_pk">
                  <td>原訂單號碼：</td>
                  <td>{{ orderDetail.o_order_pk }}</td>
                </tr>
                <tr>
                  <td>訂單狀態：</td>
                  <td>{{ orderDetail.orders_status }}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table cellspacing="0" width="100%">
              <thead>
                <th colspan="2">
                  <h3>付款資訊</h3>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td width="130px">付款方式：</td>
                  <td>{{ orderDetail.pay_method }}</td>
                </tr>
                <tr>
                  <td>付款狀態：</td>
                  <td>{{ orderDetail.pay_status }}</td>
                </tr>
                <tr>
                  <td>發票/折讓：</td>
                  <td>{{ formatCode() }}</td>
                </tr>
                <tr>
                  <td>發票狀態：</td>
                  <td>{{ orderDetail.invoice_status }}</td>
                </tr>
                <tr>
                  <td>發票類型：</td>
                  <td>{{ orderDetail.invoice_type }} / {{ orderDetail.invoice_carrier_type }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr></tr>
        <tr>
          <td class="oddSection">
            <table cellspacing="0" width="100%">
              <thead>
                <th colspan="2">
                  <h3>顧客資訊</h3>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td width="120px">姓名：</td>
                  <td>{{ orderDetail.member_name }}</td>
                </tr>
                <tr>
                  <td>Email：</td>
                  <td>{{ orderDetail.member_account }}</td>
                </tr>
                <tr>
                  <td>手機：</td>
                  <td>{{ orderDetail.member_phone }}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table cellspacing="0" width="100%">
              <thead>
                <th colspan="2">
                  <h3>送貨資訊</h3>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td width="130px">收件人姓名：</td>
                  <td>{{ orderDetail.recipient_name }} {{ formatSex(orderDetail.sex) }}</td>
                </tr>
                <tr>
                  <td>收件人手機號碼：</td>
                  <td>{{ orderDetail.recipient_phone }}</td>
                </tr>
                <tr>
                  <td>訂單備註：</td>
                  <td>{{ orderDetail.note }}</td>
                </tr>
                <tr>
                  <td>送貨方式：</td>
                  <td>{{ orderDetail.shipment_method }}</td>
                </tr>
                <tr>
                  <td>送貨狀態：</td>
                  <td>{{ orderDetail.shipping_status }}</td>
                </tr>
                <tr>
                  <td>補充說明：</td>
                  <td>
                    <div>『結帳前請詳閱』</div>
                    <div>1.如材積過大須拆單作業，運費另計。</div>
                    <div>2.訂單內若有預購及現貨商品時，會等預購商品到達後一起出貨。</div>
                  </td>
                </tr>
                <tr>
                  <td>收件地址：</td>
                  <td>{{ orderDetail.recipient_address }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="height: 30px;"></div>
    <table width="1000" cellspacing="0" class="table">
      <tbody>
        <tr>
          <td colspan="2">
            <table cellspacing="0" width="100%">
              <thead>
                <th>
                  <h3>產品列表</h3>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <table cellspacing="0" width="100%" class="list">
                      <thead style="text-align: center; white-space: nowrap;">
                        <tr>
                          <th width="50%">產品名稱 / 規格</th>
                          <th>單價</th>
                          <th>數量</th>
                          <th>小計</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="border-bottom"
                          style="text-align: center;"
                          v-for="(item, index) in orderDetail.commodity_list"
                          :key="index"
                        >
                          <td>
                            <div class="table-pItem">
                              <span>{{ item.name }} / {{ item.specification_name }}</span>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">NT$ {{ checkMoney(item.amount) }}</div>
                            <!-- <div class="table-gift"></div> -->
                          </td>
                          <td class="text-center">
                            <div class="d-flex justify-content-center align-items-center">
                              <div class="quentity-container">{{ item.count }}</div>
                            </div>
                            <!-- <div class="table-gift">1</div> -->
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">NT${{ checkMoney(item.sum_amount) }}</div>
                            <!-- <div class="table-gift"></div> -->
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td class="total">
            <div class="text-right">
              <label>小計</label>
              <span class="order_price">NT${{ checkMoney(orderDetail.subtotal) }}</span>
            </div>
            <div class="text-right" v-show="Number(orderDetail.full_discount_amount) !== 0">
              <label>滿額折扣</label>
              <span class="order_price">-NT${{ checkMoney(orderDetail.full_discount_amount) }}</span>
            </div>
            <div class="text-right" v-show="Number(orderDetail.bonus) !== 0">
              <label>紅利</label>
              <span class="order_price">-NT${{ checkMoney(orderDetail.bonus) }}</span>
            </div>
            <div class="text-right" v-show="Number(orderDetail.promo_code_amount) !== 0">
              <label>折扣碼</label>
              <span class="order_price">-NT${{ checkMoney(orderDetail.promo_code_amount) }}</span>
            </div>
            <div class="text-right">
              <label class="mb-0">運費</label>
              <span class="order_price">NT${{ checkMoney(orderDetail.shipping_amount) }}</span>
            </div>
            <hr class="my-2" />
            <div class="text-right">
              <label>合計</label>
              <span class="order_price">NT${{ checkMoney(orderDetail.total_amount) }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="height: 30px;"></div>
    <table
      width="1000"
      cellspacing="0"
      class="table"
      v-if="orderDetail.returned_purchase_detail.length"
    >
      <tbody>
        <tr>
          <td colspan="2">
            <table cellspacing="0" width="100%">
              <thead>
                <th>
                  <h3>退貨紀錄</h3>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <table cellspacing="0" width="100%" class="list">
                      <thead style="text-align: center; white-space: nowrap;">
                        <tr>
                          <th width="50%">產品名稱 / 規格</th>
                          <th>單價</th>
                          <th>數量</th>
                          <th>小計</th>
                          <th>時間</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="border-bottom"
                          v-for="(item, index) in orderDetail.returned_purchase_detail"
                          :key="index"
                        >
                          <td>
                            <div class="table-pItem">
                              <span class="mr-1">{{ item.name }} / {{ item.specification_name }}</span>
                            </div>
                            <!-- <div class="table-gift ml-10px">
                              [贈品] 小時光有我
                              規格：無
                            </div>-->
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">NT${{ checkMoney(item.amount) }}</div>
                            <!-- <div class="table-gift"></div> -->
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">1</div>
                            <!-- <div class="table-gift">1</div> -->
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">NT${{ checkMoney(item.sum_amount) }}</div>
                            <!-- <div class="table-gift"></div> -->
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">{{ item.time }}</div>
                            <!-- <div class="table-gift"></div> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="height: 30px;"></div>
    <table
      width="1000"
      cellspacing="0"
      class="table"
      v-if="orderDetail.change_purchase_detail.length"
    >
      <tbody>
        <tr>
          <td colspan="2">
            <table cellspacing="0" width="100%">
              <thead>
                <th>
                  <h3>換貨紀錄</h3>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <table cellspacing="0" width="100%" class="list">
                      <thead style="text-align: center; white-space: nowrap;">
                        <tr>
                          <th width="50%">產品名稱 / 規格</th>
                          <th>單價</th>
                          <th>數量</th>
                          <th>小計</th>
                          <th>時間</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="border-bottom"
                          v-for="(item, index) in orderDetail.change_purchase_detail"
                          :key="index"
                        >
                          <td>
                            <div class="table-pItem">
                              <span class="mr-1">{{ item.name }} / {{ item.specification_name }}</span>
                            </div>
                            <!-- <div class="table-gift ml-10px">
                              [贈品] 小時光有我
                              規格：無
                            </div>-->
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">NT${{ checkMoney(item.amount) }}</div>
                            <!-- <div class="table-gift"></div> -->
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">1</div>
                            <!-- <div class="table-gift">1</div> -->
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">NT${{ checkMoney(item.sum_amount) }}</div>
                            <!-- <div class="table-gift"></div> -->
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">{{ item.time }}</div>
                            <!-- <div class="table-gift"></div> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'OrderPrint',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import Loading from '@/components/Loading.vue'
import { getOrderDetail } from '@/apis/order'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import { checkMoney } from '@/utils/checkMoney'
import Swal from 'sweetalert2'

const isLoading = ref(false)

const orderDetail = ref({
  commodity_list: [], // 購買清單
  change_purchase_detail: [], // 換貨清單
  returned_purchase_detail: [], // 退貨清單
})

const getDetail = async () => {
  isLoading.value = true
  try {
    const res = await getOrderDetail({
      order_id: localStorage.getItem('orderId'),
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      orderDetail.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getDetail()
})

const formatNull = (item) => {
  return (!item) ? '尚未開立' : item
}

const formatCode = () => {
  return (orderDetail.value.orders_stauts === '已退貨') ? formatNull(orderDetail.value.discount_code) : formatNull(orderDetail.value.invoice_code)
}

const formatSex = (sex) => {
  return sex === '男' ? '先生' : '小姐'
}

</script>

<style lang="scss" scoped>
:root {
  --main: #477751;
}

.table {
  margin: 0 auto;
  border: 2px solid #ccc;
}

.container .oddSection {
  vertical-align: top;
  border-right: 1px solid #eee;
  width: 50%;
}

th {
  background-color: #eee;
  padding: 5px;
  text-align: start;
}

th h3 {
  margin: 0;
  font-size: 1.1em;
}

td {
  padding: 5px;
}

.list {
  text-align: center;
}

.list td:first-of-type {
  text-align: start;
}

.list th {
  background-color: transparent;
  text-align: center;
}

.table-pItem,
.table-gift {
  height: 25px;
  line-height: 25px;
}

tr .table-gift {
  font-size: 0.9em;
}

.ml-10px {
  margin-left: 10px;
}

.text-right {
  text-align: end;
}

.order_price {
  width: 100px;
  display: inline-block;
}

.total div {
  margin-bottom: 5px;
}

.product_dlabel {
  border: 1px solid;
  padding: 1px 5px;
  border-radius: 5px;
  color: var(--main);
  font-size: 0.8em;
}

.product_dlabel.cold {
  color: blue;
}

.product_dlabel.cool {
  color: #3498db;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}
</style>