<template>
  <div class="container-fluid">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">進行出貨</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form>
              <h5 class="mb-3">訂單資訊</h5>
              <div class="table-responsive text-nowrap">
                <table class="table">
                  <tbody>
                    <tr>
                      <th style="width: 150px;">訂單成立時間</th>
                      <td>{{ shippingData.make_at }}</td>
                    </tr>
                    <tr>
                      <th>訂單號碼</th>
                      <td>
                        {{ shippingData.order_num }}
                        <!-- <span class="badge badge-danger">退貨</span>
                        <span class="badge badge-danger">換貨</span>-->
                      </td>
                    </tr>
                    <tr>
                      <th>訂單狀態</th>
                      <td>
                        <span
                          class="badge badge-warning text-white"
                          v-if="shippingData.order_status === '未付款'"
                        >未付款</span>
                        <span
                          class="badge badge-secondary"
                          v-if="shippingData.order_status === '已失效'"
                        >已失效</span>
                        <span
                          class="badge badge-secondary"
                          v-if="shippingData.order_status === '已取消'"
                        >已取消</span>
                        <span
                          class="badge badge-danger"
                          v-if="shippingData.order_status === '待退款'"
                        >待退款</span>
                        <span
                          class="badge badge-secondary"
                          v-if="shippingData.order_status === '已退款'"
                        >已退款</span>
                        <span
                          class="badge badge-warning text-white"
                          v-if="shippingData.order_status === '待出貨'"
                        >待出貨</span>
                        <span
                          class="badge badge-info"
                          v-if="shippingData.order_status === '已出貨'"
                        >已出貨</span>
                        <span
                          class="badge badge-primary"
                          v-if="shippingData.order_status === '已到貨'"
                        >已到貨</span>
                        <span
                          class="badge badge-orange"
                          v-if="shippingData.order_status === '已完成'"
                        >已完成</span>
                        <span
                          class="badge badge-danger"
                          v-if="shippingData.order_status === '待退貨'"
                        >待退貨</span>
                        <span
                          class="badge badge-info"
                          v-if="shippingData.order_status === '已退貨'"
                        >已退貨</span>
                        <span
                          class="badge badge-info"
                          v-if="shippingData.order_status === '退貨取消'"
                        >退貨取消</span>
                        <span
                          class="badge badge-danger"
                          v-if="shippingData.order_status === '待換貨'"
                        >待換貨</span>
                        <span
                          class="badge badge-info"
                          v-if="shippingData.order_status === '已換貨'"
                        >已換貨</span>
                        <span
                          class="badge badge-secondary"
                          v-if="shippingData.order_status === '換貨取消'"
                        >換貨取消</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5 class="pt-2 mb-3">顧客資訊</h5>
              <div class="table-responsive text-nowrap">
                <table class="table">
                  <tbody>
                    <tr>
                      <th style="width: 150px;">會員</th>
                      <td>
                        <div>{{ shippingData.member_name }} {{ formatSex(shippingData.sex) }}</div>
                        <div>{{ shippingData.member_account }} {{ shippingData.recipient_phone }}</div>
                        <!-- <div>test123@gmail.com 0912345678</div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5 class="pt-2 mb-3">物流單號</h5>
              <div class="table-responsive text-nowrap">
                <table class="table">
                  <tbody>
                    <tr>
                      <th style="width: 150px;">物流資訊</th>
                      <td>{{ shippingData.use_shipment_methods_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 150px;">寄出日期</th>
                      <td>
                        <input
                          type="date"
                          class="form-control col-md-6 date"
                          v-model="shippingForm.shipping_at"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 150px;">物流單號</th>
                      <td>
                        <input
                          type="text"
                          class="form-control col-md-6"
                          v-model="shippingForm.lgs_no"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="text-center">
                <button class="btn btn-default" @click.prevent="cancel">取消</button>
                <button class="btn btn-primary" @click.prevent="send">送出</button>
              </div>
            </form>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>
  </div>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script>
export default {
  name: 'OrderShipping',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import Loading from '@/components/Loading.vue'
import { getGoShippingData, updateShipping } from '@/apis/order'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const isLoading = ref(false)

const shippingForm = ref({
  order_id: '',
  shipping_at: '',
  lgs_no: '',
})

const shippingData = ref({})

const getDetail = async (order_id) => {
  isLoading.value = true
  try {
    const res = await getGoShippingData({
      order_id,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      shippingData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  shippingForm.value.order_id = localStorage.getItem('orderId')
  getDetail(shippingForm.value.order_id)
})

const formatSex = (sex) => {
  return sex === '男' ? '先生' : '小姐'
}

const send = async () => {
  isLoading.value = true
  try {
    const res = await updateShipping({
      ...shippingForm.value,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: '更新成功'
      }).then(() => {
        window.close()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      Swal.fire({
        icon: 'error',
        title: '登入逾期'
      }).then(() => {
        window.close()
      })
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const cancel = () => {
  window.close()
}

</script>

<style lang="scss" scoped>
</style>