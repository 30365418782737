<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div class="card">
            <div class="card-body">
              <form>
                <h6 class="mt-2 mb-3 font-weight-bold">啟用手機註冊</h6>
                <p class="text-danger">開啟時，請先檢查您是否有完成串接服務-簡訊設定並已完成儲值作業。</p>
                <div class="row">
                  <div class="col-md-2">
                    <label class="mt-2 mb-0">簡訊驗證碼</label>
                  </div>
                  <div class="col-md-10">
                    <!-- <div class="mb-2">
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="sms_verify"
                          id="sms_verify_open"
                          value="Y"
                          v-model="configForm.sms_verify"
                        />
                        <label for="sms_verify_open" class="mx-1 mb-0">開啟</label>
                      </div>
                      <div class="d-inline-flex align-items-center mr-2 radio icheck-turquoise">
                        <input
                          type="radio"
                          name="sms_verify"
                          id="sms_verify_close"
                          value="N"
                          v-model="configForm.sms_verify"
                        />
                        <label for="sms_verify_close" class="mx-1 mb-0">關閉</label>
                      </div>
                      <span class="d-block d-lg-inline ml-lg-2 text-danger">
                        登入頁面將開啟
                        <span class="text-primary">使用簡訊登入</span> 選項。
                      </span>
                    </div>-->
                    <div class="mb-2">
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="chang_phone_verify"
                          id="chang_phone_verify_open"
                          value="Y"
                          v-model="configForm.chang_phone_verify"
                        />
                        <label for="chang_phone_verify_open" class="mx-1 mb-0">開啟</label>
                      </div>
                      <div class="d-inline-flex align-items-center mr-2 radio icheck-turquoise">
                        <input
                          type="radio"
                          name="chang_phone_verify"
                          id="chang_phone_verify_close"
                          value="N"
                          v-model="configForm.chang_phone_verify"
                        />
                        <label for="chang_phone_verify_close" class="mx-1 mb-0">關閉</label>
                      </div>
                      <span class="d-block d-lg-inline ml-lg-2 text-danger">會員資料手機號碼變更時，將使用簡訊驗證碼服務。</span>
                    </div>
                  </div>
                </div>
                <h6 class="mt-2 mb-3 font-weight-bold">啟用社群登入</h6>
                <p class="text-danger">開啟時，請先檢查您是否有完成串接服務-社群登入設定並已完成串接作業。</p>
                <p class="text-danger">開啟時，會員登入介面將會顯示社群登入按鈕。</p>
                <div class="row mb-1">
                  <div class="col-md-2">
                    <label class="mb-0 mt-2">Google登入</label>
                  </div>
                  <div class="col-md-10">
                    <div class="mb-2">
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="google"
                          id="google_1"
                          value="Y"
                          v-model="configForm.google_login"
                        />
                        <label for="google_1" class="mx-1 mb-0">開啟</label>
                      </div>
                      <div class="d-inline-flex align-items-center mr-2 radio icheck-turquoise">
                        <input
                          type="radio"
                          name="google"
                          id="google_2"
                          value="N"
                          v-model="configForm.google_login"
                        />
                        <label for="google_2" class="mx-1 mb-0">關閉</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-md-2">
                    <label class="mb-0 mt-2">LINE登入</label>
                  </div>
                  <div class="col-md-10">
                    <div class="mb-2">
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="line"
                          id="line_1"
                          value="Y"
                          v-model="configForm.line_login"
                        />
                        <label for="line_1" class="mx-1 mb-0">開啟</label>
                      </div>
                      <div class="d-inline-flex align-items-center mr-2 radio icheck-turquoise">
                        <input
                          type="radio"
                          name="line"
                          id="line_2"
                          value="N"
                          v-model="configForm.line_login"
                        />
                        <label for="line_2" class="mx-1 mb-0">關閉</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-md-2">
                    <label class="mb-0 mt-2">Facebook登入</label>
                  </div>
                  <div class="col-md-10">
                    <div class="mb-2">
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="facebook"
                          id="facebook_1"
                          value="Y"
                          v-model="configForm.facebook_login"
                        />
                        <label for="facebook_1" class="mx-1 mb-0">開啟</label>
                      </div>
                      <div class="d-inline-flex align-items-center mr-2 radio icheck-turquoise">
                        <input
                          type="radio"
                          name="facebook"
                          id="facebook_2"
                          value="N"
                          v-model="configForm.facebook_login"
                        />
                        <label for="facebook_2" class="mx-1 mb-0">關閉</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center my-2">
                  <button
                    type="reset"
                    class="btn btn-default mr-1"
                    @click.prevent="cancel(topForm)"
                  >取消</button>
                  <button type="submit" class="btn btn-primary" @click.prevent="send(bottomForm)">儲存</button>
                </div>
              </form>
            </div>
          </div>
          <!-- <div class="card">
            <div class="card-body">
              <form>
                <div class="row mb-1">
                  <div class="col-md-2 mb-2 mb-lg-0">
                    <label class="mb-0 mt-2">LINE Login</label>
                  </div>
                  <div class="col-md-10">
                    <div class="pl-3 pl-lg-0 form-group">
                      <label class="col-form-label">Client ID</label>
                      <input type="text" class="form-control" v-model="configForm.line_client_id" />
                    </div>
                    <div class="pl-3 pl-lg-0 form-group">
                      <label class="col-form-label">Client Secret</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="configForm.line_client_secret"
                      />
                    </div>
                    <div class="pl-3 pl-lg-0 form-group">
                      <label class="col-form-label">Redirect URL</label>
                      <input type="text" class="form-control" v-model="configForm.line_client_url" />
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-md-2 mb-2 mb-lg-0">
                    <label class="mb-0 mt-2">Facebook Login</label>
                  </div>
                  <div class="col-md-10">
                    <div class="pl-3 pl-lg-0 form-group">
                      <label class="col-form-label">Client ID</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="configForm.facebook_client_id"
                      />
                    </div>
                    <div class="pl-3 pl-lg-0 form-group">
                      <label class="col-form-label">Client Secret</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="configForm.facebook_client_secret"
                      />
                    </div>
                    <div class="pl-3 pl-lg-0 form-group">
                      <label class="col-form-label">Redirect URL</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="configForm.facebook_client_url"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-md-2 mb-2 mb-lg-0">
                    <label class="mb-0 mt-2">Google Login</label>
                  </div>
                  <div class="col-md-10">
                    <div class="pl-3 pl-lg-0 form-group">
                      <label class="col-form-label">Client ID</label>
                      <input type="text" class="form-control" v-model="configForm.google_client_id" />
                    </div>
                    <div class="pl-3 pl-lg-0 form-group">
                      <label class="col-form-label">Client Secret</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="configForm.google_client_secret"
                      />
                    </div>
                    <div class="pl-3 pl-lg-0 form-group">
                      <label class="col-form-label">Redirect URL</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="configForm.google_client_url"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-center my-2">
                  <button
                    type="reset"
                    class="btn btn-default mr-1"
                    @click.prevent="cancel(bottomForm)"
                  >取消</button>
                  <button type="submit" class="btn btn-primary" @click.prevent="send(topForm)">儲存</button>
                </div>
              </form>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script>
export default {
  name: 'MemberSetting',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { getMemberConfig, updateMemberConfig } from '@/apis/member'
import Swal from 'sweetalert2'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'

const breadCrumbs = ref({
  parentPage: '會員',
  currentPage: '登入設定'
})

const isLoading = ref(false)

const configForm = ref({
  sms_verify: '',
  chang_phone_verify: '',
  google_login: '',
  line_login: '',
  facebook_login: '',
  line_client_id: '',
  line_client_secret: '',
  line_client_url: '',
  facebook_client_id: '',
  facebook_client_secret: '',
  facebook_client_url: '',
  google_client_id: '',
  google_client_secret: '',
  google_client_url: '',
})

const cloneConfigForm = ref({})

const topForm = ref(['sms_verify', 'chang_phone_verify', 'google_login', 'line_login', 'facebook_login'])
const bottomForm = ref(['line_client_id', 'line_client_secret', 'line_client_url', 'facebook_client_id', 'facebook_client_secret', 'facebook_client_url', 'google_client_id', 'google_client_secret', 'google_client_url'])

const getConfig = async () => {
  isLoading.value = true
  try {
    const res = await getMemberConfig({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      configForm.value = res.data.data
      cloneConfigForm.value = JSON.parse(JSON.stringify(res.data.data))
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getConfig()
})

const cancel = (ary) => {
  for (let i in ary) {
    let target = ary[i]
    configForm.value[target] = cloneConfigForm.value[target]
  }
}

const send = async (restoreAry) => {
  isLoading.value = true
  const data = JSON.parse(JSON.stringify(configForm.value))
  for (let i in restoreAry) {
    let target = restoreAry[i]
    data[target] = cloneConfigForm.value[target]
  }
  data.token = localStorage.getItem('token')
  try {
    const res = await updateMemberConfig({ ...data })
    if (res.data.data) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: res.data.data
      }).then(() => {
        getConfig()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

</script>

<style lang="scss" scoped>
button {
  margin: 0 5px;
}
</style>