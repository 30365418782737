<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td width="120">詢問時間</td>
                    <td>{{ detailData.created_at }}</td>
                  </tr>
                  <tr>
                    <td>詢問類型</td>
                    <td>{{ detailData.question_type }}</td>
                  </tr>
                  <tr>
                    <td>姓名</td>
                    <td>{{ detailData.name }}</td>
                  </tr>
                  <tr>
                    <td>稱謂</td>
                    <td>{{ formatSex(detailData.gender) }}</td>
                  </tr>
                  <tr>
                    <td>聯絡電話</td>
                    <td>{{ detailData.phone }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ detailData.mail }}</td>
                  </tr>
                  <tr>
                    <td>詢問內容</td>
                    <td>{{ detailData.remark }}</td>
                  </tr>
                  <tr>
                    <td class="align-middle">回覆狀態</td>
                    <td>
                      <div class="d-inline-flex align-items-center icheck-turquoise">
                        <input
                          type="checkbox"
                          name="reply_status"
                          id="reply_status"
                          :checked="detailData.reply_record.length > 0"
                        />
                        <label></label>
                      </div>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>回覆時間</td>
                    <td>2021-09-08 22:50:22</td>
                  </tr>-->
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <p class="text-danger">內容將會直接回覆至客戶端信箱</p>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td width="120">寄件信箱</td>
                      <td>{{ detailData.mail }}</td>
                    </tr>
                    <tr>
                      <td>回覆內容</td>
                      <td>
                        <textarea
                          name="reply_content"
                          rows="3"
                          class="form-control"
                          v-model="replyMsg"
                        ></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 class>回覆紀錄</h6>
                <div class="reply-container">
                  <div class="text-info mb-2" v-for="msg in detailData.reply_record" :key="msg.id">
                    {{ msg.created_at}}
                    <!-- [總管理員] -->
                    <div class="text-muted">{{ msg.reply }}</div>
                    <hr />
                  </div>
                </div>
                <div class="text-center mt-3">
                  <!-- <button type="reset" class="btn btn-default mr-1">取消</button> -->
                  <button @click.prevent="send" class="btn btn-primary">寄出回覆信件</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'ContactDetail',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { getReplyData, sendReply } from '@/apis/content'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  parentPage: '網站設定',
  currentPage: '聯絡我們',
  currentPath: '/admin/Content/contact/list',
  childPage: '回覆聯絡我們',
})

const isLoading = ref(false)

const detailData = ref({
  reply_record: []
})

const getData = async () => {
  isLoading.value = true
  try {
    const res = await getReplyData({
      contactuses_id: localStorage.getItem('contactId'),
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      detailData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getData()
})

const formatSex = (sex) => {
  return sex === 'F' ? '小姐' : '先生'
}

const replyMsg = ref('')

const send = async () => {
  isLoading.value = true
  try {
    const res = await sendReply({
      contactuses_id: localStorage.getItem('contactId'),
      reply: replyMsg.value,
      mail_status: 'N',
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      getData()
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
</style>