<template>
  <footer class="main-footer">
    2023
    <strong>© 維濟生技 版權所有</strong>
    <!-- <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io/">AdminLTE.io</a>.</strong> -->
    <!-- All rights reserved. -->
    <!-- <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.1.0
    </div>-->
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>