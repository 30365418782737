<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <h5 class="pt-2">基本資訊</h5>
                <hr />
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 140px; " class="align-middle">啟用網站</th>
                      <td>
                        <div class="mb-1">
                          <div class="icheck-turquoise d-inline-block mr-2">
                            <input
                              type="radio"
                              id="website-open"
                              name="website-status"
                              value="Y"
                              v-model="websiteForm.enable"
                            />
                            <label for="website-open" class="form-check-label">開啟</label>
                          </div>
                          <div class="icheck-turquoise d-inline-block mr-2">
                            <input
                              type="radio"
                              id="website-close"
                              name="website-status"
                              value="N"
                              v-model="websiteForm.enable"
                            />
                            <label for="website-close" class="form-check-label">關閉</label>
                          </div>
                        </div>
                        <div class="text-danger">關閉時全部頁面將導向「網站維護中」頁面</div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">網站名稱</th>
                      <td>
                        <div class="row">
                          <div class="col-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="請輸入網站名稱"
                              v-model="websiteForm.name"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <th style="width: 140px; " class="align-middle">頁籤顯示</th>
                      <td>
                        <div class>頁面名稱 - 網站名稱</div>
                        <div class="text-danger">頁面標題顯示呈現的方式</div>
                      </td>
                    </tr>-->
                    <tr>
                      <th style="width: 140px; vertical-align: middle;">網站網址</th>
                      <td>{{ frontURL }}</td>
                    </tr>
                  </table>
                </div>
                <h6 class="mt-2 mb-3 font-weight-bold">版權宣告</h6>
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 140px; " class="align-middle">公司名稱</th>
                      <td>
                        <div class="row">
                          <div class="col-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="請輸入公司名稱"
                              v-model="websiteForm.c_name "
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">統一編號</th>
                      <td>
                        <div class="row">
                          <div class="col-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="請輸入統一編號"
                              v-model="websiteForm.tax_id"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">客服信箱</th>
                      <td>
                        <div class="row">
                          <div class="col-6">
                            <input
                              type="mail"
                              class="form-control"
                              placeholder="請輸入客服信箱"
                              v-model="websiteForm.c_mail"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">客服專線</th>
                      <td>
                        <div class="row">
                          <div class="col-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="請輸入客服專線"
                              v-model="websiteForm.c_tel"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <!--<tr>
                      <th style="width: 140px; " class="align-middle">聯絡電話</th>
                      <td>
                        <div class="row">
                          <div class="col-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="請輸入聯絡電話"
                              v-model="websiteForm.tel"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                     <tr>
                      <th style="width: 140px; " class="align-middle">傳真號碼</th>
                      <td>
                        <div class="row">
                          <div class="col-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="請輸入傳真號碼"
                              v-model="websiteForm.fox"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>-->
                    <tr>
                      <th style="width: 140px; " class="align-middle">營業時間</th>
                      <td>
                        <div class="row">
                          <div class="col-6">
                            <textarea
                              name
                              id
                              rows="3"
                              class="form-control"
                              v-model="websiteForm.bn_hours"
                              placeholder="請輸入營業時間"
                            ></textarea>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">版權訊息</th>
                      <td>
                        <div class="row">
                          <div class="col-6">
                            <input
                              type="text"
                              class="form-control"
                              v-model="websiteForm.copy_info"
                              placeholder="請輸入版權訊息"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </form>
              <form>
                <h5 class="pt-2">聯絡我們</h5>
                <hr />
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 140px; " class="align-middle">寄送副本給會員</th>
                      <td>
                        <div>
                          <div class="icheck-turquoise d-inline-block mr-2">
                            <input
                              type="radio"
                              id="send_true"
                              name="send_copy"
                              value="Y"
                              v-model="websiteForm.mail_cc"
                            />
                            <label for="send_true" class="form-check-label">開啟</label>
                          </div>
                          <div class="icheck-turquoise d-inline-block mr-2">
                            <input
                              type="radio"
                              id="send_false"
                              name="send_copy"
                              value="N"
                              v-model="websiteForm.mail_cc"
                            />
                            <label for="send_false" class="form-check-label">關閉</label>
                          </div>
                        </div>
                        <div class="text-danger">寄送內容通知給會員</div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">管理員信箱</th>
                      <td>
                        <div class="row">
                          <div class="col-md-4 mb-2 mb-md-0">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="輸入信箱"
                              v-model="websiteForm.mail1"
                            />
                          </div>
                          <div class="col-md-4 mb-2 mb-md-0">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="輸入信箱"
                              v-model="websiteForm.mail2"
                            />
                          </div>
                          <div class="col-md-4 mb-2 mb-md-0">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="輸入信箱"
                              v-model="websiteForm.mail3"
                            />
                          </div>
                        </div>
                        <div class="text-danger">寄送內容通知給管理員</div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">問題類型</th>
                      <td>
                        <div class="row mb-2">
                          <div class="col-md-4 mb-2 mb-md-0">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="輸入類型"
                              v-model="websiteForm.q_type1"
                            />
                          </div>
                          <div class="col-md-4 mb-2 mb-md-0">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="輸入類型"
                              v-model="websiteForm.q_type2"
                            />
                          </div>
                          <div class="col-md-4 mb-2 mb-md-0">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="輸入類型"
                              v-model="websiteForm.q_type3"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mb-2 mb-md-0">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="輸入類型"
                              v-model="websiteForm.q_type4"
                            />
                          </div>
                          <div class="col-md-4 mb-2 mb-md-0">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="輸入類型"
                              v-model="websiteForm.q_type5"
                            />
                          </div>
                          <div class="col-md-4 mb-2 mb-md-0">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="輸入類型"
                              v-model="websiteForm.q_type6"
                            />
                          </div>
                        </div>
                        <div class="text-danger">若為空值則前台不顯示,問題類型最多六項。</div>
                      </td>
                    </tr>
                  </table>
                </div>
              </form>
              <form>
                <h5 class="pt-2">客服按鈕</h5>
                <hr />
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 140px; " class="align-middle">顯示位置</th>
                      <td>網站右側邊</td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">LINE</th>
                      <td>
                        <Toggle v-model="websiteForm.line_show" falseValue="N" trueValue="Y" />
                        <div class="serviceLine d-inline-flex">
                          <a class="text-white">
                            <i class="fab fa-line" style="font-size: 35px;"></i>
                          </a>
                        </div>
                        <input
                          type="text"
                          class="form-control mt-2"
                          v-model="websiteForm.service_line"
                          v-show="websiteForm.line_show === 'Y'"
                          placeholder="請輸入連結網址"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">Facebook</th>
                      <td>
                        <Toggle v-model="websiteForm.fb_show" falseValue="N" trueValue="Y" />
                        <div class="serviceFB d-inline-flex">
                          <a href="javascript:;" class="text-white">
                            <i class="fab fa-facebook-messenger" style="font-size: 35px;"></i>
                          </a>
                        </div>
                        <input
                          type="text"
                          class="form-control mt-2"
                          v-model="websiteForm.service_fb"
                          v-show="websiteForm.fb_show === 'Y'"
                          placeholder="請輸入連結網址"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">聯絡電話</th>
                      <td>
                        <Toggle v-model="websiteForm.tel_show" falseValue="N" trueValue="Y" />
                        <div class="servicePhone d-inline-flex">
                          <a href="javascript:;" class="text-white">
                            <i class="fab fa-whatsapp" style="font-size: 35px;"></i>
                          </a>
                        </div>
                        <input
                          type="text"
                          class="form-control mt-2"
                          v-model="websiteForm.service_tel"
                          v-show="websiteForm.tel_show === 'Y'"
                          placeholder="請輸入電話號碼"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">聯絡我們</th>
                      <td>
                        <Toggle v-model="websiteForm.mail_show" falseValue="N" trueValue="Y" />
                        <div class="serviceBtn d-inline-flex">
                          <a href="javascript:;" class="text-white">
                            <i class="far fa-envelope" style="font-size: 35px;"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </form>
              <form>
                <h5 class="pt-2">社群連結</h5>
                <hr />
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 140px; " class="align-middle">顯示位置</th>
                      <td>底部選單 右邊</td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">LINE</th>
                      <td>
                        <Toggle v-model="websiteForm.linelink_show" falseValue="N" trueValue="Y" />
                        <img
                          class="ml-2"
                          :src="require('@/assets/images/footer-line.png')"
                          style="width: 40px; height: 40px;"
                        />
                        <input
                          type="text"
                          class="form-control mt-2"
                          v-model="websiteForm.line_link"
                          v-show="websiteForm.linelink_show === 'Y'"
                          placeholder="請輸入連結網址"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">Youtube</th>
                      <td>
                        <Toggle v-model="websiteForm.utb_show" falseValue="N" trueValue="Y" />
                        <img
                          class="ml-2"
                          :src="require('@/assets/images/footer-yt.png')"
                          style="width: 40px; height: 40px;"
                        />
                        <input
                          type="text"
                          class="form-control mt-2"
                          v-model="websiteForm.utb_link"
                          v-show="websiteForm.utb_show === 'Y'"
                          placeholder="請輸入連結網址"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">Facebook</th>
                      <td>
                        <Toggle v-model="websiteForm.fb_link_show" falseValue="N" trueValue="Y" />
                        <img
                          class="ml-2"
                          :src="require('@/assets/images/footer-fb.png')"
                          style="width: 40px; height: 40px;"
                        />
                        <input
                          type="text"
                          class="form-control mt-2"
                          v-model="websiteForm.fb_link"
                          v-show="websiteForm.fb_link_show === 'Y'"
                          placeholder="請輸入連結網址"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">Instagram</th>
                      <td>
                        <Toggle v-model="websiteForm.ig_show" falseValue="N" trueValue="Y" />
                        <img
                          class="ml-2"
                          :src="require('@/assets/images/footer-ig.png')"
                          style="width: 40px; height: 40px;"
                        />
                        <input
                          type="text"
                          class="form-control mt-2"
                          v-model="websiteForm.ig"
                          v-show="websiteForm.ig_show === 'Y'"
                          placeholder="請輸入連結網址"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </form>
              <form>
                <h5 class="pt-2">社群分享</h5>
                <hr />
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 140px; " class="align-middle">顯示位置</th>
                      <td>商品介紹 產品圖下方</td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">LINE</th>
                      <td>
                        <Toggle v-model="websiteForm.line_share" falseValue="N" trueValue="Y" />
                        <img class="ml-2" :src="require('@/assets/images/LINE.png')" />
                        <!-- <textarea rows="4" class="form-control mt-2" pl  aceholder="請輸入代碼"></textarea> -->
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 140px; " class="align-middle">Facebook</th>
                      <td>
                        <Toggle v-model="websiteForm.fb_share" falseValue="N" trueValue="Y" />
                        <img class="ml-2" :src="require('@/assets/images/fb.png')" />
                        <!-- <textarea rows="4" class="form-control mt-2" placeholder="請輸入代碼"></textarea> -->
                      </td>
                    </tr>
                    <!-- <tr>
                      <th style="width: 140px; " class="align-middle">Facebook Messenger</th>
                      <td>
                        <Toggle v-model="websiteForm.fbm_share" falseValue="N" trueValue="Y" />
                        <img class="ml-2" :src="require('@/assets/images/msg.png')" />
                      </td>
                    </tr>-->
                    <tr>
                      <th style="width: 140px; " class="align-middle">複製網址</th>
                      <td>
                        <Toggle v-model="websiteForm.copy_share" falseValue="N" trueValue="Y" />
                        <img class="ml-2" :src="require('@/assets/images/copy.png')" />
                        <!-- <textarea rows="4" class="form-control mt-2 d-none" placeholder="請輸入代碼"></textarea> -->
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="text-center">
                  <button class="btn btn-default mr-2" @click.prevent="reset">取消</button>
                  <button class="btn btn-primary" @click.prevent="send">儲存</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'SystemWebsite',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { getWebsiteData, updateWebsiteData } from '@/apis/system'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'
import { useAdminStore } from '@/store/admin'
import { frontURL } from '@/utils/baseWebURL'

const breadCrumbs = ref({
  parentPage: '系統',
  currentPage: '網站資料',
})

const admin = useAdminStore()

const isLoading = ref(false)

const websiteForm = ref({
  enable: 'Y', // 網站啟用與否，Y/N
  name: '', // 網站名稱
  c_name: '', // 公司名稱
  tax_id: '', // 公司統編
  c_mail: '', // 客服信箱
  c_tel: '', // 客服專線
  // tel: '', // 連絡電話
  // fox: '', // 傳真號碼
  bn_hours: '', // 營業時間，ex: 10:00-23:00
  copy_info: '', // 版權宣告，ex: Copyright © 2023 {{ ShopName }} All Rights Reserved.
  mail_cc: 'Y', // 是否寄送副本給會員，Y/N
  mail1: '', // 管理員信箱 1
  mail2: '', // 管理員信箱 2
  mail3: '', // 管理員信箱 3
  q_type1: '', // 產品問題，問題類型 1
  q_type2: '', // 產品問題，問題類型 2
  q_type3: '', // 產品問題，問題類型 3
  q_type4: '', // 產品問題，問題類型 4
  q_type5: '', // 產品問題，問題類型 5
  q_type6: '', // 產品問題，問題類型 6
  line_show: 'Y', // LINE 客服按鈕顯示，Y/N
  service_line: '', // 客服按鈕-LINE
  fb_show: 'Y', // FB，Y/N
  service_fb: '', // 客服按鈕-FB
  tel_show: 'Y', // Tel客服按鈕顯示，Y/N
  service_tel: '', // 客服按鈕-Tel
  mail_show: 'Y', // mail客服按鈕顯示，Y/N
  service_mail: '', // 客服按鈕-mail
  linelink_show: 'Y', // Line社群連結顯示，Y/N
  line_link: '', // 客服按鈕-LINE
  utb_show: 'Y', // Youtube社群連結顯示，Y/N
  utb_link: '', // 社群連結-Youtube
  fb_link_show: 'Y', // FB社群連結顯示，Y/N
  fb_link: '', // 客服按鈕-Tel
  ig_show: 'Y', // IG社群連結顯示，Y/N
  ig: '', // 社群連結-IG
  line_share: 'Y', // Line社群分享顯示，Y/N
  fb_share: 'Y', // FB社群分享顯示，Y/N
  fbm_share: 'N', // FBMessenger社群分享顯示，Y/N
  copy_share: 'Y', // 複製分享顯示，Y/N
})
// // 基本資訊
// const webBasicForm = ref({
//   enable: 'Y', // 網站啟用與否，Y/N
//   name: '', // 網站名稱
//   c_name: '', // 公司名稱
//   tax_id: '', // 公司統編
//   c_tel: '', // 客服專線
//   tel: '', // 連絡電話
//   fox: '', // 傳真號碼
//   bn_hours: '', // 營業時間，ex: 10:00-23:00
//   copy_info: '', // 版權宣告，ex: Copyright © 2023 {{ ShopName }} All Rights Reserved.
// })
// // 聯絡我們
// const contactForm = ref({
//   mail_cc: 'Y', // 是否寄送副本給會員，Y/N
//   mail1: '', // 管理員信箱 1
//   mail2: '', // 管理員信箱 2
//   mail3: '', // 管理員信箱 3
//   q_type1: '', // 產品問題，問題類型 1
//   q_type2: '', // 產品問題，問題類型 2
//   q_type3: '', // 產品問題，問題類型 3
//   q_type4: '', // 產品問題，問題類型 4
//   q_type5: '', // 產品問題，問題類型 5
//   q_type6: '', // 產品問題，問題類型 6
// })
// // 客服按鈕
// const customerServiceForm = ref({
//   line_show: 'Y', // LINE 客服按鈕顯示，Y/N
//   service_line: '', // 客服按鈕-LINE
//   fb_show: 'Y', // FB，Y/N
//   service_fb: '', // 客服按鈕-FB
//   tel_show: 'Y', // Tel客服按鈕顯示，Y/N
//   service_tel: '', // 客服按鈕-Tel
//   mail_show: 'Y', // mail客服按鈕顯示，Y/N
//   service_mail: '', // 客服按鈕-mail
// })
// // 社群連結
// const communityLinkForm = ref({
//   linelink_show: 'Y', // Line社群連結顯示，Y/N
//   line_link: '', // 客服按鈕-LINE
//   utb_show: 'Y', // Youtube社群連結顯示，Y/N
//   utb_link: '', // 社群連結-Youtube
//   fb_link_show: 'Y', // FB社群連結顯示，Y/N
//   fb_link: '', // 客服按鈕-Tel
//   ig_show: 'Y', // IG社群連結顯示，Y/N
//   ig: '', // 社群連結-IG
// })
// // 社群分享
// const communityShareForm = ref({
//   line_share: 'Y', // Line社群分享顯示，Y/N
//   fb_share: 'Y', // FB社群分享顯示，Y/N
//   fbm_share: 'Y', // FBMessenger社群分享顯示，Y/N
// })

const cloneData = ref({})
const getData = async () => {
  isLoading.value = true
  try {
    const res = await getWebsiteData({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      cloneData.value = res.data.data
      websiteForm.value = res.data.data
      // setData('webBasicForm', res.data.data)
      // setData('contactForm', res.data.data)
      // setData('customerServiceForm', res.data.data)
      // setData('communityLinkForm', res.data.data)
      // setData('communityShareForm', res.data.data)
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getData()
})

// const setData = (target, data) => {
//   if (target === 'webBasicForm') {
//     webBasicForm.value = {
//       enable: data.enable,
//       name: data.name,
//       c_name: data.name,
//       tax_id: data.tax_id,
//       c_tel: data.c_tel,
//       tel: data.tel,
//       fox: data.fox,
//       bn_hours: data.bn_hours,
//       copy_info: data.copy_info,
//     }
//   }
//   if (target === 'webBascontactFormicForm') {
//     contactForm.value = {
//       mail_cc: data.mail_cc,
//       mail1: data.mail1,
//       mail2: data.mail2,
//       mail3: data.mail3,
//       q_type1: data.q_type1,
//       q_type2: data.q_type2,
//       q_type3: data.q_type3,
//       q_type4: data.q_type4,
//       q_type5: data.q_type5,
//       q_type6: data.q_type6,
//     }
//   }
//   if (target === 'customerServiceForm') {
//     customerServiceForm.value = {
//       line_show: data.line_show,
//       service_line: data.service_line,
//       fb_show: data.fb_show,
//       service_fb: data.service_fb,
//       tel_show: data.tel_show,
//       service_tel: data.service_tel,
//       mail_show: data.mail_show,
//       service_mail: data.service_mail,
//     }
//   }
//   if (target === 'communityLinkForm') {
//     communityLinkForm.value = {
//       linelink_show: data.linelink_show,
//       line_link: data.line_link,
//       utb_show: data.utb_show,
//       utb_link: data.utb_link,
//       fb_link_show: data.fb_link_show,
//       fb_link: data.fb_link,
//       ig_show: data.ig_show,
//       ig: data.ig,
//     }
//   }
//   if (target === 'communityShareForm') {
//     communityShareForm.value = {
//       line_share: data.line_share,
//       fb_share: data.fb_share,
//       fbm_share: data.fbm_share,
//     }
//   }
// }

const reset = (target) => {
  websiteForm.value = cloneData.value
  // setData(target, cloneData.value)
}

const send = async () => {
  isLoading.value = true
  try {
    const res = await updateWebsiteData({
      ...websiteForm.value,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      isLoading.value = false
      localStorage.setItem('token', res.data.new_token)
      Swal.fire({
        icon: 'success',
        title: res.data.data,
      }).then(() => {
        getData()
        admin.getTitle()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

</script>

<style lang="scss" scoped>
.serviceContainer {
  position: fixed;
  right: 20px;
  bottom: 125px;
  font-size: 35px;
  z-index: 1;
}

.serviceLine {
  width: 50px;
  height: 50px;
  position: relative;
  text-align: center;
  background: #44be29;
  border-radius: 50%;
  margin-bottom: 10px;
  opacity: 0.7;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.serviceLine:hover {
  opacity: 1;
}

.serviceFB {
  width: 50px;
  height: 50px;
  position: relative;
  text-align: center;
  background: #086ff9;
  border-radius: 50%;
  margin-bottom: 10px;
  opacity: 0.7;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.servicePhone {
  width: 50px;
  height: 50px;
  position: relative;
  text-align: center;
  background: orange;
  border-radius: 50%;
  margin-bottom: 10px;
  opacity: 0.7;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.serviceFB:hover {
  opacity: 1;
}

.serviceBtn {
  width: 50px;
  height: 45px;
  background: var(--main);
  // line-height: 45px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0.7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.serviceBtn:hover {
  opacity: 1;
}

.serviceContainer a {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.serviceContainer i {
  font-size: 35px;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>