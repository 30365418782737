<template>
  <div class="modal modal-bg">
    <div class="close-bg" @click="closeActivity"></div>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">編輯{{props.editData.name}}</h5>
          <button
            type="button"
            class="close text-white"
            data-dismiss="modal"
            @click.prevent="closeActivity"
          >
            <small aria-hidden="true">&times;</small>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <!-- <div class="form-group">
              <label>排序</label>
              <div>
                <input class="form-control col-md-6" name="item3-sort" v-model="activityForm.order" />
              </div>
            </div>-->
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>名稱
              </label>
              <input
                type="text"
                class="form-control col-md-6"
                placeholder="輸入名稱"
                v-model="activityForm.name"
              />
            </div>
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>選單
              </label>
              <select class="form-control col-md-6" v-model="activityForm.article_menu_id">
                <option
                  v-for="item in props.options"
                  :key="item.id"
                  :value="item.id"
                >{{ item.name }}</option>
              </select>
            </div>
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>篩選條件
              </label>
              <div>
                <div class="d-inline-flex align-items-center radio icheck-turquoise">
                  <input
                    type="radio"
                    name="item-3-condition"
                    id="item-3-new"
                    v-model="activityForm.filter_article"
                    value="N"
                  />
                  <label for="item-3-new" class="mx-1 mb-0">最新上架</label>
                </div>
                <div class="d-inline-flex align-items-center radio icheck-turquoise">
                  <input
                    type="radio"
                    name="item-3-condition"
                    id="item-3-popular"
                    v-model="activityForm.filter_article"
                    value="H"
                  />
                  <label for="item-3-popular" class="mx-1 mb-0">熱門程度</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>文章類型出現的篇數
              </label>
              <input
                type="text"
                class="form-control col-md-6"
                placeholder="輸入篇數"
                v-model="activityForm.article_count"
              />
            </div>
            <div class="text-center">
              <button
                @click.prevent="closeActivity"
                class="btn btn-default mr-1"
                data-dismiss="modal"
              >取消</button>
              <button @click.prevent="send" class="btn btn-primary">儲存</button>
            </div>
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue'
import Loading from '@/components/Loading.vue'
import { updateWebConfig } from '@/apis/content'
import { wrongRequest, wrongToken } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const isLoading = ref(false)

const props = defineProps({
  editData: {
    type: Object
  },
  options: {
    require: true
  }
})

const activityForm = ref({
  id: '',
  name: '',
  // order: '',
  article_menu_id: '',
  filter_article: '',
  article_count: '',
})

onMounted(() => {
  activityForm.value = JSON.parse(JSON.stringify(props.editData))
})

const send = async () => {
  isLoading.value = true
  try {
    const res = await updateWebConfig({
      id: activityForm.value.id,
      name: activityForm.value.name,
      // order: activityForm.value.order,
      article_menu_id: activityForm.value.article_menu_id,
      filter_article: activityForm.value.filter_article,
      article_count: activityForm.value.article_count,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      isLoading.value = false
      localStorage.setItem('token', res.data.new_token)
      Swal.fire({
        icon: 'success',
        title: res.data.data,
      }).then(() => {
        closeActivity()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const emit = defineEmits(['closeActivity'])
const closeActivity = () => {
  emit('closeActivity', false)
}

</script>

<style lang="scss" scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  outline: 0;
  display: block;
}
.close-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: block;
  background-color: #0000007d;
}
input {
  width: 100%;
}
.img-content {
  max-width: 500px;
  max-height: 250px;
  // width: 500px;
  // height: 250px;
  background-color: #f1f0ef;
}
</style>