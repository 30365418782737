import request from '@/utils/request';

// 優惠 - 簽到任務列表
export function getMissionList(param) {
  return request({
    url: `/checkintask/gettasklist`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 簽到任務啟用與否
export function changeMissionEnable(param) {
  return request({
    url: `/checkintask/updatetaskenable`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 簽到任務 簽到記錄列表
export function getCheckList(param) {
  return request({
    url: `/checkintask/getmembertaskrecode`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 簽到任務 發放記錄列表
export function getSentList(param) {
  return request({
    url: `/checkintask/getdistributionrecord`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 簽到任務 取得編輯資料
export function getEditData(param) {
  return request({
    url: `/checkintask/gettaskdata`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 新增簽到任務
export function createMission(param) {
  return request({
    url: `/checkintask/createtask`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 新增簽到任務
export function updateMission(param) {
  return request({
    url: `/checkintask/updatetask`,
    method: 'post',
    data: param,
  });
}