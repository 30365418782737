<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="text-right">
                <!-- <button type="button" class="btn btn-primary" onclick>金流查詢</button> -->
                <button
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="goDetailPrint(order_id)"
                >
                  <i class="ion-printer"></i> 列印
                </button>
              </div>
              <h5 class="pt-2 mb-3">訂單資訊</h5>
              <div class="table-responsive text-nowrap">
                <table class="table">
                  <tbody>
                    <tr>
                      <th style="width: 150px;">訂單成立時間</th>
                      <td>{{ orderDetail.make_at }}</td>
                    </tr>
                    <tr>
                      <th>訂單號碼</th>
                      <td>
                        {{ orderDetail.order_pk }}
                        <!-- <span class="badge badge-danger">退貨</span>
                        <span class="badge badge-danger">換貨</span>-->
                      </td>
                    </tr>
                    <tr v-show="orderDetail.o_order_pk">
                      <th>原訂單號碼</th>
                      <td>{{ orderDetail.o_order_pk }}</td>
                    </tr>
                    <tr>
                      <th>訂單狀態</th>
                      <td>
                        <span
                          class="badge badge-warning text-white"
                          v-if="orderDetail.orders_status === '未付款'"
                        >未付款</span>
                        <span
                          class="badge badge-secondary"
                          v-if="orderDetail.orders_status === '已失效'"
                        >已失效</span>
                        <span
                          class="badge badge-secondary"
                          v-if="orderDetail.orders_status === '已取消'"
                        >已取消</span>
                        <span
                          class="badge badge-danger"
                          v-if="orderDetail.orders_status === '待退款'"
                        >待退款</span>
                        <span
                          class="badge badge-secondary"
                          v-if="orderDetail.orders_status === '已退款'"
                        >已退款</span>
                        <span
                          class="badge badge-warning text-white"
                          v-if="orderDetail.orders_status === '待出貨'"
                        >待出貨</span>
                        <span
                          class="badge badge-info"
                          v-if="orderDetail.orders_status === '已出貨'"
                        >已出貨</span>

                        <span
                          class="badge badge-primary"
                          v-if="orderDetail.orders_status === '已到貨'"
                        >已到貨</span>
                        <span
                          class="badge badge-orange"
                          v-if="orderDetail.orders_status === '已完成'"
                        >已完成</span>
                        <span
                          class="badge badge-danger"
                          v-if="orderDetail.orders_status === '待退貨'"
                        >待退貨</span>
                        <span
                          class="badge badge-info"
                          v-if="orderDetail.orders_status === '已退貨'"
                        >已退貨</span>
                        <span
                          class="badge badge-info"
                          v-if="orderDetail.orders_status === '退貨取消'"
                        >退貨取消</span>
                        <span
                          class="badge badge-danger"
                          v-if="orderDetail.orders_status === '待換貨'"
                        >待換貨</span>
                        <span
                          class="badge badge-info"
                          v-if="orderDetail.orders_status === '已換貨'"
                        >已換貨</span>
                        <span
                          class="badge badge-primary"
                          v-if="orderDetail.orders_status === '已到貨'"
                        >已到貨</span>
                        <span
                          class="badge badge-secondary"
                          v-if="orderDetail.orders_status === '換貨取消'"
                        >換貨取消</span>
                      </td>
                    </tr>
                    <tr>
                      <th>變更紀錄</th>
                      <td>
                        <div
                          v-for="log in orderDetail.order_log"
                          :key="log"
                          class="text-muted"
                        >{{ log }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5 class="pt-2 mb-3">付款資訊</h5>
              <div class="table-responsive text-nowrap">
                <table class="table">
                  <tbody>
                    <tr>
                      <th style="width: 150px;">付款方式</th>
                      <td>{{ orderDetail.pay_method }}</td>
                    </tr>
                    <tr>
                      <th>付款狀態</th>
                      <td>
                        <span
                          class="badge badge-warning text-white"
                          v-if="orderDetail.pay_status ==='未付款'"
                        >未付款</span>
                        <span
                          class="badge badge-secondary"
                          v-if="orderDetail.pay_status ==='已失效'"
                        >已失效</span>
                        <span class="badge badge-info" v-if="orderDetail.pay_status ==='已付款'">已付款</span>
                        <span
                          class="badge badge-success"
                          v-if="orderDetail.pay_status ==='貨到付款'"
                        >貨到付款</span>
                        <span class="badge badge-danger" v-if="orderDetail.pay_status ==='待退款'">待退款</span>
                        <span
                          class="badge badge-secondary"
                          v-if="orderDetail.pay_status ==='已退款'"
                        >已退款</span>
                      </td>
                    </tr>
                    <tr>
                      <th>發票/折讓</th>
                      <td>{{ formatCode() }}</td>
                    </tr>
                    <tr>
                      <th>發票狀態</th>
                      <td>
                        <span
                          class="badge badge-success"
                          v-if="orderDetail.invoice_status === '開立'"
                        >開立</span>
                        <span class="badge badge-danger" v-else>作廢</span>
                      </td>
                    </tr>
                    <tr>
                      <th>發票類型</th>
                      <td>{{ orderDetail.invoice_type }} / {{ orderDetail.invoice_carrier_type }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5 class="pt-2 mb-3">顧客資訊</h5>
              <div class="table-responsive text-nowrap">
                <table class="table">
                  <tbody>
                    <tr>
                      <th style="width: 150px;">會員</th>
                      <td>
                        <div>{{ orderDetail.member_name }} {{ formatSex(orderDetail.sex) }}</div>
                        <div>{{ orderDetail.member_account }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5 class="pt-2 mb-3">送貨資訊</h5>
              <div class="table-responsive text-nowrap">
                <table class="table">
                  <tbody>
                    <tr>
                      <th style="width: 150px;">收件人姓名</th>
                      <td>{{ orderDetail.recipient_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 150px;">收件人手機號碼</th>
                      <td>{{ orderDetail.recipient_phone }}</td>
                    </tr>
                    <tr>
                      <th style="width: 150px;">訂單備註</th>
                      <td>{{ orderDetail.note }}</td>
                    </tr>
                    <tr>
                      <th style="width: 150px;">送貨方式</th>
                      <td>{{ orderDetail.shipment_method }}</td>
                    </tr>
                    <tr>
                      <th style="width: 150px;">送貨狀態</th>
                      <td>
                        <span
                          class="badge badge-warning text-white"
                          v-if="orderDetail.shipping_status ==='待出貨'"
                        >待出貨</span>
                        <span
                          class="badge badge-info"
                          v-if="orderDetail.shipping_status ==='已出貨'"
                        >已出貨</span>
                        <span
                          class="badge badge-primary"
                          v-if="orderDetail.shipping_status ==='已到貨'"
                        >已到貨</span>
                        <span
                          class="badge badge-orange"
                          v-if="orderDetail.shipping_status ==='已完成'"
                        >已完成</span>
                        <span
                          class="badge badge-danger"
                          v-if="orderDetail.shipping_status ==='待退貨'"
                        >待退貨</span>
                        <span
                          class="badge badge-info"
                          v-if="orderDetail.shipping_status ==='已退貨'"
                        >已退貨</span>
                        <span
                          class="badge badge-info"
                          v-if="orderDetail.shipping_status ==='退貨取消'"
                        >退貨取消</span>
                        <span
                          class="badge badge-danger"
                          v-if="orderDetail.shipping_status ==='待換貨'"
                        >待換貨</span>
                        <span
                          class="badge badge-info"
                          v-if="orderDetail.shipping_status ==='已換貨'"
                        >已換貨</span>
                        <span
                          class="badge badge-warning"
                          v-if="orderDetail.shipping_status ==='退貨已到貨'"
                        >退貨已到貨</span>
                        <span
                          class="badge badge-secondary"
                          v-if="orderDetail.shipping_status ==='換貨取消'"
                        >換貨取消</span>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 150px;">補充說明</th>
                      <td>
                        <div>『結帳前請詳閱』</div>
                        <div>1.如材積過大須拆單作業，運費另計。</div>
                        <div>2.訂單內若有預購及現貨商品時，會等預購商品到達後一起出貨。</div>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 150px;">收件地址</th>
                      <td>{{ orderDetail.recipient_address }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5 class="pt-2 mb-3">產品列表</h5>
              <div class="table-responsive text-nowrap">
                <table class="table table-borderless">
                  <thead class="thead-light text-center">
                    <tr>
                      <th width="50%">產品名稱 / 規格</th>
                      <th>單價</th>
                      <th>數量</th>
                      <th>小計</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border-bottom"
                      v-for="(item, index) in orderDetail.commodity_list"
                      :key="index"
                    >
                      <td>
                        <div class="table-pItem">
                          <span>{{ item.name }} / {{ item.specification_name }}</span>
                        </div>
                        <!-- <div class="table-gift ml-4">[贈品] 小時光有我 規格：無</div> -->
                      </td>
                      <td class="text-center">
                        <div class="table-pItem">NT$ {{ checkMoney(item.amount) }}</div>
                        <!-- <div class="table-gift"></div> -->
                      </td>
                      <td class="text-center">
                        <div class="d-flex justify-content-center align-items-center">
                          <div class="quentity-container">{{ item.count }}</div>
                        </div>
                        <!-- <div class="table-gift">1</div> -->
                      </td>
                      <td class="text-center">
                        <div class="table-pItem">NT${{ checkMoney(item.sum_amount) }}</div>
                        <!-- <div class="table-gift"></div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-end">
                <div class="col-12">
                  <div class="text-right">
                    <label>小計</label>
                    <span class="order_price">NT${{ checkMoney(orderDetail.subtotal) }}</span>
                  </div>
                  <div class="text-right" v-show="Number(orderDetail.full_discount_amount) !== 0">
                    <label>滿額折扣</label>
                    <span class="order_price">-NT${{ checkMoney(orderDetail.full_discount_amount) }}</span>
                  </div>
                  <div class="text-right" v-show="Number(orderDetail.bonus) !== 0">
                    <label>紅利</label>
                    <span class="order_price">-NT${{ checkMoney(orderDetail.bonus) }}</span>
                  </div>
                  <div class="text-right" v-show="Number(orderDetail.promo_code_amount) !== 0">
                    <label>折扣碼</label>
                    <span class="order_price">-NT${{ checkMoney(orderDetail.promo_code_amount) }}</span>
                  </div>
                  <div class="text-right">
                    <label class="mb-0">運費</label>
                    <span class="order_price">NT${{ checkMoney(orderDetail.shipping_amount) }}</span>
                  </div>
                  <hr class="my-2" />
                  <div class="text-right">
                    <label>合計</label>
                    <span class="order_price">NT${{ checkMoney(orderDetail.total_amount) }}</span>
                  </div>
                </div>
              </div>
              <h5 class="pt-2 mb-3" v-if="orderDetail.returned_purchase_detail.length">退貨紀錄</h5>
              <div
                class="table-responsive text-nowrap"
                v-if="orderDetail.returned_purchase_detail.length"
              >
                <table class="table table-borderless">
                  <thead class="thead-light text-center">
                    <tr>
                      <th width="50%">產品名稱 / 規格</th>
                      <th>單價</th>
                      <th>數量</th>
                      <th>小計</th>
                      <th>時間</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border-bottom"
                      v-for="(item, index) in orderDetail.returned_purchase_detail"
                      :key="index"
                    >
                      <td>
                        <div class="table-pItem">
                          <span class="mr-1">{{ item.name }} / {{ item.specification_name }}</span>
                        </div>
                        <!-- <div class="table-gift ml-4">[贈品] 小時光有我 規格：無</div> -->
                      </td>
                      <td class="text-center">
                        <div class="table-pItem">NT${{ checkMoney(item.amount) }}</div>
                        <!-- <div class="table-gift"></div> -->
                      </td>
                      <td class="text-center">
                        <div class="table-pItem">1</div>
                        <!-- <div class="table-gift">1</div> -->
                      </td>
                      <td class="text-center">
                        <div class="table-pItem">NT${{ checkMoney(item.sum_amount) }}</div>
                        <!-- <div class="table-gift"></div> -->
                      </td>
                      <td class="text-center">
                        <div class="table-pItem">{{ item.time }}</div>
                        <!-- <div class="table-gift"></div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5 class="pt-2 mb-3" v-if="orderDetail.change_purchase_detail.length">換貨紀錄</h5>
              <div
                class="table-responsive text-nowrap"
                v-if="orderDetail.change_purchase_detail.length"
              >
                <table class="table table-borderless">
                  <thead class="thead-light text-center">
                    <tr>
                      <th width="50%">產品名稱 / 規格</th>
                      <th>單價</th>
                      <th>數量</th>
                      <th>小計</th>
                      <th>時間</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border-bottom"
                      v-for="(item, index) in orderDetail.change_purchase_detail"
                      :key="index"
                    >
                      <td>
                        <div class="table-pItem">
                          <span class="mr-1">{{ item.name }} / {{ item.specification_name }}</span>
                        </div>
                        <!-- <div class="table-gift ml-4">[贈品] 小時光有我 規格：無</div> -->
                      </td>
                      <td class="text-center">
                        <div class="table-pItem">NT${{ checkMoney(item.amount) }}</div>
                        <!-- <div class="table-gift"></div> -->
                      </td>
                      <td class="text-center">
                        <div class="table-pItem">1</div>
                        <!-- <div class="table-gift">1</div> -->
                      </td>
                      <td class="text-center">
                        <div class="table-pItem">NT${{ checkMoney(item.sum_amount) }}</div>
                        <!-- <div class="table-gift"></div> -->
                      </td>
                      <td class="text-center">
                        <div class="table-pItem">{{ item.time }}</div>
                        <!-- <div class="table-gift"></div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'OrderDetail',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { getOrderDetail } from '@/apis/order'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import { checkMoney } from '@/utils/checkMoney'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  parentPage: '訂單',
  currentPage: '訂單管理',
  currentPath: '/admin/Order/list',
  childPage: '訂單詳情',
})

const isLoading = ref(false)

const orderDetail = ref({
  commodity_list: [], // 購買清單
  change_purchase_detail: [], // 換貨清單
  returned_purchase_detail: [], // 退貨清單
})

const getDetail = async () => {
  isLoading.value = true
  try {
    const res = await getOrderDetail({
      order_id: localStorage.getItem('orderId'),
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      orderDetail.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getDetail()
})

const formatNull = (item) => {
  return (!item) ? '尚未開立' : item
}

const formatCode = () => {
  return (orderDetail.value.orders_stauts === '已退貨') ? formatNull(orderDetail.value.discount_code) : formatNull(orderDetail.value.invoice_code)
}

const formatSex = (sex) => {
  return sex === '男' ? '先生' : '小姐'
}

const goDetailPrint = () => {
  window.open(`/admin/Order/print`, 'changing', 'width=1200, height=1000')
}

</script>

<style lang="scss" scoped>
</style>