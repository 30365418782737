<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <section class="mb-5" v-for="item in listData" :key="item.id">
                  <h5 class="mb-3">{{ item.name }}</h5>
                  <div class="mb-1" v-if="item.name === '生日禮'">
                    每月1號早上9點發送
                    <span
                      class="d-block d-md-inline-block ml-md-2 text-danger"
                    >使用排程作業進行發送，發放時新增一份折扣碼紀錄</span>
                  </div>
                  <hr />
                  <div class="row mb-2 mb-md-0">
                    <div class="col-6 col-md-2 col-xl-1">
                      <label class="p-md-2">折扣碼</label>
                    </div>
                    <div class="col-12 col-sm-8 col-md-6 col-lg-4">
                      <select
                        id="giftPromoCode-1"
                        class="form-control selectbs2like"
                        v-model="item.promo_code"
                      >
                        <!-- <option value="null" disabled>請選擇折扣碼</option> -->
                        <option value="null">不綁定優惠折扣</option>
                        <option
                          v-for="code in optionsList"
                          :value="code"
                          :key="code.id"
                        >{{ code.promo_code }} ({{ code.name }})</option>
                      </select>
                    </div>
                    <div class="text-right">
                      <button @click.prevent="reset" class="btn btn-default mr-1">取消</button>
                      <button @click.prevent="send(item)" class="btn btn-primary">儲存</button>
                    </div>
                  </div>
                </section>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'PromoCodeSetting',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { getPromoCode, getPromoCodeList, updatedPromoCode } from '@/apis/promoCode'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  parentPage: '優惠',
  currentPage: '系統優惠設定',
})

const isLoading = ref(false)

const listData = ref({
  data: []
})

const getPromoCodeData = async () => {
  isLoading.value = true
  try {
    const res = await getPromoCode({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const optionsList = ref([])
const getPromoCodeOptionsData = async () => {
  isLoading.value = true
  try {
    const res = await getPromoCodeList({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      optionsList.value = res.data.data.data
      localStorage.setItem('token', res.data.new_token)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getPromoCodeOptionsData()
  getPromoCodeData()
})

const reset = () => {
  getPromoCodeData()
}

const send = async (data) => {
  const promo_id = Number(data.promo_code) === 0 ? 0 : data.promo_code.id
  try {
    const res = await updatedPromoCode({
      id: data.id,
      promo_id,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      Swal.fire({
        icon: 'success',
        title: res.data.data,
      }).then(() => {
        getPromoCodeData()
      })
      localStorage.setItem('token', res.data.new_token)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

</script>

<style lang="scss" scoped>
</style>