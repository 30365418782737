<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item" @click="toggleSidebar">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars"></i>
        </a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Navbar Search -->
      <li class="nav-item" v-if="frontURL">
        <a
          :href="frontURL"
          target="_blank"
          class="nav-link"
          data-toggle="tooltip"
          data-placement="bottom"
          title="觀看前台"
        >
          <i class="fas fa-laptop"></i>
        </a>
      </li>

      <!-- Messages Dropdown Menu -->
      <li class="nav-item dropdown">
        <a
          href="/admin/User/loginRecord"
          target="_blank"
          class="nav-link"
          data-toggle="tooltip"
          data-placement="bottom"
          title="登入紀錄"
        >
          <i class="fas fa-user-circle"></i>
        </a>
      </li>
      <li class="nav-item">
        <a
          href="/admin/User/operationRecord"
          target="_blank"
          class="nav-link"
          data-toggle="tooltip"
          data-placement="bottom"
          title="操作紀錄"
        >
          <i class="fas fa-clipboard"></i>
        </a>
      </li>

      <!-- Notifications Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-user"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <small class="dropdown-item dropdown-header">您好 ! {{ alias }}</small>
          <div class="dropdown-divider"></div>
          <a href="/admin/User/list" class="dropdown-item text-sm">
            <i class="fas fa-cog mr-2"></i>帳號管理
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-sm" @click="doLogout">
            <i class="fas fa-power-off mr-2"></i>登出系統
          </a>
        </div>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
export default {
  name: 'Navbar',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { computed } from 'vue'
import { useAdminStore } from '@/store/admin'
import { frontURL } from '@/utils/baseWebURL'

const toggleSidebar = () => {
  useAdminStore().setSideBarCollapse()
}

const alias = computed(() => localStorage.getItem('alias'))

const doLogout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('reloaded')
  location.reload()
}
</script>

<style lang="scss" scoped>
.main-header {
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out !important;
}
</style>