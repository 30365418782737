<template>
  <div class="table-content">
    <table class="table w-100 text-nowrap">
      <thead class="thead-light">
        <tr>
          <th v-for="(title, index) in columns" :key="index">
            {{ title.name }}
            <template v-if="title.customHeader">
              <span v-html="title.customHeader"></span>
            </template>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-show="props.listData.data.length === 0" class="text-center">
          <td :colspan="props.columns.length">暫無資料</td>
        </tr>
        <tr v-for="(item, index) in props.listData.data" :key="index">
          <td v-for="(data, index) in props.columns" :key="index" :style="data.style">
            <template v-if="data.custom">
              <slot :name="data.customLabel" :scope="{ ...item }"></slot>
            </template>
            <template v-if="data.childLabel">{{ item[data.label][data.childLabel] }}</template>
            <template v-if="!data.custom && !data.childLabel">{{ item[data.label] }}</template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="flex-content">
    <div class="page-info">
      顯示第
      <b>{{formatPageNull(props.listData.from)}}</b> 至
      <b>{{formatPageNull(props.listData.to)}}</b> 筆結果，共
      <b>{{props.listData.total}}</b> 筆
    </div>
    <v-pagination
      v-model="page"
      :pages="props.listData.last_page"
      :range-size="1"
      active-color="#DCEDFF"
      @update:modelValue="setCurrentPage"
    />
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'

const props = defineProps({
  columns: {
    type: Array,
    required: true
  },
  listData: {
    type: Object,
    required: true
  }
})

const formatPageNull = (page) => {
  return page === null ? 0 : page
}

const page = ref(1)

const emit = defineEmits(['setCurrentPage'])
const setCurrentPage = (pageNum) => {
  emit('setCurrentPage', pageNum)
}

</script>

<style lang="scss" scoped>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
.table-content {
  overflow: auto;
  margin-bottom: 20px;
  table {
    max-width: 1600px;
  }
}
.flex-content {
  display: flex;
  justify-content: space-between;
}
.text-center {
  text-align: center;
}
</style>