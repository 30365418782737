<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form action>
                <div class="row">
                  <div class="col-12 col-sm-4">
                    <div class="row">
                      <div class="col-10">
                        <div class="form-group">
                          <label class="col-form-label">
                            <span class="text-danger">*</span>選擇會員
                          </label>
                          <v-select
                            :options="memberOptions"
                            label="label"
                            :reduce="(option) => option.id"
                            v-model="pointForm.member_id"
                          ></v-select>
                          <!-- <select class="form-control select2bs4" v-model="members">
                            <option
                              v-for="item in memberOptions"
                              :key="item.id"
                              :value="item.id"
                            >{{ item.label }}</option>
                          </select>-->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-8">
                    <div class="row justify-content-end">
                      <div class="col-sm-8">
                        <label class="col-form-label">
                          <span class="text-danger">*</span>紅利異動
                        </label>
                        <input type="text" class="form-control" placeholder="數量" v-model="amount" />
                        <div class="my-2">
                          <div class="icheck-turquoise d-inline-block mr-2 group_type">
                            <input
                              type="radio"
                              id="bouns_increase"
                              name="bouns_send_type"
                              value="increase"
                              v-model="calcType"
                            />
                            <label for="bouns_increase" class="form-check-label">贈送</label>
                          </div>
                          <div class="icheck-turquoise d-inline-block mr-2 group_type">
                            <input
                              type="radio"
                              id="bouns_decrease"
                              name="bouns_send_type"
                              value="decrease"
                              v-model="calcType"
                            />
                            <label for="bouns_decrease" class="form-check-label">扣除</label>
                          </div>
                        </div>
                        <div class>點數：{{ computedPoint }}</div>
                      </div>
                    </div>
                    <div class="row justify-content-end my-4">
                      <div class="col-sm-8 form-group">
                        <div class="form-group">
                          <label>原因</label>
                          <textarea class="form-control" rows="3" v-model="pointForm.cause"></textarea>
                        </div>
                        <div class="form-group">
                          <label>備註</label>
                          <textarea class="form-control" rows="3" v-model="pointForm.remark"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button @click.prevent="reset" class="btn btn-default mr-1">取消</button>
                  <button @click.prevent="send" class="btn btn-primary">儲存</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'BonusSend',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted, computed } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { increaseOrDecreaseAmount } from '@/apis/bonusandpoint'
import { getAllMemberList } from '@/apis/order'
import Swal from 'sweetalert2'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'

const breadCrumbs = ref({
  parentPage: '點數',
  currentPage: '紅利發放',
})

const isLoading = ref(false)

const memberOptions = ref([])
const getMemberOptions = async () => {
  isLoading.value = true
  try {
    const res = await getAllMemberList({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      memberOptions.value = res.data.data.map(obj => ({
        ...obj,
        label: `${obj.name}/${obj.cell_phone}`
      }))
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getMemberOptions()
})

const calcType = ref('increase') // 增加(發送)
const amount = ref(null)
const pointForm = ref({
  member_id: null,
  type: 'B', // B 是紅利， P 是點數
  order_num: null,
  amount: null,
  cause: null,
  remark: null,
  notify: 'Y' // 是否通知客戶
})


const computedPoint = computed(() => {
  if (calcType.value === 'increase' && amount.value) {
    return `+${amount.value}`
  } else if (calcType.value === 'decrease' && amount.value) {
    return `-${amount.value}`
  } else {
    return ''
  }
})

const send = async () => {
  isLoading.value = true
  if (calcType.value === 'increase') {
    pointForm.value.amount = amount.value
  } else {
    pointForm.value.amount = ~amount.value + 1 // 轉負數
  }
  try {
    const res = await increaseOrDecreaseAmount({
      ...pointForm.value,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      Swal.fire({
        icon: 'success',
        title: res.data.data,
      })
      localStorage.setItem('token', res.data.new_token)
      reset()
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}
const reset = () => {

  pointForm.value.member_id = null
  pointForm.value.order_num = null
  pointForm.value.amount = null
  pointForm.value.cause = null
  pointForm.value.remark = null
  amount.value = null
}

</script>

<style lang="scss" scoped>
</style>