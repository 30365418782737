<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="/admin/Dashboard" class="brand-link text-center">
      <span class="brand-text font-weight-light">
        <img src="../assets/images/logo.png" />
      </span>
    </a>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <li class="nav-header">功能列選單</li>
        <li
          class="nav-item"
          v-for="(title, index) in menuList"
          :key="index"
          :class="{ 'menu-is-opening menu-open': nowTarget === title.path }"
        >
          <a class="nav-link" :class="{ 'active': nowTarget === title.path }">
            <i class="nav-icon fas" :class="title.icon"></i>
            <p>
              {{ title.title}}
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" v-for="(item, index) in title.children" :key="index">
              <a
                :href="item.path"
                class="nav-link"
                :class="{ 'active': childTarget === item.path }"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>{{item.title}}</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </aside>
</template>

<script>
export default {
  name: 'Sidebar',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { onMounted, ref } from 'vue'
import menuData from '@/router/menu.json'
import { getMenuPermission } from '@/apis/admin'

const nowTarget = ref('')
const childTarget = ref('')

const getUrl = () => {
  const urlAry = window.location.pathname.split('/')
  nowTarget.value = urlAry[2]
  childTarget.value = window.location.pathname
}

const menuList = ref([])
const getPermission = async () => {
  try {
    const res = await getMenuPermission({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      setMenu(menuData.menu, res.data.data)
    }
  }
  catch (error) {
    console.info(error)
  }
}

const setMenu = (menu, permission) => {
  menuList.value = menu.map(item => {
    const matchingRes = permission.find(resItem => resItem.title === item.title)
    if (matchingRes) {
      const children = matchingRes.lowerMenu.map(lowerItem => {
        const matchingMenuItem = item.children.find(childItem => childItem.title === lowerItem.title)
        if (matchingMenuItem) {
          return {
            title: matchingMenuItem.title,
            path: matchingMenuItem.path
          }
        }
        return null
      }).filter(child => child !== null)

      return {
        title: matchingRes.title,
        icon: item.icon,
        path: item.path,
        children: children
      }
    } else {
      return null
    }
  }).filter(item => item !== null)
}

onMounted(() => {
  getUrl()
  getPermission()
})

</script>

<style lang="scss" scoped>
.main-sidebar {
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out !important;
  overflow-y: auto;
}
.nav-sidebar {
  margin-left: 0.4rem;
  .nav-header {
    // font-weight: 900;
    // font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
  }
  .nav-item > .nav-link p,
  .nav-link svg {
    .nav-link .active p,
    .nav-link .active svg {
      color: #343a40 !important;
    }
  }
  .menu-is-opening svg.fa-angle-left {
    transition: all 0.3s !important;
    transform: rotate(-90deg) !important;
  }
  .nav-link {
    color: #c2c7d0;
    > p > svg {
      transition: all 0.3s !important;
    }
  }
}
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-button {
  background: transparent;

  border-radius: 4px;
}

::-webkit-scrollbar-track-piece {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;

  background-color: rgba(0, 0, 0, 0.4);

  border: 1px solid slategrey;
}

::-webkit-scrollbar-track {
  box-shadow: transparent;
}
</style>