<template>
  <Editor :api-key="apiKey" :init="init" v-model="valueHtml" />
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, watch } from 'vue'
import Editor from '@tinymce/tinymce-vue'
import { uploadImageAll } from '@/apis/webcontent'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const props = defineProps({
  data: {
    required: true
  },
  target: {
    required: true
  },
})

const valueHtml = ref('')

watch(() => props.data, (nV, oV) => {
  if (nV) {
    valueHtml.value = props.data
  }
}, {
  deep: true
})

const apiKey = reactive('dx6fmftljibe6g6r410yfy82dp66z74pq447p9nefmxkyl8e')
const init = reactive({
  language: 'zh_TW',
  height: 500,
  plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
  editimage_cors_hosts: ['picsum.photos'],
  menubar: 'file edit view insert format tools table help',
  toolbar: 'code | undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
  toolbar_sticky: true,
  images_upload_handler: (blobInfo, success, failure) => {
    handleImgUpload(blobInfo, success, failure);
  },
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
})

const handleImgUpload = async (blobInfo, success, failure) => {
  const formData = new FormData();
  formData.append('file', blobInfo.blob())
  formData.append('type', props.target)
  formData.append('token', localStorage.getItem('token'))
  try {
    const res = await uploadImageAll(formData)
    if (res.data.code === 0) {
      success(res.data.data)
      localStorage.setItem('token', res.data.new_token)
    } else if (!res.data.new_token) {
      wrongToken(res.data.message)
    } else {
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const emit = defineEmits(['changeData'])

watch(() => valueHtml, (nV, oV) => {
  if (nV) {
    emit('changeData', props.target, valueHtml)
  }
}, {
  deep: true
})

</script>