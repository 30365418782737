<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form class="mb-3">
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <label>訂單成立時間</label>
                      <div class="input-daterange input-group" id="date-range">
                        <input
                          type="date"
                          class="form-control"
                          placeholder="起日"
                          v-model="searchForm.make_start_at"
                        />
                        <span class="input-group-text rounded-0">～</span>
                        <input
                          type="date"
                          class="form-control"
                          placeholder="迄日"
                          v-model="searchForm.make_end_at"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <label>出貨日期</label>
                      <input
                        type="date"
                        class="form-control"
                        placeholder="出貨日期"
                        v-model="searchForm.shipping_at"
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-3 mb-2 mb-md-0">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="姓名｜手機號碼 | Email"
                        v-model="searchForm.member_data"
                      />
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 mb-2 mb-md-0">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="訂單號碼"
                        v-model="searchForm.order_number"
                      />
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 mb-2 mb-md-0">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="發票號碼"
                        v-model="searchForm.invoice_code"
                      />
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 mb-2 mb-md-0">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="折讓單號碼"
                        v-model="searchForm.discount_code"
                      />
                    </div>
                  </div>
                </fieldset>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <select class="form-control selectbs2like" v-model="searchForm.order_status">
                        <option disabled value="null">--選擇訂單狀態--</option>
                        <option
                          v-for="(item, value) in paymentStatusOptions"
                          :key="value"
                          :value="item.value"
                        >{{ item.label }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <select
                        class="form-control selectbs2like"
                        v-model="searchForm.shipping_status"
                      >
                        <option disabled value="null">--選擇出貨狀態--</option>
                        <option
                          v-for="(item, value) in ShippingStatusOptions"
                          :key="value"
                          :value="item.value"
                        >{{ item.label }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <select class="form-control selectbs2like" v-model="searchForm.pay_status">
                        <option disabled value="null">--選擇付款狀態--</option>
                        <option
                          v-for="(item, value) in orderStatusOptions"
                          :key="value"
                          :value="item.value"
                        >{{ item.label }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5 p-0">
                  <button class="btn btn-primary mr-1" @click.prevent="getList()">搜尋</button>
                  <button class="btn btn-default" @click.prevent="reset">清空</button>
                </div>
              </form>

              <button class="btn btn-primary mr-1" @click="goHref('/admin/Order/add')">新增訂單</button>
              <button class="btn btn-primary" @click.prevent="getDownloadKey">
                <i class="fas fa-file-excel mr-1"></i>匯出
              </button>
            </div>

            <div class="p-3">
              <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                <template #make_at="{ scope }">{{ formatTimestamp(scope.make_at) }}</template>
                <template #order_number="{ scope }">
                  <a
                    class="text-blue"
                    @click.prevent="_goDetail(scope.order_id)"
                  >{{ scope.order_number }}</a>
                </template>
                <template #pay_amount="{ scope }">{{ checkMoney(scope.pay_amount) }} 元</template>
                <template #invoice_code="{ scope }">{{ formatCode(scope) }}</template>
                <template #pay_status="{ scope }">
                  <div v-html="formatPayStatus(scope.pay_status)"></div>
                </template>
                <template #shipment_status="{ scope }">
                  <div v-html="formatShipmentStatus(scope.orders_status)"></div>
                </template>
                <template #do_shipment="{ scope }">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    v-if="scope.orders_status === '6' && (scope.pay_status === '3' || scope.pay_status === '4')"
                    @click.prevent="_openShipping(scope.order_id)"
                  >進行出貨</button>
                  <!-- <button type="button" class="btn btn-sm btn-primary confirm_ship">到貨確認</button> -->
                  <!-- <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    onclick="window.open('/admin/Order/change_num', 'change_num','width=1000, height=800')"
                    v-if="scope.orders_status === '7' && (scope.pay_status === '3' || scope.pay_status === '4')"
                  >變更單號</button>-->
                </template>
                <!-- <template #return_goods="{ scope }">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    v-if="scope.orders_status === '8' && (scope.pay_status === '3' || scope.pay_status === '4')"
                    @click.prevent="_openReturning(scope.order_id)"
                  >進行退貨</button>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary confirm_return"
                    v-if="scope.orders_status === '10' && (scope.pay_status === '3' || scope.pay_status === '4')"
                    @click.prevent="_returnungConfirm(scope.order_id)"
                  >退貨確認</button>
                  <button
                    type="button"
                    class="btn btn-sm btn-danger cancel_return"
                    v-if="scope.orders_status === '10' && (scope.pay_status === '3' || scope.pay_status === '4')"
                    @click.prevent="_returnungCancel(scope.order_id)"
                  >取消退貨</button>
                </template>
                <template #return_pay="{ scope }">
                  <button
                    type="button"
                    class="btn btn-sm btn-danger refund"
                    v-if="scope.orders_status === '11' && scope.pay_status === '6'"
                    @click.prevent="_payCancel(scope.order_id, scope.pay_amount)"
                  >退款確認</button>
                </template>
                <template #change_goods="{ scope }">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    onclick="window.open('/admin/Order/changing', 'changing','width=1000, height=800')"
                    v-if="scope.orders_status === '8' && (scope.pay_status === '3' || scope.pay_status === '4')"
                    @click.prevent="_openChanging(scope.order_id)"
                  >進行換貨</button>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary confirm_change"
                    v-if="scope.orders_status === '13' && (scope.pay_status === '3' || scope.pay_status === '4')"
                    @click.prevent="_changingConfirm(scope.order_id)"
                  >換貨確認</button>
                  <button
                    type="button"
                    class="btn btn-sm btn-danger cancel_change"
                    v-if="scope.orders_status === '13' && (scope.pay_status === '3' || scope.pay_status === '4')"
                    @click.prevent="_changingCancel(scope.order_id)"
                  >取消換貨</button>
                </template>
                <template #cancel_order="{ scope }">
                  <button
                    type="button"
                    class="btn btn-sm btn-danger refund"
                    v-if="scope.orders_status === '6' && (scope.pay_status === '3' || scope.pay_status === '4')"
                    @click.prevent="_orderCancel(scope.order_id, scope.pay_amount)"
                  >取消訂單</button>
                </template>-->
                <template #order_note="{ scope }">
                  <i
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="scope.order_note"
                    class="far fa-sticky-note"
                  ></i>
                </template>
                <template #detail_print="{ scope }">
                  <a class="btn-table" @click.prevent="_goDetailPrint(scope.order_id)">
                    <i class="ion-printer"></i>
                  </a>
                </template>
              </DataTable>
            </div>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script>
export default {
  name: 'OrderList',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { baseURL } from '@/utils/baseWebURL'
import { paymentStatusOptions, ShippingStatusOptions, orderStatusOptions } from "@/views/Order/options"
import { getOrderList, returningCheck, returningCancel, payCancel, changeCheck, changeCancel, orderCancel } from '@/apis/order'
import { getExcel } from '@/apis/report'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import { checkMoney } from '@/utils/checkMoney'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  parentPage: '訂單',
  currentPage: '訂單管理',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '訂單成立時間',
    label: 'make_at',
    customLabel: 'make_at',
    custom: true
  },
  {
    name: '訂單號碼',
    label: 'order_number',
    customLabel: 'order_number',
    custom: true
  },
  {
    name: '姓名',
    label: 'member_name',
  },
  {
    name: '金額',
    label: 'pay_amount',
    customLabel: 'pay_amount',
    custom: true
  },
  {
    name: '發票 / 折讓',
    label: 'invoice_code',
    customLabel: 'invoice_code',
    custom: true
  },
  {
    name: '付款狀態',
    label: 'pay_status',
    customLabel: 'pay_status',
    custom: true
  },
  {
    name: '出貨',
    label: 'shipment_status',
    customLabel: 'shipment_status',
    custom: true
  },
  {
    name: '出貨處理',
    label: 'do_shipment',
    customLabel: 'do_shipment',
    custom: true
  },
  // {
  //   name: '退貨處理',
  //   label: 'return_goods',
  //   customLabel: 'return_goods',
  //   custom: true
  // },
  // {
  //   name: '退款處理',
  //   label: 'return_pay',
  //   customLabel: 'return_pay',
  //   custom: true
  // },
  // {
  //   name: '換貨處理',
  //   label: 'change_goods',
  //   customLabel: 'change_goods',
  //   custom: true
  // },
  // {
  //   name: '訂單',
  //   label: '',
  //   customLabel: '',
  //   custom: true
  // },
  {
    name: '訂單處理',
    label: 'cancel_order',
    customLabel: 'cancel_order',
    custom: true
  },
  {
    name: '訂單備註',
    label: 'order_note',
    customLabel: 'order_note',
    custom: true
  },
  {
    name: '列印',
    label: 'detail_print',
    customLabel: 'detail_print',
    custom: true
  },
])

const goHref = (href) => {
  document.location.href = href
}

const formatDate = (target) => {
  const date = new Date(target)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

const searchForm = ref({
  make_start_at: '', // 訂單成立開始時間，格式ex: '2023-01-01'
  make_end_at: '', // 訂單成立結束時間，格式ex: '2023-01-20'
  shipping_at: '', // 出貨日期，格式ex: '2023-12-10'
  member_data: null, // 會員名稱 | 電話 | 信箱
  order_number: null, // 訂單號碼
  invoice_code: null, // 發票號碼
  discount_code: null, // 折讓單號碼
  order_status: null, // 訂單狀態
  shipping_status: null, // 出貨狀態
  pay_status: null, // 付款狀態
})

const reset = () => {
  searchForm.value = {
    make_start_at: '',
    make_end_at: '',
    shipping_at: '',
    member_data: null,
    order_number: null,
    invoice_code: null,
    discount_code: null,
    order_status: null,
    shipping_status: null,
    pay_status: null,
  }
  getList()
}

const listData = ref({
  data: []
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  const searchData = {}
  Object.entries(searchForm.value).forEach(([key, value]) => {
    if (value !== '' && value !== null) {
      searchData[key] = value
    }
  })
  try {
    const res = await getOrderList({
      ...searchData,
      page,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

onMounted(() => {
  getList()
})

const formatTimestamp = (unix) => {
  if (!unix) return ''
  const date = new Date(unix * 1000)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const dateString = `${year}-${month}-${day}`
  return dateString
}

const formatCode = (data) => {
  return (data.orders_stauts === '11') ? formatNull(data.discount_code) : formatNull(data.invoice_code)
}

const formatNull = (item) => {
  return (!item) ? '尚未開立' : item
}

const formatPayStatus = (status) => {
  if (status === '1') {
    return `<span class="badge badge-warning text-white">未付款</span>`
  } else if (status === '2') {
    return `<span class="badge badge-secondary">已失效</span>`
  } else if (status === '3') {
    return `<span class="badge badge-info">已付款</span>`
  } else if (status === '4') {
    return `<span class="badge badge-success">貨到付款</span>`
  } else if (status === '5') {
    return `<span class="badge badge-secondary">已取消</span>`
  } else if (status === '6') {
    return `<span class="badge badge-danger">待退款</span>`
  } else if (status === '7') {
    return `<span class="badge badge-secondary">已退款</span>`
  }
}

const formatShipmentStatus = (status) => {
  if (status === '1') {
    return `<span class="badge badge-warning text-white">未付款</span>`
  } else if (status === '2') {
    return `<span class="badge badge-secondary">已失效</span>`
  } else if (status === '3') {
    return `<span class="badge badge-secondary">已取消</span>`
  } else if (status === '4') {
    return `<span class="badge badge-danger">待退款</span>`
  } else if (status === '5') {
    return `<span class="badge badge-secondary">已退款</span>`
  } else if (status === '6') {
    return `<span class="badge badge-warning text-white">待出貨</span>`
  } else if (status === '7') {
    return `<span class="badge badge-info">已出貨</span>`
  } else if (status === '8') {
    return `<span class="badge badge-primary">已到貨</span>`
  } else if (status === '9') {
    return `<span class="badge badge-orange">已完成</span>`
  } else if (status === '10') {
    return `<span class="badge badge-danger">待退貨</span>`
  } else if (status === '11') {
    return `<span class="badge badge-info">已退貨</span>`
  } else if (status === '12') {
    return `<span class="badge badge-info">退貨取消</span>`
  } else if (status === '13') {
    return `<span class="badge badge-danger">待換貨</span>`
  } else if (status === '14') {
    return `<span class="badge badge-info">已換貨</span>`
  } else if (status === '15') {
    return `<span class="badge badge-warning">退貨已到貨</span>`
  } else if (status === '16') {
    return `<span class="badge badge-secondary">換貨取消</span>`
  }
}

// 進行出貨
const _openShipping = (order_id) => {
  localStorage.setItem('orderId', order_id)
  window.open(`/admin/Order/shipping`, 'shipping', 'width=1000, height=800')
}

// 進行退貨
const _openReturning = (order_id) => {
  localStorage.setItem('orderId', order_id)
  window.open(`/admin/Order/returning`, 'shipping', 'width=1000, height=800')
}

// 退貨確認
const _returnungConfirm = (order_id) => {
  Swal.fire({
    icon: "warning",
    title: "確認完成退貨？",
    html: `<p class="text-danger">若您已完成退貨，系統會將信用卡款項自動退刷，若是其他付款方式則請管理員「確認退款」</p>`,
    confirmButtonText: "確認",
    cancelButtonText: `<span style="color: #444444; ">取消</span>`,
    confirmButtonColor: '#007BFF',
    cancelButtonColor: '#dddddd',
    showCancelButton: true,
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true
      try {
        const res = await returningCheck({
          order_id,
          token: localStorage.getItem('token')
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          isLoading.value = false
          Swal.fire({
            icon: 'success',
            title: '退貨成功'
          })
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          isLoading.value = false
          wrongRequest(res.data)
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
    }
  })
}

// 取消退貨
const _returnungCancel = (order_id, amount) => {
  Swal.fire({
    icon: "warning",
    title: "確認取消退貨？",
    html: `<p class="text-danger">退貨單將變更為取消狀態</p>`,
    confirmButtonText: "確認",
    cancelButtonText: `<span style="color: #444444; ">取消</span>`,
    confirmButtonColor: '#fe5b52',
    cancelButtonColor: '#dddddd',
    showCancelButton: true,
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true
      try {
        const res = await returningCancel({
          order_id,
          token: localStorage.getItem('token')
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          isLoading.value = false
          Swal.fire({
            icon: 'success',
            title: '取消退貨成功'
          })
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          isLoading.value = false
          wrongRequest(res.data)
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
    }
  })
}

// 退款確認
const _payCancel = (order_id, amount) => {
  Swal.fire({
    icon: "error",
    title: `<div>確定完成退款？</div><div class='text-danger'>送出後退貨單將變更為退款完成狀態</div>`,
    html: `<div class="form-group">
             <div>退款金額：NT${checkMoney(amount)} 元</div>
           </div>`,
    inputLabel: '輸入備註',
    input: 'textarea',
    confirmButtonText: "確認",
    cancelButtonText: `<span style="color: #444444; ">取消</span>`,
    confirmButtonColor: '#007BFF',
    cancelButtonColor: '#dddddd',
    showCancelButton: true,
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true
      try {
        const res = await payCancel({
          order_id,
          note: result.value,
          token: localStorage.getItem('token')
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          isLoading.value = false
          Swal.fire({
            icon: 'success',
            title: '退款成功'
          })
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          isLoading.value = false
          wrongRequest(res.data)
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
    }
  })
}

// 進行換貨
const _openChanging = (order_id) => {
  localStorage.setItem('orderId', order_id)
  window.open(`/admin/Order/changing`, 'shipping', 'width=1000, height=800')
}

// 換貨確認
const _changingConfirm = (order_id) => {
  Swal.fire({
    icon: "warning",
    title: "請再次確認是否已經完成換貨",
    html: `<p class="text-danger">若您已完成換貨，系統將會開始計算鑑賞期，並於結束過後進行結束訂單</p>`,
    confirmButtonText: "確認",
    cancelButtonText: `<span style="color: #444444; ">取消</span>`,
    confirmButtonColor: '#007BFF',
    cancelButtonColor: '#dddddd',
    showCancelButton: true,
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true
      try {
        const res = await changeCheck({
          order_id,
          token: localStorage.getItem('token')
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          isLoading.value = false
          Swal.fire({
            icon: 'success',
            title: '完成換貨'
          })
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          isLoading.value = false
          wrongRequest(res.data)
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
    }
  })
}

// 取消換貨
const _changingCancel = (order_id) => {
  Swal.fire({
    icon: "warning",
    title: "確定取消退貨？",
    html: `<p class="text-danger">退貨單將變更為取消狀態</p>`,
    confirmButtonText: "確認",
    cancelButtonText: `<span style="color: #444444; ">取消</span>`,
    confirmButtonColor: '#fe5b52',
    cancelButtonColor: '#dddddd',
    showCancelButton: true,
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true
      try {
        const res = await changeCancel({
          order_id,
          token: localStorage.getItem('token')
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          isLoading.value = false
          Swal.fire({
            icon: 'success',
            title: '取消退貨成功'
          })
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          isLoading.value = false
          wrongRequest(res.data)
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
    }
  })
}

// 取消訂單
const _orderCancel = (order_id, amount) => {
  Swal.fire({
    icon: "error",
    title: "確定進行訂單取消？",
    html: `<div class="form-group">
             <div>退款金額：NT${checkMoney(amount)} 元</div>
           </div>`,
    inputLabel: '輸入備註',
    input: 'textarea',
    confirmButtonText: "確認",
    cancelButtonText: `<span style="color: #444444; ">取消</span>`,
    confirmButtonColor: '#fe5b52',
    cancelButtonColor: '#dddddd',
    showCancelButton: true,
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true
      try {
        const res = await orderCancel({
          order_id,
          note: result.value,
          token: localStorage.getItem('token')
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          isLoading.value = false
          Swal.fire({
            icon: 'success',
            title: '訂單取消成功'
          })
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          isLoading.value = false
          wrongRequest(res.data)
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
    }
  })
}

// 訂單詳情
const _goDetail = (order_id) => {
  localStorage.setItem('orderId', order_id)
  location.href = `/admin/Order/detail`
}

// 訂單列印
const _goDetailPrint = (order_id) => {
  localStorage.setItem('orderId', order_id)
  window.open(`/admin/Order/print`, 'changing', 'width=1200, height=1000')
}

const getDownloadKey = async () => {
  isLoading.value = true
  const searchData = {}
  Object.entries(searchForm.value).forEach(([key, value]) => {
    if (value !== '' && value !== null) {
      searchData[key] = value
    }
  })
  try {
    const res = await getOrderList({
      ...searchData,
      page: 1,
      limit: 9999,
      get_excls_url: 'Y',
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      exportExcel(res.data.data)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const exportExcel = async (key) => {
  const res = await getExcel(key)
  if (res) {
    window.open(`${baseURL}exclsPost?key=${key}`)
  }
}

</script>

<style lang="scss" scoped>
table {
  text-align: center;
}
a {
  cursor: pointer;
}
</style>