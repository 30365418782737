<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <!-- <div class="col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <div class="form-group">
                      <label class="d-block">銷售狀態</label>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="sale_status"
                          id="product_available"
                          value="Y"
                          v-model="searchForm.has_stock"
                        />
                        <label for="product_available" class="mx-1 mb-0">現貨</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="sale_status"
                          id="product_soldout"
                          value="N"
                          v-model="searchForm.has_stock"
                        />
                        <label for="product_soldout" class="mx-1 mb-0">售完</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="sale_status"
                          id="soldout_all"
                          value
                          v-model="searchForm.has_stock"
                        />
                        <label for="soldout_all" class="mx-1 mb-0">全選</label>
                      </div>
                    </div>
                  </div>-->
                  <!-- <div class="col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <div class="form-group">
                      <label class="d-block">贈品使用</label>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="gift_status"
                          id="gift_active"
                          value="Y"
                          v-model="searchForm.use_gift"
                        />
                        <label for="gift_active" class="mx-1 mb-0">開放</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="gift_status"
                          id="gift_notActive"
                          value="N"
                          v-model="searchForm.use_gift"
                        />
                        <label for="gift_notActive" class="mx-1 mb-0">鎖定</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="gift_status"
                          id="gift_All"
                          value
                          v-model="searchForm.use_gift"
                        />
                        <label for="gift_All" class="mx-1 mb-0">全選</label>
                      </div>
                    </div>
                  </div>-->
                  <div class="col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <div class="form-group">
                      <label class="d-block">產品狀態</label>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="product_status"
                          id="product_active"
                          value="Y"
                          v-model="searchForm.enable"
                        />
                        <label for="product_active" class="mx-1 mb-0">啟用</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="product_status"
                          id="product_notActive"
                          value="N"
                          v-model="searchForm.enable"
                        />
                        <label for="product_notActive" class="mx-1 mb-0">停用</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input
                          type="radio"
                          name="product_status"
                          id="product_all"
                          value
                          v-model="searchForm.enable"
                        />
                        <label for="product_all" class="mx-1 mb-0">全選</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row form-group">
                  <div class="col-sm-12 col-md-4 col-lg-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="產品名稱"
                      v-model="searchForm.name"
                    />
                  </div>
                  <!-- <div class="col-sm-12 col-md-4 col-lg-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="貨號/規格"
                      v-model="searchForm.specification_name"
                    />
                  </div>-->
                </div>
                <!-- <div class="row form-group">
                  <div class="col-12 col-md-8 col-lg-6">
                    <label class="d-block">贈品活動</label>
                    <div class="input-daterange input-group" id="date-range">
                      <input
                        type="date"
                        class="form-control day_start"
                        name="date_start"
                        placeholder="起日"
                        v-model="searchForm.activity_start_at"
                      />
                      <span class="input-group-text rounded-0">～</span>
                      <input
                        type="date"
                        class="form-control day_end"
                        name="date_end"
                        placeholder="迄日"
                        v-model="searchForm.activity_end_at"
                      />
                    </div>
                  </div>
                </div>-->
                <div class="row mb-4">
                  <div class="col-sm-12 col-md-5">
                    <button class="btn btn-primary mr-1" @click.prevent="getList()">搜尋</button>
                    <button class="btn btn-default" @click.prevent="reset">清空</button>
                  </div>
                </div>
              </form>
              <div class="mb-1">
                <button class="btn btn-primary mb-3" @click="addItem">新增產品</button>
              </div>

              <div>
                <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                  <!-- <template #is_gift_use="{ scope }">
                    <span class="badge badge-primary" v-show="scope.is_gift_use === 'Y'">開放</span>
                    <span class="badge badge-warning" v-show="scope.is_gift_use === 'N'">鎖定</span>
                  </template>-->
                  <template #specification="{ scope }">
                    <div v-for="item in scope.specification" :key="item.id">
                      <span class="badge badge-primary" v-show="item.enable === 'Y'">上架中</span>
                      <span class="badge badge-warning" v-show="item.enable === 'N'">下架中</span>
                      規格：
                      <span class="text-blue">{{ item.specification_name }}</span>
                      <span class="mx-1" style="color: #d9d9d9;">/</span>
                      貨號：
                      <span class="text-blue">{{ item.commodities_number }}</span>
                    </div>
                  </template>
                  <template #selling_price="{ scope }">
                    <div
                      v-for="item in scope.specification"
                      :key="item.id"
                    >${{ item.selling_price }}</div>
                  </template>
                  <template #stock="{ scope }">
                    <div v-for="item in scope.specification" :key="item.id">
                      <a class="text-blue" @click.prevent="toStockRecord(scope.id)">{{ item.stock }}</a>
                    </div>
                  </template>
                  <template #enable="{ scope }">
                    <Toggle
                      v-model="scope.enable"
                      falseValue="N"
                      trueValue="Y"
                      @change="changeEnable(scope.id, scope.enable)"
                    />
                  </template>
                  <!-- <template #gift_lang="{ scope }">
                    <div v-html="scope.gift_lang"></div>
                  </template>-->
                  <template #edit="{ scope }">
                    <a class="btn-table edit ml-auto ml-0" @click="editItem(scope.id)">
                      <i class="ion-edit"></i>
                    </a>
                  </template>
                  <template #copy="{ scope }">
                    <a class="btn-table copy" @click="copyItem(scope.id)">
                      <i class="ion-ios-copy"></i>
                    </a>
                  </template>
                  <template #delete="{ scope }">
                    <a class="btn-table delete" @click.prevent="deleteItem(scope.id, scope.name)">
                      <i class="ion-ios-trash"></i>
                    </a>
                  </template>
                </DataTable>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script>
export default {
  name: 'ProductList',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { getProductList, changeProductEnable, copyProduct, deleteProduct } from '@/apis/product'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'

const breadCrumbs = ref({
  parentPage: '產品',
  currentPage: '產品管理',
})

const isLoading = ref(false)

const columns = ref([
  // {
  //   name: '贈品使用',
  //   label: 'is_gift_use',
  //   customLabel: 'is_gift_use',
  //   custom: true
  // },
  {
    name: '產品名稱',
    label: 'name'
  },
  {
    name: '貨號 / 規格',
    label: 'specification',
    customLabel: 'specification',
    custom: true
  },
  {
    name: '售價',
    label: 'selling_price',
    customLabel: 'selling_price',
    custom: true
  },
  {
    name: '庫存',
    label: 'stock',
    customLabel: 'stock',
    custom: true
  },
  // {
  //   name: '贈品活動',
  //   label: 'activity_at'
  // },
  // {
  //   name: '贈品',
  //   label: 'gift_lang',
  //   customLabel: 'gift_lang',
  //   custom: true
  // },
  {
    name: '上下架狀態',
    label: 'enable',
    customLabel: 'enable',
    custom: true
  },
  {
    name: '編輯',
    label: 'edit',
    customLabel: 'edit',
    custom: true
  },
  {
    name: '複製',
    label: 'copy',
    customLabel: 'copy',
    custom: true
  },
  {
    name: '刪除',
    label: 'delete',
    customLabel: 'delete',
    custom: true
  },
])
const listData = ref({
  data: []
})

// const formatDate = (dateString) => {
//   const [year, month, day] = dateString.split('-')
//   const date = new Date(year, month - 1, day)
//   const unixTimestamp = date.getTime()
//   const unixTimestampSeconds = Math.floor(unixTimestamp / 1000)
//   return unixTimestampSeconds
// }

const searchForm = ref({
  // has_stock: '', // 銷售狀態 預設全選
  // use_gift: '', // 贈品使用 預設全選
  enable: '', // 產品狀態 預設全選
  name: null, // 產品名稱 選填
  // specification_name: null, // 貨號 選填  
  activity_start_at: null, // 活動開始時間 Unix timestamp 格式 選填
  activity_end_at: null, // 活動結束時間 Unix timestamp 格式 選填
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  const searchData = {}
  // if (searchForm.value.has_stock) {
  //   searchData.has_stock = searchForm.value.has_stock
  // }
  // if (searchForm.value.use_gift) {
  //   searchData.use_gift = searchForm.value.use_gift
  // }
  if (searchForm.value.enable) {
    searchData.enable = searchForm.value.enable
  }
  if (searchForm.value.name) {
    searchData.name = searchForm.value.name
  }
  // if (searchForm.value.specification_name) {
  //   searchData.specification_name = searchForm.value.specification_name
  // }
  // if (searchForm.value.activity_start_at) {
  //   searchData.activity_start_at = formatDate(searchForm.value.activity_start_at)
  // }
  // if (searchForm.value.activity_end_at) {
  //   searchData.activity_end_at = formatDate(searchForm.value.activity_end_at)
  // }
  try {
    const res = await getProductList({
      ...searchData,
      page,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

onMounted(() => {
  getList()
})

const addItem = () => {
  location.href = `/admin/Product/add`
}

const toStockRecord = (id) => {
  localStorage.setItem('specificationId', id)
  window.open('/admin/Product/stockRecord')
}

const changeEnable = async (id, enable) => {
  isLoading.value = true
  try {
    const res = await changeProductEnable({
      commodity_id: id,
      enable,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      getList(pageNum.value)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const editItem = (id) => {
  localStorage.setItem('commoditiesId', id)
  location.href = `/admin/Product/edit`
}

const copyItem = async (id) => {
  isLoading.value = true
  try {
    const res = await copyProduct({
      id,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: res.data.data,
      }).then(() => {
        getList(pageNum.value)
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const reset = () => {
  searchForm.value = {
    // has_stock: '',
    // use_gift: '',
    enable: '',
    name: null,
    // specification_name: null,
    // activity_start_at: null,
    // activity_end_at: null,
  }
  getList()
}

const deleteItem = async (id, name) => {
  Swal.fire({
    icon: "error",
    title: `確定刪除「${name}」？`,
    text: "刪除後將不可還原",
    confirmButtonText: "確認",
    cancelButtonText: "取消",
    confirmButtonColor: '#fe5b52',
    cancelButtonColor: '#dddddd',
    showCancelButton: true,
    reverseButtons: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true
      try {
        const res = await deleteProduct({
          commodity_id: id,
          token: localStorage.getItem('token')
        })
        if (res.data.code === 0) {
          localStorage.setItem('token', res.data.new_token)
          getList()
        } else if (!res.data.new_token) {
          isLoading.value = false
          wrongToken(res.data.message)
        } else {
          isLoading.value = false
          wrongRequest(res.data)
        }
      }
      catch (error) {
        console.info(error)
        isLoading.value = false
        Swal.fire({
          icon: 'error',
          title: error,
        })
      }
    }
  })
}

</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>