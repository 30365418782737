
import request from '@/utils/request';

// 優惠 取得折扣碼資料
export function getPromoCode(param) {
  return request({
    url: `/promoCode/config`,
    method: 'post',
    data: param,
  });
}

// 優惠 取得折扣碼列表
export function getPromoCodeList(param) {
  return request({
    url: `/promoCode/list`,
    method: 'post',
    data: param,
  });
}

// 優惠 取得折扣碼列表
export function changePromoCodeEnable(param) {
  return request({
    url: `/promoCode/editStatus`,
    method: 'post',
    data: param,
  });
}

// 優惠 更新折扣碼資料
export function updatedPromoCode(param) {
  return request({
    url: `/promoCode/configUpdate`,
    method: 'post',
    data: param,
  });
}

// 優惠 新增折扣碼
export function createPromoCode(param) {
  return request({
    url: `/promoCode/create`,
    method: 'post',
    data: param,
  });
}

// 優惠 取得折扣碼資料
export function getPromoCodeConfig(param) {
  return request({
    url: `/promoCode/getEditData`,
    method: 'post',
    data: param,
  });
}

// 優惠 修改折扣碼
export function editPromoCode(param) {
  return request({
    url: `/promoCode/editData`,
    method: 'post',
    data: param,
  });
}

// 優惠 取得折扣贈送商品
export function getGitfcommodity(param) {
  return request({
    url: `/promoCode/getgitfcommodity`,
    method: 'post',
    data: param,
  });
}

// 優惠 刪除折扣碼
export function deletePromoCode(param) {
  return request({
    url: `/promoCode/delpromo`,
    method: 'post',
    data: param,
  });
}