<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-8 col-lg-3"></div>
              </div>
              <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                <template #name="{ scope }">{{ scope.operator.alias }}</template>
                <template #account="{ scope }">{{ scope.operator.name }}</template>
                <template #method="{ scope }">
                  <span class="badge badge-info text-white" v-show="scope.method === 'C'">新增</span>
                  <span class="badge badge-warning text-white" v-show="scope.method === 'U'">更新</span>
                </template>
                <template #origin="{ scope }">
                  <vue-json-pretty :data="{ key: scope.origin }" />
                </template>
                <template #diff="{ scope }">
                  <vue-json-pretty :data="{ key: scope.diff }" />
                </template>
              </DataTable>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'OperationRecord',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
// import { baseURL } from '@/utils/baseWebURL'
import { getLogList } from '@/apis/log'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

const breadCrumbs = ref({
  currentPage: '操作紀錄',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '姓名',
    label: 'name',
    customLabel: 'name',
    custom: true
  },
  {
    name: '帳號',
    label: 'account',
    customLabel: 'account',
    custom: true
  },
  {
    name: '異動',
    label: 'method',
    customLabel: 'method',
    custom: true
  },
  {
    name: '原始資料',
    label: 'origin',
    customLabel: 'origin',
    custom: true,
    style: 'max-width: 600px; width: 600px; overflow-y: auto;'
  },
  {
    name: '改動資料',
    label: 'diff',
    customLabel: 'diff',
    custom: true,
    style: 'max-width: 600px; width: 600px; overflow-y: auto;'
  },
  // {
  //   name: '來源IP',
  //   label: 'ip',
  //   customLabel: 'ip',
  //   custom: true
  // },
  {
    name: '操作時間',
    label: 'updated_at',
  }
])

const listData = ref({
  data: []
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  try {
    const res = await getLogList({
      page,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      isLoading.value = false
      localStorage.setItem('token', res.data.new_token)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

onMounted(() => {
  getList()
})

</script>

<style lang="scss" scoped>
</style>