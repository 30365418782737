import request from '@/utils/request';

// 通知，取得 Email、簡訊、Line 通知列表
export function getNotifyList(param) {
  return request({
    url: `/notify/notifyrecord`,
    method: 'post',
    data: param,
  });
}

// 通知，取得簡訊額度
export function getSmsAmount(param) {
  return request({
    url: `/notify/getsmsamount`,
    method: 'post',
    data: param,
  });
}

// 通知，取得通知相關設定
export function getNotifyConfig(param) {
  return request({
    url: `/notify/notifyconfig`,
    method: 'post',
    data: param,
  });
}

// 通知，更新通知相關設定
export function updateNotifyConfig(param) {
  return request({
    url: `/notify/notifyconfigupdate`,
    method: 'post',
    data: param,
  });
}