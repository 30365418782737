<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h5 class="mt-2 mb-3">任務設定</h5>
              <div class="text-danger">簽到任務期間不會發放任何獎勵，於任務結束後隔日早上09:00統一發放。</div>
              <hr />
              <form>
                <div class="mb-5">
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pt-2 pb-3 mb-0">
                        <span class="text-danger">*</span>任務名稱
                      </label>
                    </div>
                    <div class="col-md-9">
                      <input
                        type="text"
                        class="form-control col-md-6"
                        placeholder="請輸入名稱"
                        required
                        v-model="missionForm.name"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-2">
                        <label class="pt-2 pb-3 mb-0">
                          <span class="text-danger">*</span>開始 / 結束日期
                        </label>
                      </div>
                      <div class="col-md-9">
                        <div class="input-daterange input-group px-0 col-md-6" id="date-range">
                          <input
                            type="date"
                            class="form-control"
                            placeholder="開始日期"
                            required
                            v-model="missionForm.start_day"
                            :readonly="route.path.indexOf('add') < 0"
                          />
                          <span class="input-group-text rounded-0">～</span>
                          <input
                            type="date"
                            class="form-control"
                            placeholder="結束日期"
                            required
                            v-model="missionForm.end_day"
                            :readonly="route.path.indexOf('add') < 0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-2">
                        <label class="pt-2 pb-3 mb-0">可簽到次數</label>
                      </div>
                      <div class="col-md-9">
                        <div class="pt-lg-2">
                          <span class="mr-3">{{ canCheckInDay }}</span>
                          <span class="text-danger pl-3">可以簽到次數需大於或等於簽到次數</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 class="mt-2">獎勵設定</h5>
                <p class="text-danger">可設定不同的門檻產生不同的贈送，以最高門檻級別獎勵贈送</p>
                <hr />
                <ul class="nav nav-pills mb-3" id="custom-content-below-tab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="gift-tab1"
                      data-toggle="pill"
                      href="#gift-content1"
                      role="tab"
                      aria-controls="gift-content1"
                      aria-selected="true"
                    >
                      <span class="text-danger">*</span>獎勵種類1
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="gift-tab2"
                      data-toggle="pill"
                      href="#gift-content2"
                      role="tab"
                      aria-controls="gift-content2"
                      aria-selected="true"
                    >
                      <span class="text-danger">*</span>獎勵種類2
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="gift-tab3"
                      data-toggle="pill"
                      href="#gift-content3"
                      role="tab"
                      aria-controls="gift-content3"
                      aria-selected="true"
                    >
                      <span class="text-danger">*</span>獎勵種類3
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="gift-tab4"
                      data-toggle="pill"
                      href="#gift-content4"
                      role="tab"
                      aria-controls="gift-content4"
                      aria-selected="true"
                    >
                      <span class="text-danger">*</span>獎勵種類4
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="gift-tab5"
                      data-toggle="pill"
                      href="#gift-content5"
                      role="tab"
                      aria-controls="gift-content5"
                      aria-selected="true"
                    >
                      <span class="text-danger">*</span>獎勵種類5
                    </a>
                  </li>
                </ul>
                <form>
                  <div class="tab-content" id="custom-content-below-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="gift-content1"
                      role="tabpanel"
                      aria-labelledby="gift-content1"
                      data-tab-index="1"
                    >
                      <div class="row form-group">
                        <div class="col-md-2">
                          <label class="pt-2 pb-3 mb-0">
                            <span class="text-danger">*</span>獎勵種類
                          </label>
                        </div>
                        <div class="col-md-9">
                          <div class="form-group">
                            <label>
                              <span class="text-danger">*</span>簽到次數
                            </label>
                            <input
                              type="text"
                              class="form-control col-6"
                              placeholder="必須簽到次數"
                              v-model="missionForm.award_has_day_1"
                              :readonly="route.path.indexOf('add') < 0"
                            />
                          </div>
                          <p class="text-danger">折扣碼可以選取多個作為贈品，紅利及點數各自輸入數字作為贈品。</p>
                          <div class="form-group">
                            <label class="mb-0">折扣碼</label>
                            <p class="text-danger">若同樣的折扣碼需要送出多個，請選取兩次以上。</p>
                            <div class="giftPromoCode-container">
                              <div class="giftPromoCode-item">
                                <div
                                  v-for="(select, selectIndex) in promoCode_stage[0]"
                                  :key="select"
                                >
                                  <select
                                    id="giftPromoCode1"
                                    class="form-control"
                                    style="width:50%"
                                    v-model="promoCode_stage[0][selectIndex]"
                                    :disabled="route.path.indexOf('add') < 0"
                                  >
                                    <option value disabled selected>請選擇折扣碼</option>
                                    <option
                                      v-for="item in promoCodeOptions"
                                      :key="item.id"
                                      :value="item"
                                    >{{ item.promo_code }} ({{ item.name }})</option>
                                  </select>
                                  <a
                                    class="text-danger mt-1 delete-giftPromoCode"
                                    @click.prevent="removeSelect(1, selectIndex)"
                                    v-if="!route.path.indexOf('add') < 0"
                                  >- 移除折扣碼</a>
                                  <hr class="ml-0" style="width: 50%" />
                                </div>
                              </div>
                            </div>
                            <div class="mb-2">
                              <a
                                class="mb-2 add-gift-promoCode"
                                @click="addSelect(1)"
                                v-if="!route.path.indexOf('add') < 0"
                              >+ 贈送折扣碼</a>
                            </div>
                          </div>
                          <div class="form-group d-flex">
                            <div class="col-lg-3 pl-0">
                              <label>紅利</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="請輸入紅利"
                                v-model="missionForm.award_bonus_1"
                                :readonly="route.path.indexOf('add') < 0"
                              />
                            </div>
                            <div class="col-lg-3 pr-0">
                              <label>點數</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="請輸入點數"
                                v-model="missionForm.award_point_1"
                                :readonly="route.path.indexOf('add') < 0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      role="tabpanel"
                      :id="`gift-content${i}`"
                      :aria-labelledby="`gift-tab${i}`"
                      v-for="i in formIndex"
                      :key="i"
                    >
                      <div class="row form-group">
                        <div class="col-md-2">
                          <label class="pt-2 pb-3 mb-0">
                            <span class="text-danger">*</span>獎勵種類
                          </label>
                        </div>
                        <div class="col-md-9">
                          <div class="form-group">
                            <label>
                              <span class="text-danger">*</span>簽到次數
                            </label>
                            <input
                              type="text"
                              class="form-control col-6"
                              placeholder="必須簽到次數"
                              v-model="missionForm[`award_has_day_${i}`]"
                              :readonly="route.path.indexOf('add') < 0"
                            />
                          </div>
                          <p class="text-danger">折扣碼可以選取多個作為贈品，紅利及點數各自輸入數字作為贈品。</p>
                          <div class="form-group">
                            <label class="mb-0">折扣碼</label>
                            <p class="text-danger">若同樣的折扣碼需要送出多個，請選取兩次以上。</p>
                            <div class="giftPromoCode-container">
                              <div class="giftPromoCode-item">
                                <div
                                  v-for="(select, selectIndex) in promoCode_stage[i-1]"
                                  :key="select"
                                >
                                  <select
                                    :id="`giftPromoCode${i}-1`"
                                    class="form-control"
                                    style="width:50%"
                                    v-model="promoCode_stage[i-1][selectIndex]"
                                    :disabled="route.path.indexOf('add') < 0"
                                  >
                                    <option value disabled selected>請選擇折扣碼</option>
                                    <option
                                      v-for="item in promoCodeOptions"
                                      :key="item.id"
                                      :value="item"
                                    >{{ item.promo_code }} ({{ item.name }})</option>
                                  </select>
                                  <a
                                    class="text-danger mt-1 delete-giftPromoCode"
                                    @click.prevent="removeSelect(i, selectIndex)"
                                    v-if="!route.path.indexOf('add') < 0"
                                  >- 移除折扣碼</a>
                                  <hr class="ml-0" style="width: 50%" />
                                </div>
                              </div>
                            </div>
                            <div class="mb-2">
                              <a
                                class="mb-2 add-gift-promoCode"
                                @click="addSelect(i)"
                                v-if="!route.path.indexOf('add') < 0"
                              >+ 贈送折扣碼</a>
                            </div>
                          </div>
                          <div class="form-group d-flex">
                            <div class="col-lg-3 pl-0">
                              <label>紅利</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="請輸入紅利"
                                v-model="missionForm[`award_bonus_${i}`]"
                                :readonly="route.path.indexOf('add') < 0"
                              />
                            </div>
                            <div class="col-lg-3 pr-0">
                              <label>點數</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="請輸入點數"
                                v-model="missionForm[`award_point_${i}`]"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="text-center">
                  <button class="btn btn-default mr-1" @click.prevent="cancel">取消</button>
                  <button class="btn btn-primary" @click.prevent="dealAwardPromo">儲存</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'MissionEdit',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted, computed } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { getEditData, createMission, updateMission } from '@/apis/mission'
import { getPromoCodeList } from '@/apis/promoCode'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const breadCrumbs = ref({
  parentPage: '優惠',
  currentPage: '簽到任務管理',
  currentPath: '/admin/Discount/Mission/list',
  childPage: '新增簽到任務',
})

const isLoading = ref(false)

const checkTitle = () => {
  breadCrumbs.value.childPage = location.href.indexOf('add') > 0 ? '新增簽到任務' : '編輯簽到任務'
}

const canCheckInDay = computed(() => {
  if (missionForm.value.end_day !== '' && missionForm.value.start_day !== '') {
    return dayjs(missionForm.value.end_day).diff(missionForm.value.start_day, 'days') + 1
  } else {
    return 0
  }
})

const getData = async () => {
  isLoading.value = true
  try {
    const res = await getEditData({
      id: localStorage.getItem('missionId'),
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      setEditData(res.data.data)
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const promoCodeOptions = ref([])
const getPromoCodeOptions = async () => {
  isLoading.value = true
  try {
    const res = await getPromoCodeList({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      promoCodeOptions.value = res.data.data.data.map(item => {
        return {
          id: item.id,
          name: item.name,
          promo_code: item.promo_code
        }
      })
      localStorage.setItem('token', res.data.new_token)
      if (location.href.indexOf('add') < 0) {
        getData()
      } else {
        isLoading.value = false
      }
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  checkTitle()
  getPromoCodeOptions()
})

const missionForm = ref({
  name: '',
  start_day: '', // ex 2023-04-01
  end_day: '', //
  // award_promo: [],
  award_has_day_1: null,
  award_bonus_1: null,
  award_point_1: null,
  award_has_day_2: null,
  award_bonus_2: null,
  award_point_2: null,
  award_has_day_3: null,
  award_bonus_3: null,
  award_point_3: null,
  award_has_day_4: null,
  award_bonus_4: null,
  award_point_4: null,
  award_has_day_5: null,
  award_bonus_5: null,
  award_point_5: null,
})

const formIndex = ref([2, 3, 4, 5])
const promoCode_stage = ref([
  [{},],
  [{},],
  [{},],
  [{},],
  [{},],
])

const addSelect = (stage) => {
  promoCode_stage.value[stage - 1].push({})
}

const removeSelect = (stage, index) => {
  promoCode_stage.value[stage - 1].splice(index, 1)
}

const setEditData = (data) => {
  missionForm.value = data
  promoCode_stage.value = [[], [], [], [], []]
  for (let i in data.award_promo) {
    const item = data.award_promo[i]
    const index = item.stage - 1
    promoCode_stage.value[index].push({
      id: item.promo_id,
      name: item.promo_data.name,
      promo_code: item.promo_data.promo_code,
    })
  }
}

// 處理 獎勵層級中的折扣碼資料
const dealAwardPromo = () => {
  const filteredData = promoCode_stage.value.map(arr => arr.filter(item => Object.keys(item).length > 0))
  filteredData.forEach((sublist, index) => {
    if (sublist.length > 0) {
      missionForm.value[`award_promo_${index + 1}`] = sublist.map(item => item.id)
    }
  })
  for (const key in missionForm.value) {
    if (missionForm.value[key] === null || missionForm.value[key] === "") {
      delete missionForm.value[key]
    }
  }
  delete missionForm.value.award_promo
  send()
}

const send = async () => {
  isLoading.value = true
  try {
    const res = (location.href.indexOf('add') > 0)
      ? await createMission({ // 新增
        ...missionForm.value,
        token: localStorage.getItem('token'),
      })
      : await updateMission({ // 修改
        ...missionForm.value,
        token: localStorage.getItem('token'),
      })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: `${breadCrumbs.value.childPage}成功`,
      }).then(() => {
        cancel()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const cancel = () => {
  router.push(breadCrumbs.value.currentPath)
}

</script>

<style lang="scss" scoped>
</style>