<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <h6 class="mb-3 font-weight-bold">SEO設定</h6>
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 125px; " class="align-middle">SiteMap</th>
                      <td>
                        <div class="text-pink">sitemap.xml</div>
                        <!-- FIXME: api 功能串接 -->
                        <button type="button" class="btn btn-sm btn-primary mt-2" onclick>更新SiteMap</button>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 125px; " class="align-middle">標題</th>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          value
                          placeholder="請輸入標題"
                          v-model="seoForm.title"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 125px; " class="align-middle">關鍵字</th>
                      <td>
                        <vue3-tags-input
                          :tags="setTags"
                          placeholder="輸入後請按Enter以隔開字串"
                          @on-tags-changed="handleChangeTag"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 125px; ">描述</th>
                      <td>
                        <!-- FIXME: 網站描述是哪個參數啊？ -->
                        <textarea
                          id="site_description"
                          rows="3"
                          class="form-control"
                          placeholder="請輸入網站描述"
                        ></textarea>
                      </td>
                    </tr>
                  </table>
                </div>
                <hr class="my-4" />
                <h6 class="mb-3 font-weight-bold">追蹤碼</h6>
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 125px; ">Google Analysis</th>
                      <td>
                        <textarea
                          name
                          id
                          rows="3"
                          class="form-control"
                          placeholder="請輸入HTML語法<script>alert('Hi');</script>"
                          v-model="seoForm.google_analysis"
                        ></textarea>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 125px; ">Google Analysis GA4</th>
                      <td>
                        <textarea
                          name
                          id
                          rows="3"
                          class="form-control"
                          placeholder="請輸入HTML語法<script>alert('Hi');</script>"
                          v-model="seoForm.google_analysis_ga4"
                        ></textarea>
                      </td>
                    </tr>
                  </table>
                </div>
                <hr class="my-4" />
                <h6 class="mb-3 font-weight-bold">
                  全網站插入程式碼
                  <div class="small mt-2 text-danger">請注意！語法將造成官網無法使用，請務必確認語法的正確性。</div>
                </h6>
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 125px; ">Head內</th>
                      <td>
                        <textarea
                          name
                          id
                          rows="3"
                          class="form-control"
                          placeholder="請輸入HTML語法<script>alert('Hi');</script>"
                          v-model="seoForm.head"
                        ></textarea>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 125px; ">Body最上方</th>
                      <td>
                        <textarea
                          name
                          id
                          rows="3"
                          class="form-control"
                          placeholder="請輸入HTML語法<script>alert('Hi');</script>"
                          v-model="seoForm.body_up"
                        ></textarea>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 125px; ">Body最下方</th>
                      <td>
                        <textarea
                          name
                          id
                          rows="3"
                          class="form-control"
                          placeholder="請輸入HTML語法<script>alert('Hi');</script>"
                          v-model="seoForm.down"
                        ></textarea>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="text-center">
                  <button type="reset" class="btn btn-default" @click.prevent="cancel">取消</button>
                  <button type="submit" class="btn btn-primary" @click.prevent="send">儲存</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'SystemSitemap',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import Vue3TagsInput from 'vue3-tags-input'
import { getSeoSitemap, updateSeoSitemap } from '@/apis/system'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  parentPage: '系統',
  currentPage: 'SEO SiteMap',
})

const isLoading = ref(false)

const seoForm = ref({
  body_down: null,
  body_up: null,
  created_at: "2023-04-24",
  describe: null,
  google_analysis: null,
  google_analysis_ga4: null,
  head: null,
  id: 1,
  key_word: null,
  title: null,
  updated_at: "2023-04-24"
})
const cloneSeoForm = ref({})

const getConfig = async () => {
  try {
    const res = await getSeoSitemap({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      // FIXME: 確定一下資料回傳 array 是否是正常的
      seoForm.value = res.data.data[0]
      formatKeyWord(seoForm.value.key_word)
      cloneSeoForm.value = JSON.parse(JSON.stringify(res.data.data[0]))
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getConfig()
})

const formatKeyWord = (string) => {
  setTags.value = string.split(',')
}

const setTags = ref([])
const handleChangeTag = (tags) => {
  setTags.value = tags
}

const cancel = () => {
  seoForm.value = cloneSeoForm.value
}

const send = async () => {
  seoForm.value.key_word = setTags.value.join(', ')
  seoForm.value.token = localStorage.getItem('token')
  try {
    const res = await updateSeoSitemap({ ...seoForm.value })
    if (res.data.data) {
      localStorage.setItem('token', res.data.new_token)
      Swal.fire({
        icon: 'success',
        title: res.data.data
      }).then(() => {
        location.reload()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false

      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

</script>

<style lang="scss" scoped>
.v3ti::v-deep {
  min-height: 38px;
  padding-top: 2px;
  border: 1px solid #ced4da;
  .v3ti-tag {
    background-color: #64b0f2;
    span {
      height: 18px;
      padding-left: 4px;
    }
  }
}
</style>