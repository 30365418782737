<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-content">
                <table class="table w-100 text-nowrap">
                  <thead class="thead-light">
                    <tr>
                      <th>名稱</th>
                      <th>最後更新時間</th>
                      <th>狀態</th>
                      <th>編輯</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in configList" :key="index">
                      <td>{{ item.name }}</td>
                      <td>{{ item.updated_at }}</td>
                      <td>
                        <Toggle
                          v-model="item.enable"
                          falseValue="N"
                          trueValue="Y"
                          @change="changeEnable(item.id, item.enable)"
                        />
                      </td>
                      <td>
                        <a
                          href="javascript:;"
                          class="btn-table edit ml-auto ml-0"
                          @click="editItem(item)"
                        >
                          <i class="ion-edit"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- 圖片輪播 -->
  <ImgSlider v-if="showImgSlider" @closeImgSlide="closeImgSlide" />
  <!-- 首頁看板 -->
  <HomeBoard v-if="showHomeBoard" :editData="edtiData" @closeHomeBoard="closeHomeBoard" />
  <!-- 限時活動(產品) -->
  <ProductActivity
    v-if="showProductActivity"
    :editData="edtiData"
    :options="productOptions"
    @closeActivity="closeProductActivity"
  />
  <!-- 各種活動 -->
  <Activity
    v-if="showActivity"
    :editData="edtiData"
    :options="menuOptions"
    @closeActivity="closeActivity"
  />
  <!-- 圖片橫幅 -->
  <Advertise v-if="showAdvertise" :editData="edtiData" @closeAdvertise="closeAdvertise" />

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'IndexList',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import ImgSlider from '@/views/Content/index/components/imgSlider.vue'
import HomeBoard from '@/views/Content/index/components/homeBoard.vue'
import ProductActivity from '@/views/Content/index/components/productActivity.vue'
import Activity from '@/views/Content/index/components/activity.vue'
import Advertise from '@/views/Content/index/components/advertise.vue'
import { getWebConfig, updateWebConfigEnable } from '@/apis/content'
import { getProductOptions, getMenuOptions } from '@/apis/webcontent'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'

const breadCrumbs = ref({
  parentPage: '網站設定',
  currentPage: '首頁管理',
})

onMounted(() => {
  getProducts()
  getMenu()
  getConfig()
})

const isLoading = ref(false)

const menuOptions = ref([])
const getMenu = async () => {
  isLoading.value = true
  try {
    const res = await getMenuOptions({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      menuOptions.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}
const productOptions = ref([])
const getProducts = async () => {
  isLoading.value = true
  try {
    const res = await getProductOptions({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      productOptions.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}
const configList = ref({})

const getConfig = async () => {
  isLoading.value = true
  try {
    const res = await getWebConfig({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      configList.value = res.data.data
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const changeEnable = async (id, enable) => {
  isLoading.value = true
  try {
    const res = await updateWebConfigEnable({
      id,
      enable,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      getConfig()
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const editItem = (data) => {
  if (data.id === 1) { // 圖片輪播
    showImgSlider.value = true
  }
  if (data.id === 2) { // 首頁看板
    edtiData.value = {
      id: data.id,
      board_img_url_left: data.board_img_url_left,
      board_title_left: data.board_title_left,
      board_sec_title_left: data.board_sec_title_left,
      board_img_url_right: data.board_img_url_right,
      board_title_right: data.board_title_right,
      board_sec_title_right: data.board_sec_title_right,
    }
    showHomeBoard.value = true
  }
  if (data.id === 3) { // 限時活動(產品)
    edtiData.value = {
      id: data.id,
      name: data.name,
      // order: data.order,
      article_menu_id: data.article_menu_id,
      filter_article: data.filter_article,
      article_count: data.article_count,
    }
    showProductActivity.value = true
  }
  if (data.id === 4 || data.id === 6) { // 各類活動
    edtiData.value = {
      id: data.id,
      name: data.name,
      // order: data.order,
      article_menu_id: data.article_menu_id,
      filter_article: data.filter_article,
      article_count: data.article_count,
    }
    showActivity.value = true
  }
  if (data.id === 5) { // 廣告橫幅-1
    edtiData.value = {
      id: data.id,
      name: data.name,
      // order: data.order,
      url: data.ad_url_1,
      ad_img_url: data.ad_img_url_1,
    }
    showAdvertise.value = true
  }
  if (data.id === 7) { // 廣告橫幅-2
    edtiData.value = {
      id: data.id,
      name: data.name,
      // order: data.order,
      url: data.ad_url_2,
      ad_img_url: data.ad_img_url_2,
    }
    showAdvertise.value = true
  }
}

const showImgSlider = ref(false)
const closeImgSlide = () => {
  showImgSlider.value = false
  getConfig()
}

const edtiData = ref({})

const showHomeBoard = ref(false)
const closeHomeBoard = () => {
  showHomeBoard.value = false
  getConfig()
}

const showProductActivity = ref(false)
const closeProductActivity = () => {
  showProductActivity.value = false
  getConfig()
}

const showActivity = ref(false)
const closeActivity = () => {
  showActivity.value = false
  getConfig()
}

const showAdvertise = ref(false)
const closeAdvertise = () => {
  showAdvertise.value = false
  getConfig()
}

</script>

<style lang="scss" scoped>
.btn-table i {
  text-align: center;
  margin: 0;
  font-size: 14px;
  border: 1px solid #eceeef;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: rgba(43, 61, 81, 0.7);
  border-radius: 3px;
  display: inline-block;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>