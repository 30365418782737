<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row mb-3">
                  <div class="col-sm-8 col-md-6 col-lg-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="姓名 | 手機號碼 | Email"
                      v-model="searchForm.search"
                    />
                  </div>
                  <div class="col-sm-4">
                    <button class="btn btn-primary mr-1" @click.prevent="getList()">搜尋</button>
                    <button class="btn btn-default" @click.prevent="reset">清空</button>
                  </div>
                </div>
              </form>
              <div class="mt-3 mb-2">
                <button type="button" class="btn btn-primary mr-1" @click="addItem">新增會員</button>
                <button class="btn btn-primary" @click.prevent="getDownloadKey">
                  <i class="fas fa-file-excel mr-1"></i>匯出
                </button>
              </div>

              <div>
                <DataTable :columns="columns" :listData="listData" @setCurrentPage="setCurrentPage">
                  <template #register_type="{ scope }">{{ formatRegisterType(scope.register_type) }}</template>
                  <template #register_icon="{ scope }">
                    <i class="fab fa-facebook my-1" v-show="scope.facebook"></i>
                    <i class="fab fa-google my-1" v-show="scope.google"></i>
                    <i class="fab fa-line my-1" v-show="scope.line"></i>
                    <i
                      class="far fa-envelope my-1"
                      v-show="!scope.facebook && !scope.google && !scope.line"
                    ></i>
                  </template>
                  <template #name="{ scope }">
                    <a
                      class="member-link"
                      @click="toMemberDetail(scope.id)"
                    >{{ formatNull(scope.name) }}</a>
                  </template>
                  <template #cell_phone="{ scope }">{{ formatNull(scope.cell_phone) }}</template>
                  <template
                    #shopping_last="{ scope}"
                  >{{ formatShoppingLast(scope.shopping_information.shopping_last) }}</template>
                  <template #enable="{ scope } ">
                    <Toggle
                      v-model="scope.enable"
                      falseValue="N"
                      trueValue="Y"
                      @change="changeEnable(scope.id, scope.enable)"
                    />
                  </template>
                  <template #edit="{ scope } ">
                    <a
                      href="javascript:;"
                      class="btn-table edit ml-auto ml-0"
                      @click="editItem(scope)"
                    >
                      <i class="ion-edit"></i>
                    </a>
                  </template>
                </DataTable>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Edit v-if="isEdit" :editData="editData" @closeEdit="closeEdit" />
  </Transition>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'MemberList',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import { useMemberStore } from '@/store/member'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Edit from '@/views/Member/edit.vue'
import DataTable from '@/dashboard/DataTable.vue'
import Loading from '@/components/Loading.vue'
import { baseURL } from '@/utils/baseWebURL'
import { getMemberList, updateMemberEnable, getMemberData } from '@/apis/member'
import { getExcel } from '@/apis/report'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'

const breadCrumbs = ref({
  parentPage: '會員',
  currentPage: '會員管理',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '註冊來源',
    label: 'register_type',
    customLabel: 'register_type',
    custom: true
  },
  {
    name: '註冊類型',
    label: 'register_icon',
    customLabel: 'register_icon',
    custom: true
  },
  {
    name: '姓名',
    label: 'name',
    customLabel: 'name',
    custom: true
  },
  {
    name: '手機號碼',
    label: 'cell_phone',
    customLabel: 'cell_phone',
    custom: true
  },
  {
    name: '電子郵件',
    label: 'account'
  },
  {
    name: '現有紅利',
    label: 'bonus_total'
  },
  {
    name: '現有點數',
    label: 'point_total'
  },
  {
    name: '總累積消費',
    label: 'shopping_information',
    childLabel: 'shopping_total'
  },
  {
    name: '平均消費',
    label: 'shopping_information',
    childLabel: 'shopping_avg'
  },
  {
    name: '累積訂單',
    label: 'shopping_information',
    childLabel: 'shopping_count'
  },
  {
    name: '前次消費時間',
    label: 'shopping_information',
    childLabel: 'shopping_last',
    customLabel: 'shopping_last',
    custom: true
  },
  {
    name: '註冊時間',
    label: 'created_at'
  },
  {
    name: '狀態',
    label: 'enable',
    customLabel: 'enable',
    custom: true
  },
  {
    name: '編輯',
    label: 'edit',
    customLabel: 'edit',
    custom: true
  }
])

const listData = ref({
  data: []
})

onMounted(() => {
  getList()
})

const searchForm = ref({
  search: null,
})

const reset = () => {
  searchForm.value = {
    search: null
  }
  getList()
}

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  try {
    const res = await getMemberList({
      ...searchForm.value,
      page,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      isLoading.value = false
      localStorage.setItem('token', res.data.new_token)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

const formatNull = (item) => {
  return (!item) ? '-' : item
}

const formatRegisterType = (type) => {
  return type === 'S'
    ? '客服'
    : type === 'F'
      ? '電腦版網站'
      : type === 'FF'
        ? '手機版網站'
        : '-'
}

const formatShoppingLast = (string) => {
  return string ? string : '尚未消費'
}

const changeEnable = async (id, enable) => {
  isLoading.value = true
  try {
    const res = await updateMemberEnable({
      id,
      enable,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      getList(pageNum.value)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const toMemberDetail = async (id) => {
  const member = useMemberStore()
  try {
    const res = await getMemberData({
      id,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      // member.setmemberDetail(res.data.data)
      localStorage.setItem('memberId', id)
      document.location.href = '/admin/Member/detail'
    } else if (!res.data.new_token) {
      wrongToken(res.data.message)
    } else {
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const isEdit = ref(false)
const editData = ref({})

const addItem = () => {
  isEdit.value = true
}

const editItem = (data) => {
  editData.value = data
  isEdit.value = true
}

const closeEdit = () => {
  isEdit.value = false
  editData.value = {}
  getList()
}

const getDownloadKey = async () => {
  isLoading.value = true
  const searchData = {}
  Object.entries(searchForm.value).forEach(([key, value]) => {
    if (value !== '' && value !== null) {
      searchData[key] = value
    }
  })
  try {
    const res = await getMemberList({
      ...searchData,
      page: 1,
      limit: 9999,
      get_excls_url: 'Y',
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      exportExcel(res.data.data)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const exportExcel = async (key) => {
  const res = await getExcel(key)
  if (res) {
    window.open(`${baseURL}exclsPost?key=${key}`)
  }
}

</script>

<style lang="scss" scoped>
.my-1 {
  margin: 0 2px;
}
.table-content {
  overflow: auto;
}
.member-link {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #0056b3;
    text-decoration: none;
  }
}
.btn-table i {
  text-align: center;
  margin: 0;
  font-size: 14px;
  border: 1px solid #eceeef;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: rgba(43, 61, 81, 0.7);
  border-radius: 3px;
  display: inline-block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 0;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>