<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form id="newOrder">
                <h5 class="mb-3">
                  <span class="text-danger">*</span>選擇欲修改之訂單
                </h5>
                <hr />
                <section class="row">
                  <div class="form-group col-12 col-sm-6 col-md-3 mb-2 mb-md-0">
                    <label>會員</label>
                    <v-select
                      :options="memberOptions"
                      label="name"
                      :reduce="(option) => option.id"
                      v-model="searchForm.member_id"
                    ></v-select>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-md-3 mb-2 mb-md-0">
                    <label class="d-block">訂單單號</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="訂單單號"
                      v-model="searchForm.return_num"
                    />
                  </div>
                  <div class="form-group col-12 col-sm-6 col-md-3 mb-2 mb-md-0">
                    <label class="d-block">收件人姓名</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="收件人姓名"
                      v-model="searchForm.recipient_name"
                    />
                  </div>
                  <div class="form-group col-12 col-sm-6 col-md-3 mb-2 mb-md-0">
                    <label class="d-block">收件人電話</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="收件人電話"
                      v-model="searchForm.recipient_phone"
                    />
                  </div>
                  <div class="w-full" style="display:flex; justify-content: center;">
                    <div class="text-center my-3">
                      <button class="btn btn-default mr-1" @click.prevent="reset">清空</button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        @click.prevent="getList()"
                        data-toggle="modal"
                        data-target="#order-content"
                      >搜尋訂單</button>
                    </div>
                  </div>
                </section>

                <div class="modal fade" id="order-content" tabindex="-1">
                  <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                      <div class="modal-header bg-primary text-white">
                        <h5 class="modal-title">欲操作訂單</h5>
                        <button
                          type="button"
                          class="close text-white"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <DataTable
                          :columns="columns"
                          :listData="listData"
                          @setCurrentPage="setCurrentPage"
                        >
                          <template #make_at="{ scope }">{{ formatTimestamp(scope.make_at) }}</template>
                          <template #member_name="{ scope }">{{ formatNull(scope.member.name) }}</template>
                          <template
                            #delivery="{ scope }"
                          >{{ formatNull(scope.member.delivery_name) }} / {{ formatNull(scope.member.delivery_phone) }}</template>
                          <template #amount="{ scope }">{{ checkMoney(scope.amount) }} 元</template>
                          <template #edit="{ scope }">
                            <button
                              type="button"
                              class="btn btn-sm btn-primary cancel_change mx-1"
                              @click.prevent="setOrder(scope, 'C')"
                              data-toggle="modal"
                              data-target="#order-content"
                            >換貨</button>
                            <button
                              type="button"
                              class="btn btn-sm btn-danger cancel_change mx-1"
                              @click.prevent="setOrder(scope, 'B')"
                              data-toggle="modal"
                              data-target="#order-content"
                            >退貨</button>
                          </template>
                        </DataTable>
                      </div>
                      <div class="modal-footer"></div>
                    </div>
                  </div>
                </div>

                <h5 class="mt-5" v-if="targetOrder">
                  <span v-show="returnForm.type">{{ formatType(returnForm.type) }}</span>處理
                </h5>
                <hr />

                <div class="form-group" v-if="targetOrder">
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <th style="width: 150px;">訂單編號</th>
                        <td>{{ targetOrder.order_pk }}</td>
                      </tr>
                      <tr>
                        <th style="width: 150px;">會員姓名</th>
                        <td>{{ formatNull(targetOrder.member.name) }}</td>
                      </tr>
                      <tr>
                        <th style="width: 150px;">收件人姓名</th>
                        <td>{{ formatNull(targetOrder.member.delivery_name) }}</td>
                      </tr>
                      <tr>
                        <th style="width: 150px;">收件人手機號碼</th>
                        <td>{{ formatNull(targetOrder.member.delivery_phone) }}</td>
                      </tr>
                      <tr>
                        <th style="width: 150px;">欲{{ formatType(returnForm.type) }}品項</th>
                      </tr>
                    </tbody>
                  </table>

                  <div class="table-responsive mb-4">
                    <table id="product-table" class="table table-borderless text-nowrap">
                      <thead class="thead-light text-center">
                        <tr>
                          <th></th>
                          <th width="50%">產品名稱 / 規格</th>
                          <th>單價</th>
                          <th>數量</th>
                          <th>小計</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="border-bottom"
                          :class="{ 'checked': item.checked }"
                          v-for="(item, index) in targetOrder.order_product_details"
                          :key="index"
                          @click="checkProduct(item)"
                        >
                          <td>
                            <input type="checkbox" v-model="item.checked" />
                          </td>
                          <td>
                            <div class="table-pItem">
                              <span
                                class="mr-1"
                              >{{ item.commodity_data.name }} 規格：{{ item.commodity_spec_data.specification_name }}</span>
                            </div>
                            <!-- <div class="table-pItem">
                              <span
                                class="mr-1"
                              >{{ item.commodities_name }} 規格：{{ item.specification_name }}</span>
                            </div>
                            <div
                              class="table-gift ml-4"
                              v-if="item.gift"
                            >[贈品] {{ item.gift.name }} 規格：{{ item.gift.specification_name }} * {{ item.gift.count }}</div>-->
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">NT${{ checkMoney(item.amount) }}</div>
                            <div class="table-gift"></div>
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">
                              <div class="d-flex justify-content-center align-items-center">
                                <div class="quentity-container">{{ item.count }}</div>
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">NT${{ checkMoney(item.amount * item.count) }}</div>
                            <div class="table-gift"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <table class="table table-borderless" v-if="returnForm.type=== 'B'">
                    <tbody>
                      <tr>
                        <th style="width: 250px;">
                          退回金額
                          <span class="text-red">(若無須返還請填 0)</span>
                        </th>
                        <td>
                          <input type="number" v-model="returnForm.return_amount" />
                        </td>
                      </tr>
                      <tr>
                        <th style="width: 250px;">
                          點數返還
                          <span class="text-red">(若無須返還請填 0)</span>
                        </th>
                        <td>
                          <input type="number" v-model="returnForm.return_point" />
                        </td>
                      </tr>
                      <tr>
                        <th style="width: 250px;">
                          紅利返還
                          <span class="text-red">(若無須返還請填 0)</span>
                        </th>
                        <td>
                          <input type="number" v-model="returnForm.return_bonus" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-center mt-3" v-if="targetOrder">
                  <button class="btn btn-default mr-1" @click.prevent="cancel">取消</button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    @click.prevent="_verify"
                  >建立{{ formatType(returnForm.type)}}單</button>
                </div>
              </form>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'OrderReturnAdd',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import DataTable from '@/dashboard/DataTable.vue'
import { getMember, getOrder, createReturnOrder } from '@/apis/order'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import { checkMoney } from '@/utils/checkMoney'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

const router = useRouter()

const breadCrumbs = ref({
  parentPage: '訂單',
  currentPage: '退換貨管理',
  currentPath: '/admin/Order/returnList',
  childPage: '新增退換貨',
})

const isLoading = ref(false)

const columns = ref([
  {
    name: '訂單成立時間',
    label: 'make_at',
    customLabel: 'make_at',
    custom: true
  },
  {
    name: '訂單號碼',
    label: 'order_pk',
  },
  {
    name: '會員',
    label: 'member_name',
    customLabel: 'member_name',
    custom: true
  },
  {
    name: '收件人姓名/電話',
    label: 'delivery',
    customLabel: 'delivery',
    custom: true
  },
  {
    name: '訂單總金額',
    label: 'amount',
    customLabel: 'amount',
    custom: true
  },
  {
    name: '操作',
    label: 'edit',
    customLabel: 'edit',
    custom: true
  },
])

const searchForm = ref({
  return_num: null, // 訂單單號
  recipient_name: null, // 收件人姓名
  recipient_phone: null, // 收件人電話
  member_id: null, // 會員 id
})

const reset = () => {
  searchForm.value = {
    return_num: null,
    recipient_name: null,
    recipient_phone: null,
    member_id: null,
  }
  resetReturnForm()
}

const listData = ref({
  data: []
})

const getList = async (page) => {
  isLoading.value = true
  if (!page) {
    page = 1
  }
  isLoading.value = true
  const searchData = {}
  Object.entries(searchForm.value).forEach(([key, value]) => {
    if (value !== '' && value !== null) {
      searchData[key] = value
    }
  })
  try {
    const res = await getOrder({
      ...searchData,
      page,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      listData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const pageNum = ref(1)
const setCurrentPage = (page) => {
  pageNum.value = page
  getList(page)
}

onMounted(() => {
  getMemberOptions()
})

const memberOptions = ref([])
const getMemberOptions = async () => {
  try {
    const res = await getMember({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      memberOptions.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const formatTimestamp = (unix) => {
  if (!unix) return ''
  const date = new Date(unix * 1000)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const dateString = `${year}-${month}-${day}`
  return dateString
}

const formatNull = (item) => {
  return (!item) ? '-' : item
}

// 建立退換貨單
const returnForm = ref({
  order_id: '', // 欲退換貨的訂單 id
  type: '', // 類型，C換貨 / B退貨 
  return_amount: null, // 退回金額，如果是換貨不用退錢請送 0
  return_point: null, // 點數返還，如果是換貨或是不用返還請填 0
  return_bonus: null, // 紅利返還，如果是換貨不用退錢請送 0
  order_detail_id: [], // 要退換貨的商品 id
})

const resetReturnForm = () => {
  targetOrder.value = null
  returnForm.value = {
    order_id: '',
    type: '',
    return_amount: null,
    return_point: null,
    return_bonus: null,
    order_detail_id: [],
    enable: 'P',
  }
}

const targetOrder = ref()

// 選取退/換貨單
const setOrder = (item, type) => {
  targetOrder.value = item
  returnForm.value = {
    order_id: item.id,
    order_detail_id: [],
    enable: 'P',
  }
  returnForm.value.type = type
  returnForm.value.return_amount = type === 'C' ? 0 : ''
  returnForm.value.return_point = type === 'C' ? 0 : ''
  returnForm.value.return_bonus = type === 'C' ? 0 : ''
}

const formatType = (type) => {
  return type === 'C' ? '換貨' : '退貨'
}

const checkProduct = (item) => {
  if (item.checked === undefined) {
    item.checked = true
  } else {
    item.checked = !item.checked
  }
}

const requiredError = (text) => {
  Swal.fire({
    icon: 'error',
    title: text
  })
}

const _verify = () => {
  let verify = true
  returnForm.value.order_detail_id = targetOrder.value.order_product_details.filter(item => item.checked === true).map(item => item.commodities_id)
  if (!returnForm.value.order_detail_id.length) {
    verify = false
    requiredError('請至少選取一項產品')
    return
  }
  if (returnForm.value.return_amount === '') {
    verify = false
    requiredError('請填寫退回金額')
    return
  }
  if (returnForm.value.return_point === '') {
    verify = false
    requiredError('請填寫點數返還')
    return
  }
  if (returnForm.value.return_bonus === '') {
    verify = false
    requiredError('請填寫紅利返還')
    return
  }
  if (verify) {
    send()
  }
}

const send = async () => {
  isLoading.value = true
  try {
    const res = await createReturnOrder({
      ...returnForm.value,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: `${formatType(returnForm.value.type)}單建立成功`,
      }).then(() => {
        cancel()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      localStorage.setItem('token', res.data.new_token)
      Swal.fire({
        icon: 'error',
        title: res.data.message
      })
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const cancel = () => {
  router.push(breadCrumbs.value.currentPath)
}

</script>

<style lang="scss" scoped>
.v-select:deep {
  #vs1__combobox {
    height: 38px;
  }
}
.checked {
  background-color: #d0ffd5;
}
</style>