<template>
  <div class="modal modal-bg">
    <div class="close-bg" @click="closeAdvertise"></div>
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">編輯{{props.editData.name}}</h5>
          <button
            type="button"
            class="close text-white"
            data-dismiss="modal"
            @click.prevent="closeAdvertise"
          >
            <small aria-hidden="true">&times;</small>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>名稱
              </label>
              <div>{{props.editData.name}}</div>
            </div>
            <div class="form-group">
              <label>連結</label>
              <div>
                <input class="form-control col-md-6" name="item5-sort" v-model="advertiseForm.url" />
              </div>
            </div>
            <div class="form-group">
              <label>
                <span class="text-danger">*</span>單一圖片
              </label>
              <div class="text-danger">建議上傳尺寸：1920x750 像素 / 檔案限制 2MB 內</div>
              <input
                type="file"
                accept="image/jpg, image/png, image/jpeg"
                class="filepond uploadItemImage"
                @change="uploadImg($event)"
              />
              <img
                class="img-content mt-2"
                :src="advertiseForm.ad_img_url"
                v-show="advertiseForm.ad_img_url"
              />
            </div>
            <div class="text-center">
              <button
                @click.prevent="closeAdvertise"
                class="btn btn-default mr-1"
                data-dismiss="modal"
              >取消</button>
              <button @click.prevent="send" class="btn btn-primary">儲存</button>
            </div>
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue'
import Loading from '@/components/Loading.vue'
import { updateWebConfig } from '@/apis/content'
import { wrongRequest, wrongToken } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const isLoading = ref(false)

const props = defineProps({
  editData: {
    type: Object
  }
})

const advertiseForm = ref({
  id: '',
  ad_img_url: '',
  url: '',
})

onMounted(() => {
  advertiseForm.value = JSON.parse(JSON.stringify(props.editData))
})

const imgFile = ref()
const uploadImg = (e) => {
  imgFile.value = e.target.files[0]
  advertiseForm.value.ad_img_url = URL.createObjectURL(e.target.files[0])
}

const send = async () => {
  isLoading.value = true
  const formData = new FormData()
  if (advertiseForm.value.id === 5) { // 廣告橫幅-1
    formData.append('ad_url_1', advertiseForm.value.url)
    if (imgFile.value) {
      formData.append('ad_img_1', imgFile.value)
    }
  } else if (advertiseForm.value.id === 7) { // 廣告橫幅-2
    formData.append('ad_url_2', advertiseForm.value.url)
    if (imgFile.value) {
      formData.append('ad_img_2', imgFile.value)
    }
  }
  formData.append('id', advertiseForm.value.id)
  formData.append('token', localStorage.getItem('token'))
  try {
    const res = await updateWebConfig(formData)
    if (res.data.code === 0) {
      isLoading.value = false
      localStorage.setItem('token', res.data.new_token)
      Swal.fire({
        icon: 'success',
        title: res.data.data,
      }).then(() => {
        closeAdvertise()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const emit = defineEmits(['closeAdvertise'])
const closeAdvertise = () => {
  emit('closeAdvertise', false)
}

</script>

<style lang="scss" scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  outline: 0;
  display: block;
}
.close-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: block;
  background-color: #0000007d;
}
input {
  width: 100%;
}
.img-content {
  max-width: 100%;
  max-height: 100%;
  // width: 500px;
  // height: 250px;
  background-color: #f1f0ef;
}
</style>