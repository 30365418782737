<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <h5 class="mt-2 mb-3">活動設置</h5>
                <div class="form-group text-danger">若活動新增後，已經有獎勵送出時，活動將不可被刪除，只能停用，請謹慎操作。</div>
                <hr />
                <div class="mb-5">
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pt-2 pb-3 mb-0">
                        <span class="text-danger">*</span>活動名稱
                      </label>
                    </div>
                    <div class="col-md-9">
                      <input
                        type="text"
                        class="form-control col-md-6"
                        placeholder="請輸入名稱"
                        v-model="activeForm.name"
                      />
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pt-2 pb-3 mb-0">
                        <span class="text-danger">*</span>開始日期
                      </label>
                    </div>
                    <div class="col-md-9">
                      <input
                        type="date"
                        class="form-control col-md-6 date"
                        placeholder="請選擇日期"
                        v-model="activeForm.start_day"
                      />
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="pt-2 pb-3 mb-0">結束日期</label>
                    </div>
                    <div class="col-md-9">
                      <div class="row align-items-center">
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control mr-3 date"
                            placeholder="請選擇日期"
                            v-model="activeForm.end_day"
                          />
                        </div>
                        <div class="col-md-6">
                          <span class="text-danger">若沒有選擇結束日期則會一直持續贈送</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 class="mt-2 mb-3">滿額設定</h5>
                <hr />
                <div class="row form-group">
                  <div class="col-md-2">
                    <label class="mb-0 pt-2">
                      <span class="text-danger">*</span>滿額訂單金額
                    </label>
                  </div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      class="form-control col-md-6"
                      placeholder="請輸入金額"
                      v-model="activeForm.full_amount"
                    />
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-md-2">
                    <label class="mb-0">
                      <span class="text-danger">*</span>贈送種類
                    </label>
                  </div>
                  <div class="col-md-9">
                    <p class="text-danger mb-2">產品可以選取多個作為贈品，紅利及點數各自輸入數字作為贈品。</p>
                    <div class="form-group">
                      <label class="mb-0">產品</label>
                      <p class="text-danger">若同樣的產品需要送出多個，請選取兩次以上。</p>
                      <div class="giftProduct-container">
                        <div
                          class="giftProduct-item"
                          v-for="(item, index) in productsArray"
                          :key="index"
                        >
                          <v-select
                            :options="productOptions"
                            label="label"
                            v-model="productsArray[index]"
                          />
                          <a
                            class="text-danger mt-2 delete-giftProduct"
                            @click.prevent="deleteProduct(index)"
                            v-show="index !== 0"
                          >- 移除產品</a>
                          <hr class="ml-0" style="width: 50%" />
                        </div>
                      </div>
                      <div class="mb-2">
                        <a class="mb-2 add-gift-product" @click.prevent="addProduct">+ 贈送產品</a>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>紅利</label>
                      <input
                        type="text"
                        class="form-control col-md-6"
                        placeholder="請輸入紅利"
                        v-model="activeForm.bonus"
                      />
                    </div>
                    <div class="form-group">
                      <label>點數</label>
                      <input
                        type="text"
                        class="form-control col-md-6"
                        placeholder="請輸入點數"
                        v-model="activeForm.point"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button class="btn btn-default mr-1" @click.prevent="cancel">取消</button>
                  <button class="btn btn-primary" @click.prevent="send">儲存</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'PriceEdit',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { createActivites, getActivitesData, updateActivites } from '@/apis/discount'
import { getGitfcommodity } from '@/apis/promoCode'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

const router = useRouter()

const breadCrumbs = ref({
  parentPage: '優惠',
  currentPage: '滿額活動管理',
  currentPath: '/admin/Discount/Price/list',
  childPage: '新增滿額活動',
})

const isLoading = ref(false)

const checkTitle = () => {
  breadCrumbs.value.childPage = location.href.indexOf('add') > 0 ? '新增滿額活動' : '編輯滿額活動'
}

const productOptions = ref([])
const gerProductOptions = async () => {
  isLoading.value = true
  try {
    const res = await getGitfcommodity({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      productOptions.value = res.data.data.map(obj => ({
        ...obj,
        label: `${obj.name}`
      }))
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      if (location.href.indexOf('add') < 0) {
        getEditData()
      }
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const activeForm = ref({
  name: null,
  start_day: null, // 2023-06-01
  end_day: null, // 2023-06-01
  full_amount: null, // 滿額金額
  // commodity_spec_id: [], // 商品 id
  bonus: null,
  point: null
})

const getEditData = async () => {
  isLoading.value = true
  try {
    const res = await getActivitesData({
      id: localStorage.getItem('discountActivitesId'),
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      setEditData(res.data.data)
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const setEditData = (data) => {
  activeForm.value.name = data.name
  activeForm.value.start_day = data.start_day
  activeForm.value.end_day = data.end_day
  activeForm.value.full_amount = data.full_amount
  productsArray.value = data.discount_gift
  // productsArray.value = data.discount_gift.map( item => {
  //   commodities_specification_id: item,
  //   commodities_name:"test",
  //   specification_name:"ddd",
  //   stock:100,
  //   selling_price:200,
  //   pay_method:null,
  //   shipment_method:null,
  //   label:"test/ddd"
  // })
  activeForm.value.bonus = data.bonus
  activeForm.value.point = data.point
}

onMounted(() => {
  checkTitle()
  gerProductOptions()
})


const productsArray = ref([null])
const addProduct = () => {
  productsArray.value.push(null)
}
const deleteProduct = (index) => {
  productsArray.value.splice(index, 1)
}

const send = async () => {
  // isLoading.value = true
  let pass = false
  const formData = {}
  formData.name = activeForm.value.name
  formData.start_day = activeForm.value.start_day
  if (activeForm.value.end_day && activeForm.value.end_day !== '') {
    formData.end_day = activeForm.value.end_day
  }
  formData.full_amount = activeForm.value.full_amount
  if (activeForm.value.bonus) {
    formData.bonus = activeForm.value.bonus
    pass = true
  }
  if (activeForm.value.point) {
    formData.point = activeForm.value.point
    pass = true
  }
  let commodity_spec_id = []
  if (productsArray.value.some((param) => param !== null)) {
    pass = true
    commodity_spec_id = productsArray.value.map(item => item.commodities_specification_id)
  }
  if (pass) {
    try {
      const res = (location.href.indexOf('add') > 0)
        ? await createActivites({ // 新增
          ...formData,
          commodity_spec_id,
          token: localStorage.getItem('token'),
        })
        : await updateActivites({ // 修改
          ...formData,
          commodity_spec_id,
          token: localStorage.getItem('token'),
        })
      if (res.data.code === 0) {
        localStorage.setItem('token', res.data.new_token)
        isLoading.value = false
        Swal.fire({
          icon: 'success',
          title: `${breadCrumbs.value.childPage}成功`,
        }).then(() => {
          cancel()
        })
      } else if (!res.data.new_token) {
        isLoading.value = false
        wrongToken(res.data.message)
      } else {
        isLoading.value = false
        wrongRequest(res.data)
      }
    }
    catch (error) {
      console.info(error)
      isLoading.value = false
      Swal.fire({
        icon: 'error',
        title: error,
      })
    }
  } else {
    Swal.fire({
      icon: 'error',
      title: '必須至少設定一項活動獎勵'
    })
  }
}

const cancel = () => {
  router.push(breadCrumbs.value.currentPath)
}

</script>

<style lang="scss" scoped>
</style>