import request from '@/utils/request';

// 取得登入設定
export function getMemberConfig(param) {
  return request({
    url: `/clientuser/config`,
    method: 'post',
    data: param,
  });
}

// 設定登入選項
export function updateMemberConfig(param) {
  return request({
    url: `/clientuser/update`,
    method: 'post',
    data: param,
  });
}

// 取得會員列表
export function getMemberList(param) {
  return request({
    url: `/member/memberlist`,
    method: 'post',
    data: param,
  });
}

// 後台新增會員
export function addMemberData(param) {
  return request({
    url: `/member/addmember`,
    method: 'post',
    data: param,
  });
}

// 後台修改會員資料
export function updateMemberData(param) {
  return request({
    url: `/member/updatemember`,
    method: 'post',
    data: param,
  });
}

// 啟用關閉會員
export function updateMemberEnable(param) {
  return request({
    url: `/member/enable`,
    method: 'post',
    data: param,
  });
}

// 取得單一會員詳細資料
export function getMemberData(param) {
  return request({
    url: `/member/memberdata`,
    method: 'post',
    data: param,
  });
}

// // 新增會員備註
// export function addMemberRemark(param) {
//   return request({
//     url: `/member/addremark`,
//     method: 'post',
//     data: param,
//   });
// }

// // 更新會員備註
// export function updateMemberRemark(param) {
//   return request({
//     url: `/member/updateremark`,
//     method: 'post',
//     data: param,
//   });
// }

// // 刪除會員備註
// export function delMemberRemark(param) {
//   return request({
//     url: `/member/delremark`,
//     method: 'post',
//     data: param,
//   });
// }

// 取得可以綁定給會員的折扣碼
export function getMemberPromoOptions(param) {
  return request({
    url: `/member/canspecifypromo`,
    method: 'post',
    data: param,
  });
}

// 綁定折價卷給會員
export function bindPromo(param) {
  return request({
    url: `/member/specifypromo`,
    method: 'post',
    data: param,
  });
}

// 綁定折價卷給會員
export function unbindPromo(param) {
  return request({
    url: `/member/cancelpromo`,
    method: 'post',
    data: param,
  });
}