import { defineStore } from 'pinia'

export const useMemberStore = defineStore('member', {
  state: () => {
    return {
      // memberDetail: {},
    }
  },
  actions: {
    // 會員詳情
    setmemberDetail (data){
      const stringifyData = JSON.stringify(data)
      localStorage.setItem('memberDetail', stringifyData)
    }
  }
})

