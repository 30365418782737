<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="modal-body">
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="mb-0 pt-1">
                        <span class="text-danger">*</span>選單
                      </label>
                    </div>
                    <div class="col-md-10">
                      <select class="form-control selectbs2like" v-model="articleForm.menu_id">
                        <option disabled value="null">--選擇選單--</option>
                        <option
                          v-for="item in menuOptions"
                          :key="item.id"
                          :value="item.id"
                        >{{ item.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label>
                        <span class="text-danger">*</span>封面照
                      </label>
                    </div>
                    <div class="col-md-10">
                      <input
                        type="file"
                        accept="image/jpg, image/png, image/jpeg"
                        class="uploadItemImage"
                        name="filepond"
                        @change="getFiles($event)"
                      />
                      <div class="imgs-content my-2">
                        <img class="img-content" v-show="imgUrl" :src="imgUrl" />
                      </div>
                      <div class="text-danger">建議上傳尺寸：1200x1200 像素 / 檔案限制 2MB 內</div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="mb-0 pt-1">
                        <span class="text-danger">*</span>名稱
                      </label>
                    </div>
                    <div class="col-md-10">
                      <input type="text" class="form-control" v-model="articleForm.title" />
                    </div>
                  </div>
                  <!-- <div class="row form-group">
                    <div class="col-md-2">
                      <label class="mb-0 pt-1">描述</label>
                    </div>
                    <div class="col-md-10">
                      <input type="text" class="form-control" v-model="articleForm.describe" />
                    </div>
                  </div>-->
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="mb-0 pt-1">
                        <span class="text-danger">*</span>內容
                      </label>
                    </div>
                    <div class="col-md-10">
                      <Editor :api-key="apiKey" :init="init" v-model="articleForm.content" />
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="mb-0 pt-1">
                        <span class="text-danger">*</span>日期
                      </label>
                    </div>
                    <div class="col-md-10">
                      <input
                        type="date"
                        class="form-control date"
                        v-model="articleForm.article_date"
                      />
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-2">
                      <label class="mb-0 pt-1">狀態</label>
                    </div>
                    <div class="col-md-10">
                      <Toggle v-model="articleForm.enable" falseValue="N" trueValue="Y" />
                    </div>
                  </div>
                  <div class="text-center">
                    <button @click.prevent="cancel" class="btn btn-default mx-1">取消</button>
                    <button @click.prevent="send" class="btn btn-primary">儲存</button>
                  </div>
                </div>
              </form>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'ArticleEdit',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import Editor from '@tinymce/tinymce-vue'
import { addArticle, getArticleData, updateArticle, getMenuOptions, uploadImageAll } from '@/apis/webcontent'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'
import { useRouter } from 'vue-router'

const router = useRouter()

const breadCrumbs = ref({
  parentPage: '網站設定',
  currentPage: '文章管理',
  currentPath: '/admin/Content/article/list',
  childPage: '新增文章',
})

const apiKey = reactive('dx6fmftljibe6g6r410yfy82dp66z74pq447p9nefmxkyl8e')
const init = reactive({
  language: 'zh_TW',
  height: 500,
  plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
  editimage_cors_hosts: ['picsum.photos'],
  menubar: 'file edit view insert format tools table help',
  toolbar: 'code | undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
  toolbar_sticky: true,
  images_upload_handler: (blobInfo, success, failure) => {
    handleImgUpload(blobInfo, success, failure);
  },
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
})

const menuOptions = ref({})

const checkTitle = () => {
  breadCrumbs.value.childPage = location.href.indexOf('add') > 0 ? '新增文章' : '編輯文章'
}

const isLoading = ref(false)

const getMenu = async () => {
  isLoading.value = true
  try {
    const res = await getMenuOptions({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      menuOptions.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      if (location.href.indexOf('add') < 0) {
        getConfig()
      } else {
        isLoading.value = false
      }
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  checkTitle()
  getMenu()
})

const cover_photo = ref('')
const imgUrl = ref('')
const articleForm = ref({
  cover_photo: '',
  menu_id: '',
  title: '',
  // describe: '',
  content: '',
  article_date: '',
  enable: 'Y',
})

const getConfig = async () => {
  isLoading.value = true
  try {
    const res = await getArticleData({
      id: localStorage.getItem('articleId'),
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      articleForm.value = res.data.data
      setImg(res.data.data.img_url)
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = true
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const setImg = (url) => {
  imgUrl.value = url
}

const getFiles = (e) => {
  cover_photo.value = e.target.files[0]
  imgUrl.value = URL.createObjectURL(e.target.files[0])
}

const handleImgUpload = async (blobInfo, success, failure) => {
  const formData = new FormData();
  formData.append('file', blobInfo.blob())
  formData.append('token', localStorage.getItem('token'))
  try {
    const res = await uploadImageAll(formData)
    if (res.data.code === 0) {
      success(res.data.data)
      localStorage.setItem('token', res.data.new_token)
    } else if (!res.data.new_token) {
      wrongToken(res.data.message)
    } else {
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const send = async () => {
  isLoading.value = true
  const formData = new FormData()
  formData.append('cover_photo', cover_photo.value)
  formData.append('menu_id', articleForm.value.menu_id)
  formData.append('title', articleForm.value.title)
  // formData.append('describe', articleForm.value.describe)
  formData.append('content', articleForm.value.content)
  formData.append('article_date', articleForm.value.article_date)
  formData.append('enable', articleForm.value.enable)
  formData.append('token', localStorage.getItem('token'))
  if (location.href.indexOf('add') < 0) {
    formData.append('id', articleForm.value.id)
  }
  try {
    const res = (location.href.indexOf('add') > 0)
      ? await addArticle(formData)
      : await updateArticle(formData)
    if (res.data.code === 0) {
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: res.data.data,
      }).then(() => {
        cancel()
      })
      localStorage.setItem('token', res.data.new_token)
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const cancel = () => {
  router.push(breadCrumbs.value.currentPath)
}

</script>

<style lang="scss" scoped>
.imgs-content {
  max-width: 1200px;
  max-height: 600px;
  background-color: #3c3c3c;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 15px;
  a {
    position: absolute;
    top: 0;
    right: 16px;
  }
  img {
    max-width: 600px;
    max-height: 300px;
  }
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>