import axios from 'axios';
import { baseURL } from '@/utils/baseWebURL'

// 創建 axios 實例
const service = axios.create({
  baseURL,
  timeout: 120000, // 请求超时时间
  headers: {},
});

export default service;
