<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form id="newOrder">
                <h5 class="mb-3">顧客資訊</h5>
                <hr />
                <section class="mb-5">
                  <div class="row form-group">
                    <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4">
                      <label>
                        <span class="text-danger">*</span>選擇會員
                        <div class="text-danger font-weight-normal">請選擇一個會員</div>
                      </label>
                      <v-select :options="memberOptions" label="label" v-model="memberInfo" />
                    </div>
                  </div>
                  <div>
                    <div>{{ memberInfo.name}} {{ memberSex(memberInfo.sex) }}</div>
                    <div>{{ memberInfo.account}} {{ memberInfo.cell_phone}}</div>
                  </div>
                  <!-- <div class="row mb-2">
                    <div class="col-sm-6 col-md-4 col-lg-3">
                      <div class="d-flex align-items-center">
                        <label class="text-nowrap mr-1 mb-0">
                          <span class="text-danger">*</span>姓名
                        </label>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input type="radio" name="gender" id="male" checked />
                        <label for="male" class="mx-1 mb-0">先生</label>
                      </div>
                      <div class="d-inline-flex align-items-center radio icheck-turquoise">
                        <input type="radio" name="gender" id="female" />
                        <label for="female" class="mx-1 mb-0">小姐</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-6 col-md-4 col-lg-3 mb-2 mb-md-0 d-flex align-items-center">
                      <label class="mb-0 mr-1 text-nowrap">
                        <span class="text-danger">*</span>手機
                      </label>
                      <input type="tel" class="form-control" />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-6 col-md-4 col-lg-3 mb-2 mb-md-0 d-flex align-items-center">
                      <label class="mb-0 mr-1 text-nowrap">Email</label>
                      <input type="email" class="form-control" />
                    </div>
                  </div>-->
                </section>
                <h5>購買資訊</h5>
                <p class="text-danger">點選加入產品在此列出</p>
                <hr />
                <section class="mb-5">
                  <div class="form-group">
                    <div class="d-flex align-items-center">
                      <label class="mb-0 mr-1 text-nowrap">查詢商品</label>
                      <div class="col-10 col-md-4 px-0">
                        <v-select :options="productOptions" label="label" v-model="productInfo" />
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table id="product-table" class="table table-borderless text-nowrap">
                      <thead class="thead-light text-center">
                        <tr>
                          <th width="50%">產品名稱 / 規格</th>
                          <th>單價</th>
                          <th>數量</th>
                          <th>小計</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="border-bottom" v-for="(item, index) in carList" :key="index">
                          <td>
                            <div class="table-pItem">
                              <span
                                class="mr-1"
                              >{{ item.commodities_name }} 規格：{{ item.specification_name }}</span>
                            </div>
                            <div
                              class="table-gift ml-4"
                              v-if="item.gift"
                            >[贈品] {{ item.gift.name }} 規格：{{ item.gift.specification_name }} * {{ item.gift.count }}</div>
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">NT${{ checkMoney(item.selling_price) }}</div>
                            <div class="table-gift"></div>
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">
                              <div class="d-flex justify-content-center align-items-center">
                                <div class="quentity-container">
                                  <button
                                    type="button"
                                    class="quentity-minus"
                                    @click="reduceCount(index)"
                                    :disabled="item.count === 1"
                                  >
                                    <i class="fas fa-minus"></i>
                                  </button>
                                  <input
                                    type="number"
                                    class="quentity-num text-center"
                                    min="1"
                                    step="1"
                                    required
                                    v-model="item.count"
                                    readonly
                                  />
                                  <button
                                    type="button"
                                    class="quentity-plus"
                                    @click="addCount(index)"
                                  >
                                    <i class="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                            <div
                              class="table-pItem"
                            >NT${{ checkMoney(item.selling_price * item.count) }}</div>
                            <div class="table-gift"></div>
                          </td>
                          <td class="text-center">
                            <div class="d-flex justify-content-center align-items-center">
                              <a
                                href="javascript:;"
                                class="btn-table delete"
                                @click.prevent="delItem(index)"
                              >
                                <i class="ion-ios-trash"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                        <!-- <tr class="border-bottom">
                          <td>
                            <div class="table-pItem">
                              <span class="product_dlabel mr-1">常溫</span>
                              <span class="mr-1">時光有我 規格：無</span>
                            </div>
                            <div class="table-gift ml-4">[贈品] 小時光有我 規格：無</div>
                            <div class="text-danger">不支援超商取貨</div>
                            <div class="text-danger">不支援超商取貨付款</div>
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">NT$30,000</div>
                            <div class="table-gift"></div>
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">
                              <div class="d-flex justify-content-center align-items-center">
                                <div class="quentity-container">
                                  <button type="button" class="quentity-minus" disabled>
                                    <i class="fas fa-minus"></i>
                                  </button>
                                  <input
                                    type="number"
                                    class="quentity-num text-center"
                                    min="1"
                                    value="1"
                                    step="1"
                                    required
                                    readonly
                                  />
                                  <button type="button" class="quentity-plus">
                                    <i class="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="table-gift">1</div>
                          </td>
                          <td class="text-center">
                            <div class="table-pItem">NT$30,000</div>
                            <div class="table-gift"></div>
                          </td>
                          <td class="text-center">
                            <div class="d-flex justify-content-center align-items-center">
                              <a href="javascript:;" class="btn-table delete">
                                <i class="ion-ios-trash"></i>
                              </a>
                            </div>
                          </td>
                        </tr>-->
                      </tbody>
                    </table>
                  </div>
                  <div class="row mb-2 mb-md-0">
                    <div class="col-6 col-md-2">
                      <label class="p-md-2">使用折扣碼</label>
                    </div>
                    <div class="col-12 col-sm-8 col-md-6 col-lg-4">
                      <select class="form-control" v-model="orderForm.promo_code_id">
                        <option value>不使用折扣碼</option>
                        <option
                          v-for="item in promoCodeOptions"
                          :key="item.id"
                          :value="item"
                        >{{ item.promo_code }} ({{ item.name }})</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-2 mb-md-0">
                    <div class="col-6 col-md-2">
                      <label class="p-md-2">
                        <span class="text-danger">*</span>送貨方式
                      </label>
                    </div>
                    <div class="col-12 col-sm-8 col-md-6 col-lg-4">
                      <select class="form-control" v-model="orderForm.shipment">
                        <option
                          v-for="item in shipmentOptions"
                          :key="item.id"
                          :value="item.id"
                        >{{ item.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-2 mb-md-0">
                    <div class="col-6 col-md-2">
                      <label class="p-md-2">
                        <span class="text-danger">*</span>付款方式
                      </label>
                    </div>
                    <div class="col-12 col-sm-8 col-md-6 col-lg-4">
                      <select class="form-control" v-model="orderForm.pay">
                        <option
                          v-for="item in paymentOptions"
                          :key="item.id"
                          :value="item.id"
                        >{{ item.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-12" v-if="amountInfo">
                      <div class="text-right">
                        <label>小計</label>
                        <span class="order_price">NT${{ checkMoney(amountInfo.pay_amount) }}</span>
                      </div>
                      <div class="text-right">
                        <label>折扣</label>
                        <span class="order_price">-NT${{ checkMoney(amountInfo.full_discount) }}</span>
                      </div>
                      <div class="text-right">
                        <label>紅利</label>
                        <span class="order_price">-NT${{ checkMoney(amountInfo.dividend) }}</span>
                      </div>
                      <div class="text-right">
                        <label>折扣碼</label>
                        <span
                          class="order_price"
                        >-NT${{ checkMoney(amountInfo.promo_code_discount) }}</span>
                      </div>
                      <div class="text-right">
                        <label class="mb-0">運費</label>
                        <span class="order_price">NT${{ checkMoney(amountInfo.shipping_amount) }}</span>
                      </div>
                      <hr class="my-2" />
                      <div class="text-right">
                        <label>合計</label>
                        <span class="order_price">NT${{ checkMoney(amountInfo.subtotal) }}</span>
                      </div>
                    </div>
                  </div>
                </section>
                <h5 class="mb-3">發票資訊</h5>
                <hr />
                <section class="mb-3">
                  <div class="row">
                    <div class="form-group col-md-4">
                      <label>
                        <span class="text-danger">*</span>發票類型
                      </label>
                      <select
                        id="invoice-main"
                        class="form-control"
                        v-model="orderForm.invoice_type_id"
                        required
                      >
                        <option
                          v-for="(item, value) in invoiceOptions"
                          :key="value"
                          :value="item.value"
                        >{{ item.label }}</option>
                      </select>
                    </div>
                    <div
                      class="form-group invoice-personal col-md-4"
                      v-show="orderForm.invoice_type_id === 1"
                    >
                      <label>載具類型</label>
                      <select
                        id="invoice-choice-personal"
                        class="form-control"
                        v-model="orderForm.invoice_carrier_type"
                        required
                      >
                        <option
                          v-for="(item, value) in carrierOptions"
                          :key="value"
                          :value="item.value"
                        >{{ item.label }}</option>
                      </select>
                    </div>
                    <div
                      class="form-group invoice-donate col-md-4"
                      v-show="orderForm.invoice_type_id === 2"
                    >
                      <label class>捐贈單位</label>
                      <select class="form-control" v-model="orderForm.invoice_donation_unit">
                        <option value="1">財團法人創世社會福利基金會</option>
                        <option value="2">財團法人罕見疾病基金會</option>
                        <option value="3">財團法人陽光社會福利基金會</option>
                      </select>
                    </div>
                    <div
                      class="form-group invoice-company col-md-4"
                      v-show="orderForm.invoice_type_id === 3"
                    >
                      <label class>開立統編</label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="請輸入統編"
                        v-model="orderForm.invoice_compilation"
                      />
                    </div>
                    <div
                      class="form-group invoice-p2 col-md-4"
                      v-show="orderForm.invoice_type_id === 1 && orderForm.invoice_carrier_type === 2"
                    >
                      <label class>手機條碼</label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="請輸入手機條碼"
                        v-model="orderForm.invoice_phone_carrier"
                      />
                    </div>
                    <div
                      class="form-group invoice-p3 col-md-4"
                      v-show="orderForm.invoice_type_id === 1 && orderForm.invoice_carrier_type === 3"
                    >
                      <label class>自然人憑證</label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="請輸入自然人憑證"
                        v-model="orderForm.invoice_people_carrier"
                      />
                    </div>
                  </div>
                </section>
                <h5 class="mb-3">收件人資訊</h5>
                <p class="text-primary">若有設定收件人資訊時，主動帶入收件人資訊</p>
                <hr />
                <p>
                  已選擇的送貨方式：
                  <span class="deliverySelected">{{ formatShipment(orderForm.shipment)}}</span>
                </p>
                <div class="checkbox icheck-turquoise mb-4">
                  <input type="checkbox" id="same" v-model="syncMemberInfo" />
                  <label for="same">購買人資料相同</label>
                </div>
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <th style="width: 150px;">
                        <span class="text-danger">*</span>收件人姓名
                      </th>
                      <td>
                        <input
                          type="text"
                          class="form-control col-md-4"
                          v-model="orderForm.recipient_name"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 150px;">
                        <span class="text-danger">*</span>收件人手機號碼
                      </th>
                      <td>
                        <input
                          type="tel"
                          class="form-control col-md-4"
                          v-model="orderForm.recipient_phone"
                        />
                      </td>
                    </tr>
                    <tr
                      class="homeAddress"
                      v-if="orderForm.shipment === 1 || orderForm.shipment === 2"
                    >
                      <th style="width: 150px;">
                        <span class="text-danger">*</span>收件地址
                      </th>
                      <td>
                        <div class="form-group">
                          <div class="text-primary mb-2">選擇超商取貨時免填</div>
                          <div class="row mb-2">
                            <div class="col-12 col-md-4 mb-2 mb-sm-0">
                              <v-select
                                :options="cityOptions"
                                v-model="orderForm.city"
                                placeholder="縣市"
                              />
                            </div>
                            <div class="col-12 col-md-4">
                              <v-select
                                :options="areaOptions"
                                v-model="orderForm.area"
                                placeholder="鄉鎮地區"
                              />
                            </div>
                            <div class="col-12 col-md-4 mb-2 mb-sm-0">
                              <v-select
                                :options="roadOptions"
                                v-model="roadInfo"
                                label="road"
                                placeholder="路(街)名"
                              />
                            </div>
                          </div>
                          <div class="row mb-2">
                            <div class="col-12 col-md-3 mb-2 mb-sm-0">
                              <input
                                type="text"
                                class="form-control"
                                value
                                placeholder="郵遞區號"
                                v-model="roadInfo.zip5"
                                readonly
                                v-if="roadInfo"
                              />
                              <input
                                type="text"
                                class="form-control"
                                value
                                placeholder="郵遞區號"
                                readonly
                                v-else
                              />
                            </div>
                            <div class="col-12 col-md-9 mb-2 mb-sm-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="詳細地址"
                                v-model="orderForm.address"
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr
                      class="CSAddress"
                      v-if="orderForm.shipment === 3 || orderForm.shipment === 4"
                    >
                      <th>
                        <span class="text-danger">*</span>取件門市
                      </th>
                      <td>
                        <div class="form-group">
                          <div class>店鋪代號：</div>
                          <div class>店鋪名稱：</div>
                        </div>
                      </td>
                    </tr>-->
                  </tbody>
                </table>
                <h5 class="mb-3">訂單備註</h5>
                <hr />
                <textarea
                  name="comment"
                  rows="3"
                  class="form-control"
                  placeholder="輸入備註"
                  v-model="orderForm.order_note"
                ></textarea>
                <div class="text-center mt-3">
                  <button class="btn btn-default mr-1" @click.prevent="cancel">取消</button>
                  <button type="submit" class="btn btn-primary" @click.prevent="send">建立訂單</button>
                </div>
              </form>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'OrderAdd',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted, watch } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { getAllMemberList, getProductList, getCreateOrderData, getCityArea, getAreaRoad, createOrder, checkOrderPrice } from '@/apis/order'
import { getPromoCodeList } from '@/apis/promoCode'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import { checkMoney } from '@/utils/checkMoney'
import { invoiceOptions, carrierOptions, cityOptions } from "@/views/Order/options"
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

const router = useRouter()

const breadCrumbs = ref({
  parentPage: '訂單',
  currentPage: '訂單管理',
  currentPath: '/admin/Order/list',
  childPage: '新增訂單',
})

const isLoading = ref(false)

// 建立訂單
const orderForm = ref({
  invoice_type_id: 1, // 發票類型，預設 個人電子發票
  invoice_carrier_type: 1, // 載具類型，預設 會員載具
  order_note: '',
  recipient_name: '',
  recipient_phone: '',
  // city: '',
  // area: '',
  // road: '',
  // address: '',
})

onMounted(() => {
  getMemberOptions()
  gerProductOptions()
  getOrderData()
  getPromoCodeOptions()
})

const memberOptions = ref([])
const getMemberOptions = async () => {
  isLoading.value = true
  try {
    const res = await getAllMemberList({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      memberOptions.value = res.data.data.map(obj => ({
        ...obj,
        label: `${obj.name}/${obj.cell_phone}`
      }))
      // 預設填入第一筆資料
      memberInfo.value = memberOptions.value[0]
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const memberInfo = ref({})
const memberSex = (sex) => {
  return sex === 'F' ? '小姐' : '先生'
}

const productInfo = ref(null)
const productOptions = ref([])
const gerProductOptions = async () => {
  isLoading.value = true
  try {
    const res = await getProductList({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      productOptions.value = res.data.data.map(obj => ({
        ...obj,
        label: `${obj.commodities_name}/${obj.specification_name}`
      }))
      // 預設填入第一筆資料
      // productInfo.value = productOptions.value[0]
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}
// 後台購物車
const carList = ref([])
watch(() => productInfo.value, (nV, oV) => {
  if (nV) {
    if (!carList.value.some(car => car.commodities_specification_id === nV.commodities_specification_id)) {
      nV.count = 1
      carList.value.push(nV)
    }
  }
}, {
  deep: true
})

const reduceCount = (index) => {
  carList.value[index].count -= 1
}
const addCount = (index) => {
  carList.value[index].count += 1
}
const delItem = (index) => {
  carList.value.splice(index, 1)
}

const paymentOptions = ref([])
const shipmentOptions = ref([])
const getOrderData = async () => {
  isLoading.value = true
  try {
    const res = await getCreateOrderData({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      paymentOptions.value = res.data.data.pay
      // 預設填入第一筆資料
      orderForm.value.pay = paymentOptions.value[0].id
      shipmentOptions.value = res.data.data.shipment
      // 預設填入第一筆資料
      orderForm.value.shipment = shipmentOptions.value[0].id
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const promoCodeOptions = ref([])
const getPromoCodeOptions = async () => {
  isLoading.value = true
  try {
    const res = await getPromoCodeList({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      promoCodeOptions.value = res.data.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const amountInfo = ref(null)
// 試算運費等小計
const calcAmount = async () => {
  if (!memberInfo.value.id) return
  const commodities_specification = carList.value.map(({ commodities_specification_id: id, count }) => ({ id, count }))
  isLoading.value = true
  try {
    const res = await checkOrderPrice({
      member_id: memberInfo.value.id,
      commodities_specification,
      pay_methods_id: orderForm.value.pay,
      shipment_methods_id: orderForm.value.shipment,
      checkAmount: 'Y',
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      amountInfo.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      localStorage.setItem('token', res.data.new_token)
      Swal.fire({
        icon: 'error',
        title: res.data.message
      }).then(() => {
        carList.value.splice(carList.value.length - 1, 1)
      })
      // wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}
watch(() => carList.value, (nV, oV) => {
  if (nV) {
    calcAmount()
  }
}, {
  deep: true
})
watch(() => orderForm.value.pay, (nV, oV) => {
  if (!isLoading.value) {
    calcAmount()
  }
}, {
  deep: true
})
watch(() => orderForm.value.shipment, (nV, oV) => {
  if (!isLoading.value) {
    calcAmount()
  }
}, {
  deep: true
})

// 同步會員地址
const syncMemberInfo = ref(false)
watch(() => syncMemberInfo.value, (nV, oV) => {
  if (nV) {
    orderForm.value.recipient_name = memberInfo.value.name
    orderForm.value.recipient_phone = memberInfo.value.cell_phone
    orderForm.value.city = memberInfo.value.delivery_city
    orderForm.value.area = memberInfo.value.delivery_area
    orderForm.value.road = memberInfo.value.delivery_read // 錯字，不過算了
    orderForm.value.address = memberInfo.value.delivery_address
  }
})

// 鄉鎮地方選項
const areaOptions = ref([])
const getArea = async (city) => {
  isLoading.value = true
  try {
    const res = await getCityArea({
      city: city,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      areaOptions.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}
watch(() => orderForm.value.city, (nV, oV) => {
  if (nV) {
    getArea(nV)
  } else {
    areaOptions.value = []
    orderForm.value.area = null
  }
}, {
  deep: true
})

// 路街道選項
const roadOptions = ref([])
const roadInfo = ref(null)
const getRoad = async (area) => {
  isLoading.value = true
  try {
    const res = await getAreaRoad({
      city: orderForm.value.city,
      area: area,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      roadOptions.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}
watch(() => orderForm.value.area, (nV, oV) => {
  if (nV) {
    getRoad(nV)
  } else {
    areaOptions.value = []
    orderForm.value.road = null
  }
}, {
  deep: true
})

const formatShipment = (id) => {
  const shipment = shipmentOptions.value.filter(item => {
    return item.id === id
  })
  if (shipment[0]) {
    return shipment[0].name
  }
}

const send = async () => {
  if (!memberInfo.value.id) return
  isLoading.value = true
  const formData = {}
  formData.member_id = memberInfo.value.id
  formData.commodities_specification = carList.value.map(({ commodities_specification_id: id, count }) => ({ id, count }))
  if (orderForm.value.promo_code_id) {
    formData.promo_code_id = orderForm.value.promo_code_id
  }
  formData.shipment_methods_id = orderForm.value.shipment
  formData.pay_methods_id = orderForm.value.pay
  formData.invoice_type_id = orderForm.value.invoice_type_id // 發票類型
  if (orderForm.value.invoice_type_id === 1) { // 個人發票
    formData.invoice_carrier_type = orderForm.value.invoice_carrier_type // 載具類型
    // 手機載具
    if (orderForm.value.invoice_carrier_type === 2) {
      formData.invoice_phone_carrier = orderForm.value.invoice_phone_carrier // 手機載具號碼
    }
    // 自然人憑證
    if (orderForm.value.invoice_carrier_type === 3) {
      formData.invoice_people_carrier = orderForm.value.invoice_people_carrier // 自然人憑證載具號碼
    }
  }
  if (orderForm.value.invoice_type_id === 2) { // 發票捐贈
    formData.invoice_donation_unit = orderForm.value.invoice_donation_unit
  }
  if (orderForm.value.invoice_type_id === 3) { // 開立統編
    formData.invoice_compilation = orderForm.value.invoice_compilation
  }
  formData.recipient_name = orderForm.value.recipient_name
  formData.recipient_phone = orderForm.value.recipient_phone
  if (orderForm.value.shipment === 1 || orderForm.value.shipment === 2) { // 郵寄、宅配
    // 地址
    formData.city = orderForm.value.city
    formData.area = orderForm.value.area
    formData.road = (roadInfo.value) ? roadInfo.value.road : ''
    formData.address = orderForm.value.address
    formData.recipient_postal_code = (roadInfo.value) ? roadInfo.value.zip5 : ''
    if (formData.city === null || formData.city === '' ||
      formData.area === null || formData.area === '' ||
      formData.road === null || formData.road === '' ||
      formData.address === null || formData.address === '' ||
      formData.recipient_postal_code === null || formData.recipient_postal_code === '') {
      isLoading.value = false
      Swal.fire({
        icon: 'error',
        title: '配送地址未填寫齊全'
      })
      return
    }
  }
  formData.order_note = orderForm.value.order_note
  formData.token = localStorage.getItem('token')
  try {
    const res = await createOrder(formData)
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
      Swal.fire({
        icon: 'success',
        title: '訂單建立成功',
        html:
          `訂單號碼：<b>${res.data.data.order_pk}</b> <br/>
           訂單金額： <b>$${res.data.data.pay_amount} 元</b>`
      }).then(() => {
        cancel()
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      localStorage.setItem('token', res.data.new_token)
      Swal.fire({
        icon: 'error',
        title: res.data.message
      })
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const cancel = () => {
  router.push(breadCrumbs.value.currentPath)
}

</script>

<style lang="scss" scoped>
.quentity-container {
  --main: #477751;
  --second: #eaefcf;
  --third: #9fb99e;
}
</style>