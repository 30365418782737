// 付款狀態
export const paymentStatusOptions = [
  {
    label:'付款狀態',
    value: 1
  },
  {
    label:'未付款',
    value: 2
  },
  {
    label:'已失效',
    value: 3
  },
  {
    label:'已付款',
    value: 4
  },
  {
    label:'貨到付款',
    value: 5
  },
  {
    label:'已取消',
    value: 6
  },
  {
    label:'待退款',
    value: 7
  },
  {
    label:'已退款',
    value: 8
  }
]

// 出貨狀態
export const ShippingStatusOptions = [
  {
    label: '出貨狀態',
    value: 1
  },
  {
    label: '待出貨',
    value: 2
  },
  {
    label: '已出貨',
    value: 3
  },
  {
    label: '已到貨',
    value: 4
  },
  {
    label: '已完成',
    value: 5
  }
]

// 訂單狀態
export const orderStatusOptions = [
  {
    label: '未付款',
    value: 2
  }, 
  {
    label: '已失效',
    value: 3
  },
  {
    label: '已取消',
    value: 4
  },
  {
    label: '待退款',
    value: 5
  },
  {
    label: '已退款',
    value: 6
  },
  {
    label: '待出貨',
    value: 7
  },
  {
    label: '已出貨',
    value: 8
  },
  {
    label: '已到貨',
    value: 9
  },
  {
    label: '已完成',
    value: 10
  },
  {
    label: '待退貨',
    value: 11
  },
  {
    label: '已退貨',
    value: 12
  },
  {
    label: '退貨取件',
    value:  13
  },
  {
    label: '待換貨',
    value: 14
  },
  {
    label: '已換貨',
    value: 15
  },
  {
    label: '退貨已到貨',
    value: 16
  }, 
  {
    label: '取消換貨',
    value:  17
  },
]

// 發票類型
export const invoiceOptions = [
  {
    label: '個人電子發票',
    value:  1
  },
  {
    label: '捐贈發票',
    value:  2
  },
  {
    label: '開立統編',
    value:  3
  }
]

// 載具類型 
export const carrierOptions = [
  {
    label: '會員載具',
    value:  1
  },
  {
    label: '手機條碼載具',
    value:  2
  },
  {
    label: '自然人憑證載具',
    value:  3
  }
]

// 住址，城市
export const cityOptions = [
  '基隆市',
  '臺北市',
  '新北市',
  '桃園縣',
  '新竹市',
  '新竹縣',
  '苗栗縣',
  '臺中市',
  '彰化縣',
  '南投縣',
  '雲林縣',
  '嘉義縣',
  '臺南市',
  '高雄市',
  '屏東縣',
  '臺東縣',
  '花蓮縣',
  '宜蘭縣',
  '澎湖縣',
  '金門縣',
  '連江縣'
]