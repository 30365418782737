import request from '@/utils/request';

// 取得發票列表
export function getInvoiceTrackList(param) {
  return request({
    url: `/invoice/getinvoicelist`,
    method: 'post',
    data: param,
  });
}

// 作廢發票
export function scrapInvoiceTrack(param) {
  return request({
    url: `/invoice/invoiceinvalid`,
    method: 'post',
    data: param,
  });
}

// 取得發票字軌
export function getInvoiceTrack(param) {
  return request({
    url: `/invoice/invoicetrack`,
    method: 'post',
    data: param,
  });
}

// 新增發票字軌
export function createInvoiceTrack(param) {
  return request({
    url: `/invoice/createinvoicetrack`,
    method: 'post',
    data: param,
  });
}

// 更新發票字軌
export function updateInvoiceTrack(param) {
  return request({
    url: `/invoice/updateinvoicetrack`,
    method: 'post',
    data: param,
  });
}


