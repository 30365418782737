<template>
  <div class="modal modal-bg">
    <div class="close-bg" @click="closeHomeBoard"></div>
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">編輯首頁看板</h5>
          <button
            type="button"
            class="close text-white"
            data-dismiss="modal"
            @click="closeHomeBoard"
          >
            <small aria-hidden="true">&times;</small>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>名稱</label>
              <div>首頁看板</div>
            </div>
            <div class="item-container">
              <div class="row">
                <div class="col-lg-6">
                  <label>
                    <span class="text-danger">*</span>左圖內容
                  </label>
                  <div class="modal-item mt-1 pt-2">
                    <div class="form-group">
                      <label>單一圖片上傳</label>
                      <div class="text-danger">建議上傳尺寸：80x80 像素 / 檔案限制 2MB 內</div>
                      <input
                        type="file"
                        accept="image/jpg, image/png, image/jpeg"
                        class="filepond uploadItemImage"
                        id="item2-img1"
                        @change="getFiles($event, 'left')"
                      />
                      <img class="img-content mt-2" :src="homeBoardForm.board_img_url_left" />
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>標題</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="輸入標題"
                            v-model="homeBoardForm.board_title_left"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>次標題</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="輸入次標題"
                            v-model="homeBoardForm.board_sec_title_left"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <label>
                    <span class="text-danger">*</span>右圖內容
                  </label>
                  <div class="modal-item mt-1 pt-2">
                    <div class="form-group">
                      <label>單一圖片上傳</label>
                      <div class="text-danger">建議上傳尺寸：80x80 像素 / 檔案限制 2MB 內</div>
                      <input
                        type="file"
                        accept="image/jpg, image/png, image/jpeg"
                        class="filepond uploadItemImage"
                        id="item2-img2"
                        @change="getFiles($event, 'right')"
                      />
                      <img class="img-content mt-2" :src="homeBoardForm.board_img_url_right" />
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>標題</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="輸入標題"
                            v-model="homeBoardForm.board_title_right"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>次標題</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="輸入次標題"
                            v-model="homeBoardForm.board_sec_title_right"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button
                @click.prevent="closeHomeBoard"
                class="btn btn-default mr-1"
                data-dismiss="modal"
              >取消</button>
              <button @click.prevent="sendForm" class="btn btn-primary">儲存</button>
            </div>
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>

<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue'
import Loading from '@/components/Loading.vue'
import { updateWebConfig } from '@/apis/content'
import { wrongRequest, wrongToken } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const props = defineProps({
  editData: {
    type: Object
  }
})

const isLoading = ref(false)

const homeBoardForm = ref({
  id: '',
  // 左側
  board_img_left: '',
  board_img_url_left: '',
  board_title_left: '',
  board_sec_title_left: '',
  // 右側
  board_img_right: '',
  board_img_url_right: '',
  board_title_right: '',
  board_sec_title_right: '',
})

onMounted(() => {
  homeBoardForm.value = JSON.parse(JSON.stringify(props.editData))
})

const upload_img = ref({
  left: '',
  right: '',
})
const getFiles = (e, target) => {
  upload_img.value[target] = e.target.files[0]
  if (target === 'left') {
    homeBoardForm.value.board_img_url_left = URL.createObjectURL(e.target.files[0])
  } else if (target === 'right') {
    homeBoardForm.value.board_img_url_right = URL.createObjectURL(e.target.files[0])
  }
}

const emit = defineEmits(['closeHomeBoard'])
const closeHomeBoard = () => {
  emit('closeHomeBoard', false)
}

const sendForm = async () => {
  isLoading.value = true
  const formData = new FormData()
  formData.append('id', homeBoardForm.value.id)
  formData.append('board_img_left', upload_img.value.left)
  formData.append('board_title_left', homeBoardForm.value.board_title_left)
  formData.append('board_sec_title_left', homeBoardForm.value.board_sec_title_left)
  formData.append('board_img_right', upload_img.value.right)
  formData.append('board_title_right', homeBoardForm.value.board_title_right)
  formData.append('board_sec_title_right', homeBoardForm.value.board_sec_title_right)
  formData.append('token', localStorage.getItem('token'))
  try {
    const res = await updateWebConfig(formData)
    if (res.data.code === 0) {
      isLoading.value = false
      localStorage.setItem('token', res.data.new_token)
      Swal.fire({
        icon: 'success',
        title: res.data.data,
      }).then(() => {
        emit('closeHomeBoard', false)
      })
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

</script>


<style lang="scss" scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: block;
}
.close-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: block;
  background-color: #0000007d;
}
.img-content {
  max-width: 500px;
  max-height: 250px;
  // width: 500px;
  // height: 250px;
  background-color: #f1f0ef;
}
</style>