import { defineStore } from 'pinia'
import axios from 'axios'

export const useAdminStore = defineStore('admin', {
  state: () => {
    return {
      isLogin: false,
      isSidebarCollapse: false,
      webTitle: '維濟生技',
    }
  },
  actions: {
    // 登入狀態
    setLoginStats (){
      this.isLogin = localStorage.key('token') !== null ? true : false
    },
    // 側邊欄收縮
    setSideBarCollapse (){
      this.isSidebarCollapse = !this.isSidebarCollapse
    },
    // 網站名稱
    async getTitle () {
      const { data } = await axios.post(`https://api.weijibiotech.com.tw/api/client/web/getwebdata`)
      this.webTitle = data.data.web_name
      localStorage.setItem('name', this.webTitle)
    }
  }
})

