
import request from '@/utils/request';

// 優惠 - 取得滿額折扣設定
export function getDiscountSetting(param) {
  return request({
    url: `/discount/getdiscountset`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 更新滿額折扣設定
export function updateDiscountSetting(param) {
  return request({
    url: `/discount/updatediscountset`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 取得滿額活動列表
export function getdActivitesList(param) {
  return request({
    url: `/discount/getdiscountactivites`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 修改滿額活動狀態
export function changedActivitesEnable(param) {
  return request({
    url: `/discount/enable`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 新增滿額活動
export function createActivites(param) {
  return request({
    url: `/discount/creatediscountactivites`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 取得編輯滿額活動資料
export function getActivitesData(param) {
  return request({
    url: `/discount/getdetail`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 編輯滿額活動
export function updateActivites(param) {
  return request({
    url: `/discount/editdiscountactivites`,
    method: 'post',
    data: param,
  });
}

// 優惠 - 刪除滿額活動
export function deleteActivites(param) {
  return request({
    url: `/discount/deldiscountactivites`,
    method: 'post',
    data: param,
  });
}