<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <!-- <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h5 class="pt-2">服務商狀態</h5>
              <hr />
              <div class="table-responsive">
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <th style="width: 120px;">藍新</th>
                      <td class="center">
                        <div>上線</div>
                        <button type="button" class="btn btn-primary btn-sm mt-2">更新</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>-->
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <h5 class="pt-2">線下付款設定</h5>
                <hr />
                <div class="row form-group">
                  <div class="col-md-2">
                    <label class="pl-2">付款說明</label>
                  </div>
                  <div class="col-md-10 ml-2 ml-md-0">
                    <label>補充說明</label>
                    <textarea
                      id="payment_comment1"
                      name="payment_comment1"
                      class="form-control"
                      rows="3"
                      placeholder="請輸入補充說明"
                      v-model="publicity.send_by_post_cash_on_delivery"
                    ></textarea>
                    <div class="text-danger">相關補充說明會顯示在該名稱下方作為提示客戶須知。</div>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-md-2">
                    <label class="pl-2">ATM 銀行轉帳帳戶</label>
                  </div>
                  <div class="col-md-10 ml-2 ml-md-0">
                    <label>補充說明</label>
                    <textarea
                      id="payment_comment2"
                      name="payment_comment2"
                      class="form-control"
                      rows="3"
                      placeholder="請輸入補充說明"
                      v-model="publicity.home_delivery_cash_on_delivery"
                    ></textarea>
                    <div class="text-danger">相關補充說明會顯示在該名稱下方作為提示客戶須知。</div>
                  </div>
                </div>
                <div class="text-center mt-3">
                  <!-- <button type="reset" class="btn btn-default mr-1">取消</button> -->
                  <button class="btn btn-primary" @click.prevent="updateText">儲存</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form>
                <h5 class="py-2">付款設定</h5>
                <div class="table-responsive">
                  <table class="table text-center text-nowrap">
                    <thead class="thead-light">
                      <th style="width: 35%">付款方式</th>
                      <th style="width: 15%;">服務商</th>
                      <th style="width: 50%;">啟用設定</th>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in paySetting" :key="index">
                        <td class="align-middle">{{ item.name }}</td>
                        <td class="align-middle">{{ item.service_provider }}</td>
                        <td>
                          <label class="ml-2">前台</label>
                          <Toggle v-model="item.is_front_desk_use" falseValue="N" trueValue="Y" />
                          <label class="ml-2 mr-2">後台</label>
                          <Toggle v-model="item.is_backstage_use" falseValue="N" trueValue="Y" />
                          <label class="ml-2 mr-2">排序</label>
                          <input type="text" class="form-control sort_num" v-model="item.sort" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-center mt-3">
                    <!-- <button type="reset" class="btn btn-default">取消</button> -->
                    <button class="btn btn-primary" @click.prevent="snedPaySetting">儲存</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'OrderPaymentSetting',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { getPaymentSettingConfig, upadtePaymentSettingText, upadtePaymentSetting } from '@/apis/order'
import { wrongToken, wrongRequest } from '@/utils/Interceptor'
import Swal from 'sweetalert2'
import Toggle from '@vueform/toggle'

const breadCrumbs = ref({
  parentPage: '訂單',
  currentPage: '付款相關設定',
})

const isLoading = ref(false)

const publicity = ref({
  send_by_post_cash_on_delivery: '', // 郵寄
  home_delivery_cash_on_delivery: '', // 宅配
})

const paySetting = ref([])

const getData = async () => {
  isLoading.value = true
  try {
    const res = await getPaymentSettingConfig({
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      publicity.value = res.data.data.publicity
      paySetting.value = res.data.data.pay
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getData()
})

const updateText = async () => {
  isLoading.value = true
  try {
    const res = await upadtePaymentSettingText({
      ...publicity,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      Swal.fire({
        icon: 'success',
        title: '更新說明成功'
      })
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

const snedPaySetting = async () => {
  isLoading.value = true
  try {
    const res = await upadtePaymentSetting({
      pay: paySetting.value,
      token: localStorage.getItem('token'),
    })
    if (res.data.code === 0) {
      Swal.fire({
        icon: 'success',
        title: '更新付款設定成功'
      })
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

</script>

<style lang="scss" scoped>
.sort_num {
  text-align: center;
  width: 40px;
  display: inline-block;
  padding: 0.25rem;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>