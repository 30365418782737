import { type } from 'jquery';
import Swal from 'sweetalert2'

// 資料有錯
export const wrongRequest = (data) => {
  localStorage.setItem('token', data.new_token)
  let errorMessage = ''
  if (data.message.constructor === Array){
    errorMessage = data.message.join("<br/>") + "<br/>";
  } else if (data.message.constructor === Object){
    errorMessage = Object.values(data.message).map((errors) => errors.join('<br/>')).join('<br/>')
  } else {
    errorMessage = data.message
  }
  Swal.fire({
    icon: 'error',
    title: '資料錯誤',
    html: errorMessage
  })
};
// token 失效
export const wrongToken = (message) => {
  Swal.fire({
    icon: 'error',
    title: '登入逾期'
  }).then(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('reloaded')
    location.reload()
  })
};