// // 加入千分位符號，無條件捨棄小數點
export const checkMoney = (money) => {
  if (money === null || money === undefined) {
    return '--'
  } else if (money !== 0) {
    money += "";
    let arr = money.split(".");
    let re = /(\d{1,3})(?=(\d{3})+$)/g;
    return arr[0].replace(re, "$1,")
  } else {
    return '0'
  }
}