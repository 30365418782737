<template>
  <ContentHeader :breadCrumbs="breadCrumbs" />
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <div class="text-danger">所有產品的總金額－已使用的紅利－已使用折扣碼折扣的金額，不含運費的金額。</div>
                <div class="text-danger">請輸入價格區間作為結帳時的折扣優惠判斷。</div>
              </div>
              <form>
                <div class="table-responsive">
                  <table id="example1" class="table w-100 table-borderless">
                    <thead class="thead-light">
                      <tr class="text-center">
                        <th>訂單金額(區間)</th>
                        <th>折扣金額</th>
                      </tr>
                    </thead>
                    <tbody v-if="discountData.length > 0">
                      <tr v-for="item in discountData" :key="item.id">
                        <td>
                          <div>
                            <div class="d-flex text-nowrap align-items-center form-group">
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  value
                                  placeholder="輸入開始價格"
                                  v-model="item.order_cash_st"
                                />
                                <div class="input-group-append">
                                  <span class="input-group-text rounded-0">元</span>
                                </div>
                              </div>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control rounded-0"
                                  value
                                  placeholder="輸入結束價格"
                                  v-model="item.order_cash_ed"
                                />
                                <div class="input-group-append">
                                  <span class="input-group-text">元</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="input-group form-group">
                            <input
                              type="text"
                              class="form-control"
                              value
                              placeholder="輸入折扣金額"
                              v-model="item.order_cash_discount"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">元</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-center">
                  <button @click.prevent="reset" class="btn btn-default mr-1">取消</button>
                  <button @click.prevent="send" class="btn btn-primary">儲存</button>
                </div>
              </form>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition name="fade">
    <Loading v-show="isLoading" />
  </Transition>
</template>


<script>
export default {
  name: 'PriceSetting',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import ContentHeader from '@/dashboard/ContentHeader.vue'
import Loading from '@/components/Loading.vue'
import { getDiscountSetting, updateDiscountSetting } from '@/apis/discount'
import { wrongRequest, wrongToken } from '@/utils/Interceptor'
import Swal from 'sweetalert2'

const breadCrumbs = ref({
  parentPage: '優惠',
  currentPage: '滿額折扣設定',
})
const isLoading = ref(false)

const discountData = ref([])

const getDiscount = async () => {
  isLoading.value = true
  try {
    const res = await getDiscountSetting({
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      discountData.value = res.data.data
      localStorage.setItem('token', res.data.new_token)
      isLoading.value = false
    } else if (!res.data.new_token) {
      isLoading.value = false
      wrongToken(res.data.message)
    } else {
      isLoading.value = false
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

onMounted(() => {
  getDiscount()
})

const reset = () => {
  getDiscount()
}

const setUpData = (data) => {
  return data.map(item => {
    // 檢查每個參數，若值為 ''，則將其改為 null
    Object.keys(item).forEach(key => {
      if (item[key] === '') {
        item[key] = null
      }
    })
    return item
  })
}

const send = async () => {
  let up_data = JSON.stringify(setUpData(discountData.value))
  try {
    const res = await updateDiscountSetting({
      up_data,
      token: localStorage.getItem('token')
    })
    if (res.data.code === 0) {
      localStorage.setItem('token', res.data.new_token)
      Swal.fire({
        icon: 'success',
        title: res.data.data,
      }).then(() => {
        getDiscount()
      })
    } else if (!res.data.new_token) {
      wrongToken(res.data.message)
    } else {
      wrongRequest(res.data)
    }
  }
  catch (error) {
    console.info(error)
    isLoading.value = false
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}

</script>

<style lang="scss" scoped>
</style>