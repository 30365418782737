import request from '@/utils/request';

// 訂單列表查詢 (訂單總管首頁撈取訂單功能)
export function getOrderList(param) {
  return request({
    url: `/order/getOrderList`,
    method: 'post',
    data: param,
  });
}

// 訂單詳情
export function getOrderDetail(param) {
  return request({
    url: `/order/getOrderDetail`,
    method: 'post',
    data: param,
  });
}

// 取得 進行出貨 所需資料
export function getGoShippingData(param) {
  return request({
    url: `/order/getGoShippingData`,
    method: 'post',
    data: param,
  });
}

// 送出 確認出貨 
export function updateShipping(param) {
  return request({
    url: `/order/shippingCheck`,
    method: 'post',
    data: param,
  });
}

// 取得 進行退貨 所需資料
export function getReturnedPurchase(param) {
  return request({
    url: `/order/returnedPurchase`,
    method: 'post',
    data: param,
  });
}

// 確認退貨
export function returningCheck(param) {
  return request({
    url: `/order/returnedPurchaseCheck`,
    method: 'post',
    data: param,
  });
}

// 取消退貨
export function returningCancel(param) {
  return request({
    url: `/order/returnedPurchaseCancel`,
    method: 'post',
    data: param,
  });
}

// 退款確認
export function payCancel(param) {
  return request({
    url: `/order/cancelPay`,
    method: 'post',
    data: param,
  });
}

// 申請換貨
export function changePurchase(param) {
  return request({
    url: `/order/changePurchase`,
    method: 'post',
    data: param,
  });
}

// 換貨確認
export function changeCheck(param) {
  return request({
    url: `/order/changePurchaseCheck`,
    method: 'post',
    data: param,
  });
}

// 取消換貨
export function changeCancel(param) {
  return request({
    url: `/order/changePurchaseCancel`,
    method: 'post',
    data: param,
  });
}

// 取消訂單
export function orderCancel(param) {
  return request({
    url: `/order/cancelOrder`,
    method: 'post',
    data: param,
  });
}

// 新增訂單，取得會員列表
export function getAllMemberList(param) {
  return request({
    url: `/member/allmemberlist`,
    method: 'post',
    data: param,
  });
}

// 新增訂單，取得商品列表
export function getProductList(param) {
  return request({
    url: `/order/getCreateOrderCommodities`,
    method: 'post',
    data: param,
  });
}

// 新增訂單，取得建立訂單首頁需要資料
export function getCreateOrderData(param) {
  return request({
    url: `/order/getCreateOrderData`,
    method: 'post',
    data: param,
  });
}

// 新增訂單，使用縣市取得區域
export function getCityArea(param) {
  return request({
    url: `/order/getArea`,
    method: 'post',
    data: param,
  });
}

// 新增訂單，使用縣市跟區域取得路名
export function getAreaRoad(param) {
  return request({
    url: `/order/getRoad`,
    method: 'post',
    data: param,
  });
}

// 新增訂單
export function createOrder(param) {
  return request({
    url: `/order/createOrder`,
    method: 'post',
    data: param,
  });
}

// 查詢金額
export function checkOrderPrice(param) {
  return request({
    url: `/order/createOrder`,
    method: 'post',
    data: param,
  });
}

// 取得付款相關設定資料
export function getPaymentSettingConfig(param) {
  return request({
    url: `/order/getPaySetIndex`,
    method: 'post',
    data: param,
  });
}

// 修改線下付款設定說明
export function upadtePaymentSettingText(param) {
  return request({
    url: `/order/publicity/editPay`,
    method: 'post',
    data: param,
  });
}

// 修改付款設定
export function upadtePaymentSetting(param) {
  return request({
    url: `/order/editPaySet`,
    method: 'post',
    data: param,
  });
}

// 取得運費及免運設定
export function getShipmentConfig(param) {
  return request({
    url: `/order/publicity/getShipment`,
    method: 'post',
    data: param,
  });
}

// 修改運費及免運說明
export function updateShipmentText(param) {
  return request({
    url: `/order/publicity/editShipment`,
    method: 'post',
    data: param,
  });
}

// 修改運費及免運說明
export function upadteShipment(param) {
  return request({
    url: `/order/editShipmentSet`,
    method: 'post',
    data: param,
  });
}

// 取得 退換貨列表
export function getReturnOrderList(param) {
  return request({
    url: `/order/getReturnOrderList`,
    method: 'post',
    data: param,
  });
}

// 取得 會員列表，新增退貨單用
export function getMember(param) {
  return request({
    url: `/order/getMember`,
    method: 'post',
    data: param,
  });
}

// 取得 訂單列表，新增退貨單用
export function getOrder(param) {
  return request({
    url: `/order/getOrder`,
    method: 'post',
    data: param,
  });
}

// 新增 退換貨單
export function createReturnOrder(param) {
  return request({
    url: `/order/createReturnOrder`,
    method: 'post',
    data: param,
  });
}

// 退貨單審核
export function returnReview(param) {
  return request({
    url: `/order/returnReview`,
    method: 'post',
    data: param,
  });
}