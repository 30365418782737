<template>
  <div class="bg-img">
    <img :src="require('@/assets/images/background/lock-screen.jpg')" />
  </div>
  <div class="login-container py-5">
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex align-items-center mb-3">
          <img :src="require('@/assets/images/logo-m.png')" height="60" />
          <h4 class="ml-2 logo-title">{{ admin.webTitle }}</h4>
        </div>
        <h6 class="pt-2 mb-3">登入以開始使用</h6>
        <hr class="my-4" />
        <form ref="form">
          <div class="form-group form-group-default">
            <label class="mr-2">
              管理者帳號
              <span class="text-danger">*</span>
            </label>
            <input type="tel" class="mb-3" v-model="loginForm.account" required />
          </div>
          <div class="form-group form-group-default">
            <label class="mr-2">
              管理者密碼
              <span class="text-danger">*</span>
            </label>
            <input type="password" class="mb-3" v-model="loginForm.password" required />
          </div>
          <div class="text-right">
            <button type="button" class="main-btn px-3 py-2 rounded" @click="doLogin">登入</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  inheritAttrs: false,
  customOptions: {}
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import { useAdminStore } from '@/store/admin'
import { adminLogin } from '@/apis/login'
import Swal from 'sweetalert2'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const admin = useAdminStore()

let loginForm = ref({
  account: '',
  password: '',
})

const redirectHandler = (path) => {
  router.push(path)
}

onMounted(async () => {
  await admin.getTitle()
  document.addEventListener("keyup", function (event) {
    if (event.keyCode === 13) {
      doLogin()
    }
  });
})

const doLogin = async () => {
  const admin = useAdminStore()
  try {
    const res = await adminLogin(loginForm.value)
    if (res.data.code === 0 && res.data.data.token) {
      localStorage.setItem('token', res.data.data.token)
      localStorage.setItem('alias', res.data.data.alias)
      localStorage.setItem('p', res.data.data.permission)
      admin.setLoginStats()
    }
    if (admin.isLogin) {
      if (route.query.redirect) {
        redirectHandler(route.query.redirect)
        return
      }
      router.push({ name: 'Home' })
    } else {
      Swal.fire({
        icon: 'error',
        title: '帳號密碼錯誤',
      })
    }
  }
  catch (error) {
    console.info(error)
    Swal.fire({
      icon: 'error',
      title: error,
    })
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/admin/admin.css";
@import "~@/assets/css/rwd.css";
</style>